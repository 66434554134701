import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Space, Table, Tag } from "antd";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import TableScrollOnTop from "../../../../providers/TableScrollOnTop";
import { GET } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import ModalInvoice from "../../PageUser/PageInvoices/Components/ModalInvoice";

export default function PageOrder(props) {
  const history = useHistory();
  const elementRef = useRef(null);
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_field: "created_at_str",
    sort_order: "desc",
  });

  const {
    data: dataTable,
    refetch: refetchTable,
    isFetching: isFetchingTable,
    isLoading: isLoadingTable,
  } = GET(`api/v1/orders?${$.param(tableFilter)}`, "orders", (res) => {
    if (res.success) {
      console.log("dataTable", res);
    }
  });

  const [searchText, setSearchText] = useState("");
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
  }, [tableFilter]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      page: 1,
    });
  };

  const [toggleModal, setToggleModal] = useState({
    show: false,
    data: null,
    dataTable: [],
  });

  const handleToggleModal = () => {
    setToggleModal({
      show: false,
      data: null,
      dataTable: [],
    });
  };

  return (
    <Card className="admin-page-user card--padding-mobile">
      <Row gutter={12}>
        {/* table pagination and searc */}
        <Col xs={24} sm={24} md={24}>
          <Row gutter={12} className="m-b-sm">
            <Col xs={24} sm={24} md={12} lg={12} xl={18} className="m-b-sm">
              <Space>
                <TablePageSize
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
              <TableGlobalSearch
                paginationFilter={searchText}
                setPaginationFilter={setSearchText}
              />
            </Col>
          </Row>
        </Col>

        {/* table */}
        <Col xs={24} sm={24} md={24}>
          <TableScrollOnTop tableRef={elementRef} />
          <div id="tbl1" style={{ marginTop: "-8px" }}>
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={
                dataTable?.data && dataTable?.data?.data
                  ? dataTable?.data?.data
                  : []
              }
              loading={isLoadingTable || isFetchingTable}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              ref={elementRef}
              onChange={handleTableChange}
              style={{ top: "0px" }}
              sticky
            >
              <Table.Column
                title="Purchased At"
                key="created_at_str"
                dataIndex="created_at_str"
                sorter={true}
                defaultSortOrder={"descend"}
                width={"180px"}
              />

              <Table.Column
                title="Invoice Number"
                key="invoice_number"
                dataIndex="invoice_number"
                sorter={true}
                width={"170px"}
                render={(text, record) => {
                  return (
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();

                        let product = [];
                        if (record?.order_details?.includes("1 Hour Update")) {
                          product = [
                            {
                              description: "1 Hour Update",
                              quantity: 1,
                              amount: record?.amount,
                            },
                          ];
                        } else if (
                          record?.order_details?.includes(
                            "Free Application Analysis"
                          )
                        ) {
                          product = [
                            {
                              description: "Free Application Analysis",
                              quantity: 1,
                              amount: record?.amount,
                            },
                          ];
                        } else {
                          product = [
                            {
                              description: "[VIDEO 5] Timeline Training Video",
                              quantity: 1,
                              amount: "$0.00",
                            },
                            {
                              description:
                                "United Airlines Application Boot Camp",
                              quantity: 1,
                              amount: "$0.00",
                            },
                            {
                              description:
                                "Eight Hour Application Construction",
                              quantity: 1,
                              amount: record?.amount,
                            },
                          ];
                        }

                        setToggleModal({
                          show: true,
                          data: record,
                          dataTable: product,
                        });
                      }}
                      className="table-link"
                    >
                      {text}
                    </Link>
                  );
                }}
              />

              <Table.Column
                title="Full Name"
                key="full_name"
                dataIndex="full_name"
                sorter={true}
                width={"200px"}
              />

              <Table.Column
                title="Email"
                key="email"
                dataIndex="email"
                sorter={true}
                width={"330px"}
              />

              <Table.Column
                title="Product"
                key="order_details"
                dataIndex="order_details"
                width={"270px"}
                className="table-th"
                render={(text, record) => {
                  let product = text ? JSON.parse(text) : [];
                  return product.map((item, index) => {
                    return (
                      <Tag color="blue" key={index} className="table-link">
                        {item}
                      </Tag>
                    );
                  });
                }}
              />

              <Table.Column
                title="Order Notes"
                key="order_notes"
                dataIndex="order_notes"
                sorter={true}
                width={"350px"}
              />

              <Table.Column
                title="Coupon Code"
                key="coupon_value"
                dataIndex="coupon_value"
                sorter={true}
                width={"180px"}
              />

              <Table.Column
                title="Discount"
                key="coupon_amount"
                dataIndex="coupon_amount"
                sorter={true}
                width={"130px"}
              />

              <Table.Column
                title="Amount"
                key="amount"
                dataIndex="amount"
                sorter={true}
                width={"130px"}
              />

              <Table.Column
                title="Total Amount"
                key="total"
                dataIndex="total"
                sorter={true}
                width={"150px"}
              />
            </Table>
          </div>
        </Col>

        {/* table pagination */}
        <Col xs={24} sm={24} md={24}>
          <div className="ant-space-flex-space-between the-pagination the-pagination--view-user">
            <TableShowingEntries />
            <TablePagination
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
              setPaginationTotal={dataTable?.data?.total}
              showLessItems={true}
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>

      <ModalInvoice
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        handleToggleModal={handleToggleModal}
      />
    </Card>
  );
}
