import React, { useState } from "react";
import { Card, Collapse, Form, Input, notification, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  formats,
  modulesToolBarV3,
} from "../../../providers/reactQuillOptions";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";

export default function ComponentQuill(props) {
  const { form, dataSource, onBlur, name } = props;

  const ColorClass = Quill.import("attributors/class/color");
  Quill.register(ColorClass, true);
  const ColorStyle = Quill.import("attributors/style/color");
  Quill.register(ColorStyle, true);

  const [activeKey, setActiveKey] = useState("0");
  const [activeKeySelected, setActiveKeySelected] = useState("0");

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (
        !document.querySelector(".ql-toolbar")?.contains(e.target) &&
        e.target !== document.querySelector(".ql-editor")
      ) {
      } else {
        e.preventDefault();
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Form.List name={name} initialValue={{ stages: "" }}>
      {(fields) => (
        <Collapse
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <FontAwesomeIcon
                style={{ color: "#325db8", fontSize: 18 }}
                icon={faMinus}
              />
            ) : (
              <FontAwesomeIcon
                style={{ color: "#325db8", fontSize: 18 }}
                icon={faPlus}
              />
            )
          }
          className="main-4-collapse border-none m-b-lg email-template-collapse stages-collapse"
          onChange={(key) => {
            setActiveKey(key);
            setActiveKeySelected(key);
          }}
        >
          {fields.map(({ name, ...restField }, index) => (
            <Collapse.Panel
              className="dashboard-collapse-header padding-on-dashboard"
              header={
                dataSource[index].title == "Set Appt."
                  ? "Set Appointment"
                  : dataSource[index].title
              }
              key={index.toString()}
              style={{ marginBottom: "20px" }}
            >
              {/* ... (your other Form.Item components) ... */}

              <Form.Item
                {...restField}
                name={[name, "description"]}
                style={{ marginBottom: "0px" }}
                hasFeedback
                rules={[
                  {
                    required: activeKey === index ? true : false,
                    message: "This field is required!",
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  modules={modulesToolBarV3}
                  formats={formats}
                  onBlur={(range, source, quill) => {
                    onBlur(quill.getHTML(), index, dataSource[index].id);
                  }}
                />
              </Form.Item>
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </Form.List>
  );
}
