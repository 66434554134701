import { faEnvelopesBulk } from "@fortawesome/pro-light-svg-icons";
import {
  faBell,
  faCalendarDays,
  faChartNetwork,
  faTableColumns,
  faUser,
  faUsers,
  faCalendarTimes,
  faClockRotateLeft,
  faSms,
  faCommentDots,
  faShoppingCart,
  faTag,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminSideMenu = [
  {
    title: "Dashboard",
    path: "/dashboard",
    page: "Dashboard",
    icon: <FontAwesomeIcon icon={faChartNetwork} />,
  },
  {
    title: "Clients",
    page: "Clients",
    path: "/clients",
    icon: <FontAwesomeIcon icon={faUser} />,
  },
  {
    title: "Consultants",
    page: "Consultants",
    path: "/consultants",
    icon: <FontAwesomeIcon icon={faUsers} />,
  },
  {
    title: "Calendar",
    page: "Calendar",
    path: "/calendar",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
  },
  {
    page: "Coupons",
    title: "Coupons",
    path: "/coupons",
    icon: <FontAwesomeIcon icon={faTag} />,
  },
  {
    page: "Orders",
    title: "Orders",
    path: "/orders",
    icon: <FontAwesomeIcon icon={faShoppingCart} />,
  },
  {
    page: "Email Templates",
    title: "Email Templates",
    path: "/emailtemplate",
    icon: <FontAwesomeIcon icon={faEnvelopesBulk} />,
  },
  {
    page: "SMS Templates",
    title: "SMS Templates",
    path: "/sms-template",
    icon: <FontAwesomeIcon icon={faSms} />,
  },
  {
    page: "Stages",
    title: "Stages",
    path: "/stages",
    icon: <FontAwesomeIcon icon={faTableColumns} />,
  },
  {
    page: "Messages",
    title: "Messages",
    path: "/messages",
    icon: <FontAwesomeIcon icon={faCommentDots} />,
  },
  {
    page: "Notifications",
    title: "Notifications",
    path: "/notification",
    icon: <FontAwesomeIcon icon={faBell} />,
  },
  {
    page: "Cancelled Appointments",
    title: "Cancelled Appt.",
    path: "/cancelled-appointments",
    icon: <FontAwesomeIcon icon={faCalendarTimes} />,
  },
  {
    page: "History Log",
    title: "History Log",
    path: "/history/all",
    icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
  },
];

export default AdminSideMenu;
