import { Col, Modal, Row, Table, Tag, Typography } from "antd"
import { useEffect } from "react"
import { GETMANUAL, POST } from "../../../../../providers/useAxiosQuery"
import { useState, useRef } from "react"
import TableScrollOnTop2 from "../../../../../providers/TableScrollOnTop2"
// import {
//   TablePagination,
//   TableShowingEntries,
// } from "../../../Components/ComponentTableFilter";

export default function ModalBulkEmailsList(props) {
  const { showModalBulkEmails, setShowModalBulkEmails, bookingDetails, setBookingDetails } = props

  const handleCancel = () => {
    setBookingDetails([])
    setShowModalBulkEmails(false)
    setDataSource([])
  }

  const [dataSource, setDataSource] = useState([])
  useEffect(() => {
    if (showModalBulkEmails && bookingDetails && bookingDetails?.id) {
      let data = {
        consultant_scedule_id: bookingDetails?.id,
      }
      mutateBulkEmails(data, {
        onSuccess: (res) => {
          setDataSource(res.data)
        },
      })
    }
  }, [showModalBulkEmails, bookingDetails])

  const { mutate: mutateBulkEmails, isLoading: isLoadingBulkEmails } = POST("api/v1/appointment/get_bulk_email_list", "bulk_email_list")

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "cancelled_at",
    sort_order: "desc",
    consultant_scedule_id: bookingDetails?.id,
  })

  const onChange = (current, pageSize, sorter) => {
    let data = {
      ...tableFilter,
      page: 1,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
    }

    mutateBulkEmails(data, {
      onSuccess: (res) => {
        setDataSource(res.data)
      },
    })
  }

  const elementRef = useRef(null)

  return (
    <Modal
      open={showModalBulkEmails}
      className="modal-send-email-notifiaction  modal-bulk-email-list scrollbar-2"
      title="EMAIL HISTORY"
      okText="Submit"
      width={850}
      onCancel={() => handleCancel()}
      beforeClose={() => handleCancel()}
      footer={false}
      bodyStyle={{
        maxHeight: 500,
        overflowY: "auto",
      }}
    >
      <TableScrollOnTop2 tableRef2={elementRef} />
      <div id="tbl2" style={{ marginTop: "-8px" }}>
        <Table className="ant-table-default ant-table-striped scrollbar-2" rowKey={(record) => record.id} onChange={onChange} dataSource={dataSource} loading={isLoadingBulkEmails} pagination={false} style={{ top: "0px" }} sticky ref={elementRef}>
          <Table.Column
            key={"created_at_str"}
            title="Created At"
            dataIndex={"created_at_str"}
            className="p-l-md"
            width={180}
            //   defaultSortOrder={"descend"}
            //   sorter
          />
          <Table.Column
            key={"title"}
            title="Email Template"
            className="p-l-md"
            dataIndex={"title"}
            render={(text, record) => {
              return <Typography.Text style={{ textTransform: "capitalize" }}>{text ? text?.toLowerCase() : ""}</Typography.Text>
            }}
            width={300}
            //   sorter
          />
          <Table.Column
            key={"user_ids"}
            title="Users"
            dataIndex={"user_ids"}
            className="p-l-md"
            width={300}
            render={(text, record) => {
              return (
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                  }}
                >
                  {record?.users?.map((item, key) => {
                    return (
                      <Tag
                        color="blue"
                        style={{
                          textTransform: "capitalize",
                          marginBottom: 5,
                          marginRight: 5,
                        }}
                        key={key}
                      >
                        {item?.name}
                      </Tag>
                    )
                  })}
                </div>
              )
            }}
          />
        </Table>
      </div>

      {/* <div className="ant-space-flex-space-between the-pagination the-pagination--cancelled-appointments">
        <TableShowingEntries />
        <TablePagination
          paginationFilter={tableFilter}
          setPaginationFilter={setTableFilter}
          setPaginationTotal={tableTotal}
          showLessItems={true}
          showSizeChanger={false}
        />
      </div> */}
    </Modal>
  )
}
