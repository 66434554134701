import {
  Card,
  Row,
  Col,
  Collapse,
  Button,
  Typography,
  Form,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faHourglassClock,
  faRotateRight,
  faCheckCircle,
  faEyeSlash,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { GET, POST } from "../../../../providers/useAxiosQuery";

import TaskCard from "../../Components/TaskCard";
import { Color } from "highcharts";

function PageTask() {
  const [rawTaskData, setTaskData] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [id, setID] = useState(0);
  const [filteredTask, setFilteredTask] = useState([]);
  const [filter, setFilter] = useState("all");
  const [form] = Form.useForm();
  const default_task = ["WAIT FOR SALES REP", "PRODUCT PURCHASE", "MNDA"];
  const [isLoading, setIsLoading] = useState(false);
  const { Panel } = Collapse;

  const { mutate: mutateMarkCompleted } = POST(
    "api/v1/task/markcomplete",
    "markcomplete"
  );

  const [tasks, setTasks] = useState({
    completed: [],
    pending: [],
    inactive: [],
  });

  const { refetch: getTaskData, isLoading: isLoadingTask } = GET(
    `api/v1/task/` + { id },
    "get_task",
    (res) => {
      if (res.success) {
        function clean_description(description) {
          let newDescription = "";

          if (description.includes("<br/>")) {
            newDescription = description.split("<br/>")[0];
          } else if (description.includes("<a")) {
            newDescription = description.split("<a")[0];
          } else if (description.includes("Click here to")) {
            newDescription = description.split("Click")[0];
          } else if (description.includes("click here")) {
            newDescription = description.split("click")[0];
          } else {
            newDescription = description;
          }

          return newDescription;
        }

        let task_data = [];
        res.data.map((item) => {
          if (task_data.length === 0) {
            task_data.push({
              id: item.id,
              title: item.title,
              description: clean_description(item.description),
              link: item.description.includes("href")
                ? item.description.split("\n")[
                    item.description.split("\n").length - 1
                  ]
                : "",
              hasLink: item.description.includes("href") ? true : false,
              assignedTo: item.assignedTo,
              isCompleted: item.status,
              isDisabled: true,
            });
          } else {
            task_data.push({
              id: item.id,
              title: item.title,
              description: clean_description(item.description),
              link: item.description.includes("href")
                ? item.description.split("\n")[
                    item.description.split("\n").length - 1
                  ]
                : "",
              hasLink: item.description.includes("href") ? true : false,
              assignedTo: item.assignedTo,
              isCompleted: item.status,
              isDisabled: true,
            });
          }
        });

        task_data.sort((a, b) =>
          a.isActive > b.isActive ? 1 : b.isCompleted > a.isCompleted ? -1 : 0
        );

        setTaskData(task_data);

        setTasks({
          completed: task_data.filter((task) => {
            return task.isCompleted === "completed";
          }),
          pending: task_data.filter((task) => {
            return task.isCompleted === "pending";
          }),
          inactive: task_data.filter((task) => {
            return task.isCompleted === "upcoming";
          }),
        });

        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    form.setFieldsValue({
      filter: "All",
    });

    handleFilter("All");
  }, [rawTaskData]);

  const handleFilter = (val) => {
    let filtered = [];
    if (val === "Pending") {
      filtered = rawTaskData.filter((task) => {
        return task.isCompleted === false;
      });
    } else if (val === "Completed") {
      filtered = rawTaskData.filter((task) => {
        return task.isCompleted === true;
      });
    } else if (val === "All") {
      filtered = rawTaskData;
    }

    setFilteredTask(filtered);
  };

  return (
    <Card className="ant-design-dashboard the-page-task">
      <Row gutter={[5, 5]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Space wrap className="task-btn-space">
            <Button
              onClick={() => setFilter("all")}
              size="large"
              className={
                "btn-dashboard-task " +
                (filter === "all"
                  ? "btn-task-primary-active"
                  : "btn-task-primary")
              }
              style={{ lineHeight: "19px" }}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "5px" }} />
              All Tasks
            </Button>

            <Button
              onClick={() => setFilter("completed")}
              size="large"
              className={
                "btn-dashboard-task " +
                (filter === "completed"
                  ? "btn-task-active-active"
                  : "btn-task-active")
              }
              style={{ lineHeight: "19px" }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ marginRight: "5px" }}
              />
              Completed Tasks
            </Button>

            <Button
              onClick={() => setFilter("pending")}
              size="large"
              className={
                "btn-dashboard-task " +
                (filter === "pending"
                  ? "btn-task-pending-active"
                  : "btn-task-pending")
              }
              style={{ lineHeight: "19px" }}
            >
              <FontAwesomeIcon
                icon={faHourglassClock}
                style={{ marginRight: "5px" }}
              />
              Pending Tasks
            </Button>

            <Button
              onClick={() => setFilter("inactive")}
              size="large"
              className={
                "btn-dashboard-task " +
                (filter === "inactive"
                  ? "btn-task-inactive-active"
                  : "btn-task-inactive")
              }
              style={{ lineHeight: "19px" }}
            >
              <FontAwesomeIcon
                icon={faEyeSlash}
                style={{ marginRight: "5px" }}
              />
              Inactive Tasks
            </Button>
          </Space>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
          <Collapse
            className=" border-none panel-bordered "
            expandIcon={({ isActive }) =>
              isActive ? (
                <FontAwesomeIcon
                  style={{ color: "#325db8", fontSize: 18 }}
                  icon={faMinus}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ color: "#325db8", fontSize: 18 }}
                  icon={faPlus}
                />
              )
            }
            defaultActiveKey={["1", "2", "3"]}
            expandIconPosition="end"
          >
            {/* completed */}
            {(filter === "all" || filter === "completed") && (
              <Panel
                key="1"
                className="accordion bg-darkgray-form m-t-md bottom-padding-none the-completed-tasks gray-header"
                header="COMPLETED TASKS"
              >
                <Row gutter={[24, 24]}>
                  {rawTaskData.length > 0 && tasks.completed.length !== 0 ? (
                    tasks.completed.map((item, index) => {
                      return (
                        <Col
                          key={index}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={12}
                          xxl={12}
                        >
                          <TaskCard item={item} isDisabled={item.isDisabled} />
                        </Col>
                      );
                    })
                  ) : (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      className="no-task-found"
                    >
                      <Typography.Text>
                        NO COMPLETED TASKS FOUND
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Panel>
            )}

            {/* pending */}
            {(filter === "all" || filter === "pending") && (
              <Panel
                key="2"
                className="accordion bg-darkgray-form m-t-md bottom-padding-none the-pending-tasks gray-header"
                header="PENDING TASKS"
              >
                <Row gutter={[24, 24]}>
                  {rawTaskData.length > 0 && tasks.pending.length !== 0 ? (
                    tasks.pending.map((item, index) => {
                      let oldLink =
                        "https://staging2.myairlinetc.com/product/full-consultation/";
                      let newLink =
                        "checkout/eight-hour-application-constructions";
                      let newLink1 =
                        "checkout/eight-hour-application-construction";

                      let newDescription = item?.link.replace(oldLink, newLink);
                      let newDescription1 = newDescription.replace(
                        newLink,
                        newLink1
                      );

                      let newDescription2 = newDescription1;
                      if (process.env.REACT_APP_SIDE_MENU_MODE !== "staging") {
                        newDescription2 = newDescription1.replace(
                          "https://staging.system.airlinetc.com/",
                          "https://system.airlinetc.com/"
                        );
                      }

                      item = {
                        ...item,
                        link: newDescription1,
                      };
                      return (
                        <Col
                          key={index}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={12}
                          xxl={12}
                        >
                          <TaskCard item={item} />
                        </Col>
                      );
                    })
                  ) : (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      className="no-task-found"
                    >
                      <Typography.Text>
                        NO COMPLETED TASKS FOUND
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Panel>
            )}

            {/* inactive */}
            {(filter === "all" || filter === "inactive") && (
              <Panel
                key="3"
                className="accordion bg-darkgray-form m-t-md bottom-padding-none the-inactive-tasks gray-header"
                header="INACTIVE TASKS"
              >
                <Row gutter={[24, 24]}>
                  {rawTaskData.length > 0 && tasks.inactive.length !== 0 ? (
                    <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                      <TaskCard
                        item={tasks?.inactive[0]}
                        isDisabled={tasks?.inactive[0]?.isDisabled}
                      />
                    </Col>
                  ) : (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      className="no-task-found"
                    >
                      <Typography.Text>
                        NO COMPLETED TASKS FOUND
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Panel>
            )}
          </Collapse>
        </Col>
      </Row>

      {/* end */}
    </Card>
  );
}

export default PageTask;
