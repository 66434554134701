import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  Typography,
  Collapse,
  Alert,
} from "antd";
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import moment from "moment";
import { description, fullwidthlogo } from "../../../providers/companyInfo";
import { POST, POSTNOLOADING } from "../../../providers/useAxiosQuery";
import FloatInput from "../../../providers/FloatInput";
import FloatInputMaskDate from "../../../providers/FloatInputMaskDate";
import FloatInputMask from "../../../providers/FloatInputMask";
import ComponentHeader from "../Components/ComponentHeader";
import { isPossiblePhoneNumber } from "react-phone-number-input";
// import optionCountryCodes from "../../../providers/optionCountryCodes";

import optionStateCodesUnitedState from "../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../providers/optionStateCodesCanada";

import ReCAPTCHA from "react-google-recaptcha";

import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import FloatSelect from "../../../providers/FloatSelect";
// import FloatDatePicker from "../../../providers/FloatDatePicker";
// import FloatSelectWithDangerouslySetInnerHTML from "../../../providers/FloatSelectWithDangerouslySetInnerHTML";
import validateRules from "../../../providers/validateRules";
import FloatInputPhone from "../../../providers/FloatInputPhone";
import axios from "axios";

export default function PageRegister() {
  const history = useHistory();
  const [collapseActiveKey, setCollapseActiveKey] = useState("1");

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();
  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");
  const [optionZip, setOptionZip] = useState();
  const [zipLabel, setZipLabel] = useState("Zip Code");
  const [captchaToken, setCaptchaToken] = useState("");
  const [captchaTokenError, setCaptchaTokenError] = useState(false);

  const [completePurchaseErr, setCompletePurchaseErr] = useState({
    type: "",
    message: "",
  });
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      //    console.log(getWindowSize().innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    console.log(windowSize.innerWidth);
  }, [windowSize]);

  const { mutate: mutateRegister, isLoading: isLoadingRegister } = POST(
    "api/v1/register",
    "register"
  );

  useEffect(() => {
    setOptionState(stateUS);
    setStateLabel("State");
    setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    setZipLabel("Zip Code");
  }, []);

  useEffect(() => {
    if (captchaToken && captchaTokenError) {
      setCaptchaTokenError(false);
    }
  }, [captchaToken]);

  const onFinishInfomation = (values) => {
    let phone = values.phone;

    phone = phone.replace(/[^0-9]/g, "");

    // form.validateFields(["username", "email", "phone"]).then((values) => {
    //   checkAccount(values, "multi");
    //   // checkAccount(values.email, "email");
    // });

    if (!captchaToken) {
      setCaptchaTokenError(true);
    } else {
      setCaptchaTokenError(false);
      let data = {
        ...values,
        link_origin: window.location.origin,
        phone: phone,
      };
      mutateRegister(data, {
        onSuccess: (res) => {
          if (res.success) {
            setCompletePurchaseErr({
              type: "success",
              message:
                "A confirmation e-mail has been send please check your inbox or your spam folder for the next step.",
            });
          } else {
            form.resetFields(["username", "email", "phone"]);
            form.setFieldsValue({
              username: data?.username,
              email: data?.email,
              phone: "+" + data?.phone,
            });
            if (res.array_message.includes("Username is already registered!")) {
              form.setFields([
                {
                  name: "username",
                  errors: ["Username is already registered!"],
                },
              ]);
            }

            if (res.array_message.includes("Email is already registered!")) {
              form.setFields([
                {
                  name: "email",
                  errors: ["Email is already registered!"],
                },
              ]);
            }

            if (res.array_message.includes("Phone is already registered!")) {
              form.setFields([
                {
                  name: "phone",
                  errors: ["Phone is already registered!"],
                },
              ]);
            }

            setCompletePurchaseErr({
              type: "error",
              message: res.array_message
                // .map((item) => item + "<br/>")
                // .map((item) => item + ", ")
                .join(", "),
            });
          }
        },
        onError: (err) => {
          setCompletePurchaseErr({
            type: "error",
            message: err.response.data.message,
          });
        },
      });
    }
  };

  const [form] = Form.useForm();

  const checkAccount = (value, type) => {
    if (type === "phone") {
      value = value.replace(/[^0-9]/g, "");
    }
    let data = {
      value: value,
      type: type,
    };

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
          // form.submit();
        } else {
          form.setFields([
            {
              name: type,
              errors: [res.message],
            },
          ]);
        }
      },
      onError: (err) => {
        form.setFields([
          {
            name: type,
            errors: ["Error on checking " + type + "!"],
          },
        ]);
      },
    });
  };

  const { mutate: mutateCheckAccount } = POSTNOLOADING(
    "api/v1/check_account",
    "check_account",
    false
  );

  return (
    <Layout className="public-layout register-layout">
      <Layout.Content>
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Image
              className="zoom-in-out-box"
              onClick={() => history.push("/")}
              src={fullwidthlogo}
              preview={false}
            />

            <div className="register-sub-title"></div>

            <Card className="card--public-body">
              <ComponentHeader
                title="Registration"
                subtitle="New User"
                icon={faEdit}
              />

              <Collapse
                accordion
                expandIconPosition="end"
                activeKey={collapseActiveKey}
                onChange={(e) => setCollapseActiveKey(e)}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                }
                collapsible="disabled"
              >
                <Collapse.Panel
                  header={
                    <>
                      <div className="title">Step 1</div>
                      <div className="sub-title">Complete All Fields Below</div>
                    </>
                  }
                  key="1"
                >
                  <Form
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinishInfomation}
                    form={form}
                    // scrollToFirstError
                  >
                    <Typography.Text className="form-title">
                      User's Information
                    </Typography.Text>
                    <Form.Item
                      name="firstname"
                      className="m-t-sm"
                      hasFeedback
                      rules={[validateRules.required]}
                    >
                      <FloatInput
                        label="First Name"
                        placeholder="First Name"
                        required
                      />
                    </Form.Item>

                    <Form.Item
                      name="lastname"
                      hasFeedback
                      rules={[validateRules.required]}
                    >
                      <FloatInput
                        label="Last Name"
                        placeholder="Last Name"
                        required
                      />
                    </Form.Item>

                    <Form.Item
                      name="username"
                      hasFeedback
                      rules={[validateRules.required, validateRules.username]}
                    >
                      <FloatInput
                        label="Username"
                        placeholder="Username"
                        required
                        onBlurInput={(e) => checkAccount(e, "username")}
                      />
                    </Form.Item>

                    <Form.Item
                      name="phone"
                      rules={[
                        validateRules.required,
                        validateRules.international_phone,
                      ]}
                      className="class-phone"
                    >
                      <FloatInputPhone
                        international={true}
                        label="Phone"
                        placeholder="Phone"
                        onBlurInput={(e) => {
                          checkAccount(e.target.value, "phone");
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="birthdate"
                      rules={[validateRules.birthday]}
                    >
                      <FloatInputMaskDate
                        label="Date of Birth (MM/DD/YYYY)"
                        placeholder="Date of Birth"
                        maskLabel="Date of Birth"
                        maskType="mm/dd/yyyy"
                      />
                    </Form.Item>

                    <Form.Item
                      name="email"
                      hasFeedback
                      rules={[validateRules.email, validateRules.required]}
                    >
                      <FloatInput
                        label="Email"
                        placeholder="Email"
                        required
                        onBlurInput={(e) => checkAccount(e, "email")}
                      />
                    </Form.Item>

                    <Form.Item
                      name="confirm_email"
                      hasFeedback
                      dependencies={["email"]}
                      rules={[
                        validateRules.required,
                        validateRules.email_validate,
                      ]}
                    >
                      <FloatInput
                        label="Confirm Email"
                        placeholder="Confirm Email"
                        required
                      />
                    </Form.Item>

                    <Form.Item
                      name="address_1"
                      hasFeedback
                      rules={[validateRules.required]}
                    >
                      <FloatInput
                        label="Address"
                        placeholder="Address"
                        required
                      />
                    </Form.Item>

                    <Form.Item
                      name="city"
                      hasFeedback
                      rules={[validateRules.required]}
                    >
                      <FloatInput label="City" placeholder="City" required />
                    </Form.Item>

                    <Form.Item
                      name="state"
                      hasFeedback
                      // className="form-select-error"
                      className="w-100"
                      rules={[validateRules.required]}
                    >
                      <FloatSelect
                        label={stateLabel}
                        placeholder={stateLabel}
                        options={optionState}
                        onChange={() => {
                          // console.log("adasdasd");
                          // form.setFieldValue("zip_code", "");
                        }}
                        required
                        allowClear
                      />
                    </Form.Item>

                    <Form.Item
                      name="zip_code"
                      hasFeedback
                      className="w-100"
                      rules={[
                        validateRules.required,
                        {
                          pattern: optionZip,
                          message: "Invalid Post Code / ZIP Code!",
                        },
                      ]}
                    >
                      <FloatInput
                        label={"Post Code / ZIP Code"}
                        placeholder={"Post Code / ZIP Code"}
                        required
                      />
                    </Form.Item>

                    <div>
                      <Typography.Text>
                        This page is protected by reCAPTCHA, and subject to the
                        Google{" "}
                        <Typography.Link
                          href="https://policies.google.com/privacy?hl=en"
                          className="color-1"
                          target="new"
                        >
                          Privacy Policy
                        </Typography.Link>{" "}
                        and{" "}
                        <Typography.Link
                          href="https://policies.google.com/terms?hl=en"
                          className="color-1"
                          target="new"
                        >
                          Terms of Services.
                        </Typography.Link>
                      </Typography.Text>
                    </div>
                    <>
                      <div className="captchaDiv">
                        <ReCAPTCHA
                          size={
                            windowSize.innerWidth < 350 ? "compact" : "normal"
                          }
                          style={{ marginTop: 10 }}
                          onChange={(token) => setCaptchaToken(token)}
                          // theme="dark"
                          render="explicit"
                          className="captcha-registration"
                          // render="explicit"
                          sitekey="6LfNfXgfAAAAAAbDCNuzI35Cm0hW_YwJ4UC0TYgw"
                          // onloadCallback={() => {}}
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {captchaTokenError && (
                            <span
                              style={{
                                color: "#dc3545",
                              }}
                            >
                              Please Verify Captcha
                            </span>
                          )}
                        </div>
                      </div>
                    </>

                    <Form.Item>
                      <Button
                        type="primary"
                        // htmlType="submit"
                        className="btn-main btn-register-here"
                        style={{ marginTop: "10px" }}
                        loading={isLoadingRegister}
                        size="large"
                        block
                        onClick={() => {
                          form
                            .validateFields()
                            .then((values) => {
                              onFinishInfomation(values);
                            })
                            .catch((info) => {
                              console.log(info);
                              setCompletePurchaseErr({
                                type: "error",
                                message: "Please Check Invalid Fields!",
                              });
                            });
                        }}
                      >
                        SUBMIT
                      </Button>
                    </Form.Item>

                    {completePurchaseErr.message && (
                      <Alert
                        className="m-t-sm m-b-sm register-alert"
                        type={completePurchaseErr.type}
                        message={
                          <div
                            dangerouslySetInnerHTML={{
                              __html: completePurchaseErr.message,
                            }}
                          />
                        }
                      />
                    )}
                  </Form>
                </Collapse.Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Footer className="text-center">
        <Typography.Text className="copyright-txt">
          © Copyright {moment().format("YYYY")} {description}. All Rights
          Reserved.
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
}
