import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Col,
  Card,
  Row,
  Collapse,
  Typography,
  Divider,
  Button,
  Table,
  Alert,
  notification,
} from "antd";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatInputMaskDate from "../../../../providers/FloatInputMaskDate";

import FloatInput from "../../../../providers/FloatInput";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import FloatSelect from "../../../../providers/FloatSelect";

import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { GET, POST } from "../../../../providers/useAxiosQuery";

import {
  TablePageSize,
  TableGlobalSearch,
  TableShowingEntries,
  TablePagination,
} from "../../Components/ComponentTableFilter";
import { data } from "jquery";
import ModalAddTask from "../../Components/ModalAddTask";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";
import FloatInputPhone from "../../../../providers/FloatInputPhone";
import validateRules from "../../../../providers/validateRules";

function PageUserForm() {
  // const history = useHistory();
  const { id } = useParams();
  const [user, setUsersData] = useState([]);
  const [form] = Form.useForm();

  // GET(`api/v1/users/${id}`, "show", (res) => {
  //   if (res.success) {
  //     setUsersData(res.data);
  //     // console.log("user", user.firstname);
  //   }
  // });

  // const [tableFilter, setTableFilter] = useState({
  //   page: 1,
  //   page_size: 50,
  //   search: "",
  //   sort_field: "id",
  //   sort_order: "desc",
  // });

  // const [tableTotal, setTableTotal] = useState(0);
  // const [dataSource, setDataSource] = useState([]);
  // const { refetch: refetchTable } = GET(
  //   //	`api/v1/users?${$.param(tableFilter)}`,
  //   "users",
  //   (res) => {
  //     if (res.success) {
  //       console.log("dataTable", res);
  //       setDataSource(res.data && res.data.data);
  //       setTableTotal(res.data.total);
  //     }
  //   }
  // );

  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");
  const [optionZip, setOptionZip] = useState();
  const [zipLabel, setZipLabel] = useState("Zip Code");

  const [toggleModal, setToggleModal] = useState(false);
  const { mutate: mutateRegister, isLoading: isLoadingRegister } = POST(
    "api/v1/user/consultant_register",
    "register"
  );

  useEffect(() => {
    setOptionState(stateUS);
    setStateLabel("State");
    setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    setZipLabel("Zip Code");
  }, []);

  const [completePurchaseErr, setCompletePurchaseErr] = useState({
    type: "",
    message: "",
  });

  const onFinishInfomation = (values) => {
    let phone = values?.phone?.replace(/[^0-9]/g, "");
    let data = {
      ...values,
      link_origin: window.location.origin,
      role: "Consultant",
      phone: phone,
    };

    mutateRegister(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description:
              "An Email has been sent to consultant for password setup",
          });
          form.resetFields();
        } else {
          form.resetFields(["username", "email", "phone"]);
          form.setFieldsValue({
            username: data?.username,
            email: data?.email,
            phone: "+" + data?.phone,
          });
          if (res.array_message.includes("Username is already registered!")) {
            form.setFields([
              {
                name: "username",
                errors: ["Username is already registered!"],
              },
            ]);
          }

          if (res.array_message.includes("Email is already registered!")) {
            form.setFields([
              {
                name: "email",
                errors: ["Email is already registered!"],
              },
            ]);
          }

          if (res.array_message.includes("Phone is already registered!")) {
            form.setFields([
              {
                name: "phone",
                errors: ["Phone is already registered!"],
              },
            ]);
          }

          notification.warning({
            message: "Warning",
            description: (
              <div
                dangerouslySetInnerHTML={{
                  __html: res.array_message.join("<br/>"),
                }}
              />
            ),
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Error",
          description: "Something went wrong while registering consultant!",
        });
      },
    });
  };

  const checkAccount = (value, type) => {
    if (type === "phone") {
      value = value.replace(/[^0-9]/g, "");
    }
    let data = {
      value: value,
      type: type,
    };

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
        } else {
          form.setFields([
            {
              name: type,
              errors: [res.message],
            },
          ]);
        }
      },
      onError: (err) => {
        form.setFields([
          {
            name: type,
            errors: ["Error on checking " + type + "!"],
          },
        ]);
      },
    });
  };

  const { mutate: mutateCheckAccount } = POST(
    "api/v1/check_account",
    "check_account",
    false
  );

  return (
    <Card className="card--padding-mobile">
      <Collapse
        className="main-4-collapse border-none register-consultant-collapse"
        defaultActiveKey={["1"]}
        expandIcon={({ isActive }) =>
          isActive ? (
            <FontAwesomeIcon
              style={{ color: "#325db8", fontSize: 18 }}
              icon={faMinus}
            />
          ) : (
            <FontAwesomeIcon
              style={{ color: "#325db8", fontSize: 18 }}
              icon={faPlus}
            />
          )
        }
        expandIconPosition="end"
      >
        <Collapse.Panel
          header={
            <div className="flex">
              <div style={{ width: "300 px" }}>Consultant Information</div>
            </div>
          }
          key="1"
          className="accordion bg-darkgray-form m-b-md  white"
        >
          <Form
            autoComplete="off"
            form={form}
            onFinish={onFinishInfomation}
            className="consultant-register"
          >
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="firstname"
                  //className="m-t-sm"

                  rules={[validateRules.required]}
                >
                  <FloatInput label="First Name" placeholder="First Name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="lastname" rules={[validateRules.required]}>
                  <FloatInput label="Last Name" placeholder="Last Name" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="username"
                  rules={[
                    validateRules.required,
                    ({ getFieldValue }) => ({
                      validator(_, value = "") {
                        if (
                          !value
                            .toLowerCase()
                            .match(
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            )
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Invalid username format!")
                        );
                      },
                    }),
                  ]}
                >
                  <FloatInput
                    label="Username"
                    placeholder="Username"
                    onBlurInput={(e) => {
                      checkAccount(e, "username");
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item name="birthdate" rules={[validateRules.birthday]}>
                  {/* <FloatDatePicker
                          label="Birthdate"
                          placeholder="Birthdate"
                          onBlurInput={(e) => handleBlur(e, "birthdate", 1)}
                        /> */}
                  <FloatInputMaskDate
                    label="Date of Birth (MM/DD/YYYY)"
                    placeholder="Date of Birth"
                    maskLabel="Date of Birth"
                    maskType="mm/dd/yyyy"
                    // onBlurInput={(e) => {
                    //   handleBlur(e, "birthdate", 1);
                    // }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid email!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <FloatInput
                    label="Email"
                    placeholder="Email"
                    onBlurInput={(e) => checkAccount(e, "email")}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="confirm_email"
                  dependencies={["email"]}
                  rules={[
                    validateRules.required,
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("email") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two emails that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <FloatInput
                    label="Confirm Email"
                    placeholder="Confirm Email"
                  />
                </Form.Item>
              </Col>

              {/* <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="ghl_calendar_id"
                  
                  rules={[
                    // {
                    //   message: "The input is not valid",
                    // },
                    {
                      required: true,
                      message: "Please input your GHL Calendar ID!",
                    },
                  ]}
                >
                  <FloatInput label="GHL Calendar ID" placeholder="GHL Calendar ID" />
                </Form.Item>
              </Col> */}

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="address_1"
                  rules={[
                    // {
                    //   message: "The input is not valid",
                    // },
                    {
                      required: true,
                      message: "Please input your address!",
                    },
                  ]}
                >
                  <FloatInput label="Address" placeholder="Address" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="city"
                  rules={[
                    // {
                    //   message: "The input is not valid",
                    // },
                    {
                      required: true,
                      message: "Please input your city!",
                    },
                  ]}
                >
                  <FloatInput label="City" placeholder="City" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                {/* 
                    <Form.Item
                      name="country"
                      
                      className="form-select-error"
                      rules={[
                        {
                          required: true,
                          message: "This field is required.",
                        },
                      ]}
                    >
                      <FloatSelect
                        label="Country"
                        placeholder="Country"
                        options={optionCountryCodes}
                        onChange={handleCountry}
                      />
                    </Form.Item> */}

                <Form.Item
                  name="state"
                  className="form-select-error the-state"
                  rules={[validateRules.required]}
                >
                  <FloatSelect
                    label={stateLabel}
                    placeholder={stateLabel}
                    options={optionState}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="zip_code"
                  className="w-100"
                  rules={[
                    validateRules.required,
                    {
                      pattern: optionZip,
                      message: "Invalid Post Code / ZIP Code!",
                    },
                  ]}
                >
                  <FloatInput
                    label={"Post Code / ZIP Code"}
                    placeholder={"Post Code / ZIP Code"}
                  />
                  {/* /   / <FloatInput label="Zip Code" placeholder="Zip Code" /> */}
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    validateRules.required,
                    validateRules.international_phone,
                  ]}
                >
                  <FloatInputPhone
                    international={true}
                    label="Phone"
                    placeholder="Phone"
                    onBlurInput={(e) => {
                      let value = e.target.value;
                      if (value) {
                        let phone = value.replace(/[^0-9]/g, "");
                        checkAccount(phone, "phone");
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="text-right" xs={24} sm={24} md={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-main btn-register-here"
                  size="large"
                  loading={isLoadingRegister}
                >
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>

      <ModalAddTask
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        user={user}
      />
    </Card>
  );
}

export default PageUserForm;
