import React from "react";
import { Col, Divider, Row, Typography } from "antd";

export default function PageCheckoutComponentProductDetails(props) {
  const { poductDetails, couponCode } = props;

  const totalAmount = poductDetails?.reduce(
    (total, item) => total + item?.quantity * Number(item?.amount),
    0
  );
  return (
    <>
      <Row gutter={24}>
        <Col xs={14} sm={18} md={18} lg={18} xl={18}>
          <div className="p-sm">
            <Typography.Text strong>Product</Typography.Text>
          </div>
        </Col>
        <Col xs={10} sm={6} md={6} lg={6} xl={6}>
          <div className="p-sm">
            <Typography.Text strong>Total</Typography.Text>
          </div>
        </Col>
      </Row>
      <Divider
        style={{ margin: "0px 0px", borderTop: "1px solid rgb(217 217 217)" }}
      />

      {poductDetails?.map((item, index) => {
        return (
          <div key={index}>
            <Row gutter={24}>
              <Col xs={14} sm={18} md={18} lg={18} xl={18}>
                <div className="p-sm">
                  <Typography.Text>{item?.name}</Typography.Text> x{" "}
                  <Typography.Text strong>{item?.quantity}</Typography.Text>
                </div>
              </Col>
              <Col xs={10} sm={6} md={6} lg={6} xl={6}>
                <div className="p-sm">
                  <Typography.Text strong>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(Number(item.amount))}
                  </Typography.Text>
                </div>
              </Col>
            </Row>
            <Divider
              style={{
                margin: "0px 0px",
                borderTop: "1px solid rgb(217 217 217)",
              }}
            />
          </div>
        );
      })}

      {couponCode?.coupon_name && (
        <>
          <Row gutter={24}>
            <Col xs={14} sm={18} md={18} lg={18} xl={18}>
              <div className="p-sm">
                <Typography.Text strong>Discount</Typography.Text>
              </div>
            </Col>
            <Col xs={10} sm={6} md={6} lg={6} xl={6}>
              <div className="p-sm">
                <Typography.Text strong>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(couponCode?.coupon_amount)}
                </Typography.Text>
              </div>
            </Col>
          </Row>
          <Divider
            style={{
              margin: "0px 0px",
              borderTop: "1px solid rgb(217 217 217)",
            }}
          />
        </>
      )}

      <Row gutter={24}>
        <Col xs={14} sm={18} md={18} lg={18} xl={18}>
          <div className="p-sm">
            <Typography.Text strong>Subtotal</Typography.Text>
          </div>
        </Col>
        <Col xs={10} sm={6} md={6} lg={6} xl={6}>
          <div className="p-sm">
            <Typography.Text strong>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(couponCode?.total ? couponCode?.total : totalAmount)}
            </Typography.Text>
          </div>
        </Col>
      </Row>
      <Divider
        style={{ margin: "0px 0px", borderTop: "1px solid rgb(217 217 217)" }}
      />

      <Row gutter={24}>
        <Col xs={14} sm={18} md={18} lg={18} xl={18}>
          <div className="p-sm">
            <Typography.Text strong>Total</Typography.Text>
          </div>
        </Col>
        <Col xs={10} sm={6} md={6} lg={6} xl={6}>
          <div className="p-sm">
            <Typography.Text strong>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(couponCode?.total ? couponCode?.total : totalAmount)}
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </>
  );
}
