import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faChartNetwork,
  faCommentDots,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

const ConsultantSideMenu = [
  {
    page: "Dashboard",
    title: "Dashboard",
    path: "/dashboard",
    icon: <FontAwesomeIcon icon={faChartNetwork} />,
  },
  {
    page: "Calendars",
    title: "Calendars",
    path: "/appointment",
    icon: <FontAwesomeIcon icon={faCalendarDays} />,
    children: [
      {
        page: "My Availability",
        title: "My Availability",
        path: "/appointment/schedules",
      },
      {
        page: "My Bookings",
        title: "My Bookings",
        path: "/appointment/bookings",
      },
    ],
  },
  {
    page: "Clients",
    title: "Clients",
    path: "/user",
    icon: <FontAwesomeIcon icon={faUser} />,
  },
  {
    page: "Messages",
    title: "Messages",
    path: "/messages",
    icon: <FontAwesomeIcon icon={faCommentDots} />,
  },
];

export default ConsultantSideMenu;
