import React, { useState } from "react";
import { Card, Collapse, Form, Input, notification, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import {
  formats,
  modulesToolBarV3,
} from "../../../../../providers/reactQuillOptions";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useEffect } from "react";
import FloatInput from "../../../../../providers/FloatInput";
import validateRules from "../../../../../providers/validateRules";

export default function PageEmailTemplates(props) {
  const { form, dataSource, onBlur, currentView } = props;

  const ColorClass = Quill.import("attributors/class/color");
  Quill.register(ColorClass, true);
  const ColorStyle = Quill.import("attributors/style/color");
  Quill.register(ColorStyle, true);

  const [activeKey, setActiveKey] = useState("0");
  const [activeKeySelected, setActiveKeySelected] = useState("0");
  useEffect(() => {
    setActiveKeySelected("0");
  }, [currentView]);

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (
        !document.querySelector(".ql-toolbar")?.contains(e.target) &&
        e.target !== document.querySelector(".ql-editor")
      ) {
      } else {
        e.preventDefault();
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <Form.List name="list" initialValue={{ list: "" }}>
      {(fields) => (
        <Collapse
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? (
              <FontAwesomeIcon
                style={{ color: "#325db8", fontSize: 18 }}
                icon={faMinus}
              />
            ) : (
              <FontAwesomeIcon
                style={{ color: "#325db8", fontSize: 18 }}
                icon={faPlus}
              />
            )
          }
          className="main-4-collapse border-none m-b-lg email-template-collapse"
          onChange={(key) => {
            setActiveKey(key);
            setActiveKeySelected(key);
          }}
        >
          {fields.map(({ name, ...restField }, index) => (
            <Collapse.Panel
              className="dashboard-collapse-header padding-on-dashboard"
              header={dataSource[index].title}
              key={index.toString()}
              style={{ marginBottom: "20px" }}
            >
              <Form.Item
                {...restField}
                name={[name, "subject"]}
                className="m-b-sm form-select-error"
                hasFeedback
                rules={[validateRules.required]}
              >
                <FloatInput
                  label="Subject"
                  placeholder="Subject"
                  onBlurInput={(e) => onBlur(e, name, "subject")}
                />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "body"]}
                style={{ marginBottom: "0px" }}
                hasFeedback
                rules={[
                  {
                    required: activeKey === index ? true : false,
                    message: "This field is required!",
                  },
                ]}
              >
                <ReactQuill
                  theme="snow"
                  modules={modulesToolBarV3}
                  formats={formats}
                  onBlur={(range, source, quill) => {
                    onBlur(quill.getHTML(), name, "body");
                  }}
                />
              </Form.Item>
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </Form.List>
  );
}
