import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { faSmile } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Empty, Input, Space, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import { apiUrl, appUrl, userData } from "../../../../../providers/companyInfo";
import moment from "moment";
import EmojiPicker from "emoji-picker-react";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { GET } from "../../../../../providers/useAxiosQuery";

export default function ChatContent(props) {
  const {
    dataSearchMessageConvo,
    userdata,
    filterMessage,
    filterMessageStatus,
    selectedMessage,
    handleRestoreMessage,
    handleNewMessageConvo,
    messageText,
    setMessageText,
    uploadref,
    toggleEmojiPicker,
    setToggleEmojiPicker,
    handleMessageAddEmoji,
  } = props;
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    // Close the dropdown if the click is outside of the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleEmojiPicker(false);
    }
  };

  // const { data: userList, refetch: refetchUserList } = GET(
  //   `api/v1/check_block_status`,
  //   [`check_block_status`],
  //   (res) => {
  //     if (res.success) {
  //     }
  //   }
  // );

  // const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    // Attach click event listener to the document body
    document.body.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Content className="site-layout-background">
      {filterMessageStatus !== "Blocked" && selectedMessage?.id && (
        <>
          <div
            id="messageConvoContainer"
            className="messageConvoContainer scrollbar-2"
          >
            {filterMessageStatus === "Archived" && (
              <div
                style={{
                  textAlign: "center",
                  background: "white",
                  marginRight: "5px",
                }}
              >
                This message is archived, please{" "}
                <a
                  href="#!"
                  onClick={
                    (e) =>
                      handleRestoreMessage(
                        selectedMessage.id,
                        selectedMessage.type
                      )
                    //  console.log("restore", selectedMessage)
                  }
                >
                  restore
                </a>{" "}
                this message to send a message again...
              </div>
            )}

            {dataSearchMessageConvo?.data?.message_convos.length >= 1 &&
            dataSearchMessageConvo?.data?.message_convos[0].message.message !=
              "" ? (
              dataSearchMessageConvo?.data?.message_convos?.map(
                (message, key) => {
                  // console.log("message", message);
                  var show = true;

                  if (
                    message.message == "" &&
                    message.message_convo_files?.length == 0
                  ) {
                    var show = false;
                  }

                  if (show == true) {
                    if (
                      message.from_id === userData().id ||
                      selectedMessage.type === "gc" ||
                      message.from_id === filterMessage.view_as_id
                    ) {
                      console.log("message1", message);
                      let image = message.from.profile_image;
                      if (image) {
                        image = image.includes("gravatar")
                          ? image
                          : `${apiUrl}${image}`;
                      } else {
                        image = `${apiUrl}images/default.png`;
                      }
                      return (
                        <div className="messageRight" key={key}>
                          <Space align="start" className="messageSapceGap">
                            <img
                              style={{
                                width: "35px",
                                height: "35px",
                                marginRight: 5,
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                              alt={"asdd" + key}
                              src={image}
                            />{" "}
                            <Tooltip
                              placement="top"
                              title={moment(message.created_at).format(
                                "YYYY-MM-DD h:mm A"
                              )}
                            >
                              <div className="messageNameDate">
                                <strong
                                  style={{
                                    color: "gray",
                                  }}
                                >
                                  {message.from.firstname +
                                    " " +
                                    message.from.lastname}{" "}
                                </strong>
                                <span>
                                  {moment(message.created_at).format(
                                    "MMM DD, h:mm A"
                                  )}
                                </span>
                              </div>
                              <div>{message.message}</div>
                              {message.message_convo_files &&
                                message.message_convo_files.length > 0 &&
                                message.message_convo_files.map((files) => {
                                  return (
                                    <div>
                                      <a
                                        target="_blank"
                                        href={
                                          apiUrl +
                                          "storage/files/" +
                                          files.file_url
                                        }
                                      >
                                        <span>
                                          <PaperClipOutlined />
                                        </span>
                                        <span> {files.file_url}</span>
                                      </a>
                                    </div>
                                  );
                                })}
                            </Tooltip>
                          </Space>
                        </div>
                      );
                    }
                    if (message.to_id === userData().id) {
                      console.log("message", message.message);
                      let image = message.from.profile_image;
                      if (image) {
                        image = image.includes("gravatar")
                          ? image
                          : `${apiUrl}${image}`;
                      } else {
                        image = `${apiUrl}img/default.png`;
                      }
                      return (
                        <div className="messageLeft" key={key}>
                          <Space align="start" className="messageSapceGap">
                            <img
                              style={{
                                width: 35,
                                height: 35,
                                marginRight: 5,
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                              alt={"asdd" + key}
                              src={image}
                            />{" "}
                            <Tooltip
                              placement="top"
                              title={moment(message.created_at).format(
                                "YYYY-MM-DD h:mm A"
                              )}
                            >
                              <div className="messageNameDate">
                                <strong
                                  style={{
                                    color: "gray",
                                  }}
                                >
                                  {message.from.firstname +
                                    " " +
                                    message.from.lastname}{" "}
                                </strong>
                                <span>
                                  {moment(message.created_at).format(
                                    "MMM DD, h:mm A"
                                  )}
                                </span>
                              </div>
                              <div>{message.message}</div>
                              {message.message_convo_files &&
                                message.message_convo_files.length > 0 &&
                                message.message_convo_files.map((files) => {
                                  return (
                                    <div>
                                      <a
                                        target="_blank"
                                        href={
                                          window.location.origin +
                                          "/storage/files/" +
                                          files.file_url
                                        }
                                      >
                                        <span>
                                          <PaperClipOutlined />
                                        </span>
                                        <span> {files.file_url}</span>
                                      </a>
                                    </div>
                                  );
                                })}
                            </Tooltip>
                          </Space>
                        </div>
                      );
                    }
                  }
                }
              )
            ) : (
              <Empty style={{ height: "auto", color: "grey" }} />
            )}
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              left: 0,
            }}
            className="messageText"
          >
            {" "}
            <>
              <Tooltip placement="top" title={"Add Emoji"}>
                <Dropdown
                  overlay={
                    <div className="emojiPicker" ref={dropdownRef}>
                      <EmojiPicker
                        height={300}
                        size={20}
                        lazyLoadEmojis={true}
                        onEmojiClick={(emojiObject, e) => {
                          handleMessageAddEmoji(emojiObject.emoji);
                        }}
                        skinTonesDisabled={true}
                        previewConfig={{ showPreview: false }}
                      />
                    </div>
                  }
                  placement="bottomRight"
                  // overlayClassName="ant-menu-submenu-notification-popup scrollbar-2"

                  trigger={["click"]}
                  open={toggleEmojiPicker}
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggleEmojiPicker(!toggleEmojiPicker);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSmile}
                    style={{ fontSize: "16px" }}
                    className={
                      "uploadMessagesFileIcon btn-emojiPicker " +
                      (filterMessageStatus === "Archived" ? "disabled" : "")
                    }
                    onClick={() => {
                      if (filterMessageStatus !== "Archived") {
                        setToggleEmojiPicker(!toggleEmojiPicker);
                      }
                    }}
                  />
                </Dropdown>
              </Tooltip>
            </>
            <Tooltip placement="top" title={"Add Files"}>
              <UploadOutlined
                style={{ fontSize: "16px" }}
                className={
                  "uploadMessagesFileIcon " +
                  (filterMessageStatus === "Archived" ? "disabled" : "")
                }
                onClick={() => {
                  if (filterMessageStatus !== "Archived") {
                    uploadref.current.click();
                  }
                }}
              />
            </Tooltip>
            <Input
              disabled={filterMessageStatus === "Archived"}
              size="large"
              placeholder="Type Message Here"
              value={messageText}
              onChange={(e) => {
                if (toggleEmojiPicker) {
                  setToggleEmojiPicker(false);
                }
                setMessageText(e.target.value);
              }}
              onPressEnter={(e) => handleNewMessageConvo()}
              addonAfter={
                <Button
                  disabled={filterMessageStatus === "Archived"}
                  onClick={(e) => {
                    handleNewMessageConvo();
                  }}
                  type="link"
                  icon={<FontAwesomeIcon icon={faPaperPlane} />}
                ></Button>
              }
            />
          </div>
        </>
      )}
    </Content>
  );
}
