import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Space,
  Form,
  Row,
  Col,
  notification,
  Divider,
} from "antd";

import { useForm } from "antd/lib/form/Form";
import FloatSelect from "../../../providers/FloatSelect";
import FloatInput from "../../../providers/FloatInput";
import { GET, POST, GETMANUAL } from "../../../providers/useAxiosQuery";
import ReactQuill from "react-quill";
import { formats, modulesToolBar } from "../../../providers/reactQuillOptions";

const ModalCancelledAppointment = ({
  showModal,
  setShowModal,
  bookingDetails,
  setBookingDetails,
}) => {
  const handleCancel = () => {
    form.resetFields();
    setMemberOption([]);
    setBookingDetails([]);
    setShowModal(false);
  };

  const [form] = useForm();
  const [searchFor, setSearchFor] = useState("Client");
  const [appointment_stage, setAppointmentStage] = useState([]);
  const [pipeline_stage, setPipelineStage] = useState([]);
  const handleSearchFor = (val, opt) => {
    setSearchFor(val);
    setMemberOption([]);
    form.resetFields(["type"]);

    if (val === "Same stage clients") {
      form.setFieldsValue({
        type: appointment_stage.name,
      });
    }
  };

  const [memberOption, setMemberOption] = useState([]);
  const handleMember = (val, opt) => {
    setMemberOption(opt["data-json"]);
  };

  const [member, setmember] = useState([]);
  GET(
    "api/v1/appointment/user_option",
    "appointment_user_options",
    (res) => {
      if (res.success) {
        let arr = [];
        res?.data &&
          res?.data?.map((row) => {
            let val =
              // row?.role
              //   ?.match(/(\b\S)?/g)
              //   ?.join("")
              //   ?.match(/(^\S|\S$)?/g)
              //   ?.join("")
              //   ?.toUpperCase() +
              // " - " +
              row?.firstname + " " + row?.lastname;

            arr.push({
              value: row?.id,
              label: val,
              json: row,
            });
          });
        setmember(arr);
      }
    },
    false
  );

  const { refetch: getOpportunity } = GETMANUAL(
    `api/v1/consultant/opportunity/${bookingDetails?.client?.id}`,
    "opportunity",
    (res) => {
      // console.log("bookingDetails", bookingDetails)
      if (res.success) {
        // console.log("data", res.data)
        if (res.data != "nodata") {
          res.pipeline_stages_appointment.map((appointment_stage) => {
            if (appointment_stage.status === "process") {
              setAppointmentStage(appointment_stage);

              form.setFieldValue("");
            }
          });

          let pipeline_stage_option = [];
          if (res.pipeline_stages && res.pipeline_stages.length > 0) {
            res?.pipeline_stages.map((stage) => {
              pipeline_stage_option.push({
                label: stage.name,
                value: stage.id,
              });
            });
          }
          // console.log("setPipelineStage", res)

          setPipelineStage(pipeline_stage_option);
        }
      }
    },
    false
  );

  const onFinishForm = (values) => {
    let data = {
      ...values,
      user_id: memberOption && memberOption.id,
      booking_details: bookingDetails,
      appointment_stage: appointment_stage && appointment_stage,
      link_origin: window.location.origin,
      id: bookingDetails?.id,
    };

    console.log("onFinishForm", data);
    mutateSendEmailNotification(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Clients has been notified!",
          });
          form.resetFields();
          setMemberOption([]);
          setBookingDetails([]);
          setShowModal(false);
        }
      },
    });
  };

  const [templateOption, setTemplateOption] = useState();
  const [emailTemplate, setEmailTemplate] = useState([]);
  GET(
    `api/v1/email_template?type=email&view=${0}&ginature=none`,
    "email_template_list",
    (res) => {
      if (res.success) {
        let option = [];

        res.data.forEach((template) => {
          option.push({
            label: template.title,
            value: template.id,
          });
        });
        setEmailTemplate(res.data);
        setTemplateOption(option);
      }
    },
    false
  );

  useEffect(() => {
    if (bookingDetails?.client?.id !== undefined) {
      getOpportunity();
    }

    if (showModal && emailTemplate.length > 0) {
      let template = emailTemplate?.find(
        (p) => p.title.toUpperCase() == "APPOINTMENT CANCELLATION NOTIFICATION"
      );

      form.setFieldsValue({
        search_for: "Client",
        body: template?.body ? template?.body : "",
        subject: template?.subject ? template?.subject : "",
        email_template: template?.id ? template?.id : undefined,
      });
    }
  }, [bookingDetails, showModal, emailTemplate]);

  const handleChangeEmailTemplate = (value) => {
    form.setFieldsValue({
      subject: emailTemplate.find((p) => p.id == value).subject,
      body: emailTemplate.find((p) => p.id == value).body,
    });
  };

  const {
    mutate: mutateSendEmailNotification,
    isLoading: isLoadingSendNotification,
  } = POST("api/v1/appointment/send_email_notification", "send_notification");

  const [role] = useState([
    {
      label: "All",
      value: "All",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Consultant",
      value: "Consultant",
    },
    {
      label: "User",
      value: "User",
    },
  ]);

  return (
    <>
      <Modal
        open={showModal}
        className="modal-send-email-notifiaction cancelled-appointment-notif"
        title="NOTIFICATION FORM"
        okText="Submit"
        width={700}
        onCancel={() => handleCancel()}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        footer={[
          <Space>
            <Button
              className="btn-primary atc-btn-opposite"
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    onFinishForm(values);
                  })
                  .catch((info) => {
                    notification.warning({
                      message: "Warning",
                      description: "Please complete the form!",
                    });
                  });
              }}
              loading={isLoadingSendNotification}
            >
              Submit
            </Button>
          </Space>,
        ]}
      >
        <Form
          form={form}
          name="panlistnew"
          layout="vertical"
          // initialValues={{
          //   search_for: "Client",
          //   body:
          //     emailTemplate.length > 0
          //       ? emailTemplate.find(
          //           (p) =>
          //             p.title.toUpperCase() ==
          //             "APPOINTMENT CANCELLATION NOTIFICATION"
          //         ).body
          //         ? emailTemplate.find(
          //             (p) =>
          //               p.title.toUpperCase() ==
          //               "APPOINTMENT CANCELLATION NOTIFICATION"
          //           ).body
          //         : ""
          //       : "",
          //   subject:
          //     emailTemplate.length > 0
          //       ? emailTemplate.find(
          //           (p) =>
          //             p.title.toUpperCase() ==
          //             "APPOINTMENT CANCELLATION NOTIFICATION"
          //         ).subject
          //         ? emailTemplate.find(
          //             (p) =>
          //               p.title.toUpperCase() ==
          //               "APPOINTMENT CANCELLATION NOTIFICATION"
          //           ).subject
          //         : ""
          //       : "",
          //   email_template:
          //     emailTemplate.length > 0
          //       ? emailTemplate.find(
          //           (p) =>
          //             p.title.toUpperCase() ==
          //             "APPOINTMENT CANCELLATION NOTIFICATION"
          //         ).id
          //         ? emailTemplate.find(
          //             (p) =>
          //               p.title.toUpperCase() ==
          //               "APPOINTMENT CANCELLATION NOTIFICATION"
          //           ).id
          //         : ""
          //       : "",
          // }}
          initialValues={{
            body: "",
          }}
        >
          <Form.Item name="id" style={{ display: "none" }}>
            <FloatInput label="id" placeholder="id" />
          </Form.Item>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                className="email-by"
                name="search_for"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <FloatSelect
                  label="Email by"
                  placeholder="Email by"
                  onChange={handleSearchFor}
                  options={[
                    {
                      label: "Same Stage Clients",
                      value: "Same stage clients",
                    },
                    {
                      label: "Client",
                      value: "Client",
                    },
                    {
                      label: "Stage",
                      value: "Stage",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="no-margin-bottom"
            >
              {searchFor === "Stage" && (
                <Form.Item
                  name="type"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <FloatSelect
                    label="Choose Stage"
                    placeholder="Choose Stage"
                    options={pipeline_stage && pipeline_stage}
                  />
                </Form.Item>
              )}

              {searchFor === "Same stage clients" && (
                <Form.Item
                  name="type"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <FloatInput
                    label="Client Current Stage"
                    placeholder="Client Current Stage"
                    disabled
                  />
                </Form.Item>
              )}
              {searchFor === "Client" && (
                <Form.Item
                  name="type"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                  className="form-select-error-multi"
                >
                  <FloatSelect
                    label="Choose clients"
                    placeholder="Choose Clients"
                    onChange={handleMember}
                    options={member}
                    multi="multiple"
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
          <Divider style={{ marginBottom: "24px" }}></Divider>
          <Row gutter={24} className="consultant-register-grid">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="email_template"
                rules={[{ required: true, message: "This field is required!" }]}
                className="form-select-error-multi choose-email-template"
              >
                <FloatSelect
                  label="Choose Email Template"
                  placeholder="Choose Email Template"
                  onChange={handleChangeEmailTemplate}
                  options={templateOption}
                  // disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                className="the-subject"
                name="subject"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <FloatInput label="Subject" placeholder="Subject" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item
                name="body"
                className="m-b-sm the-body"
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                ]}
              >
                <ReactQuill
                  className="notif-modal-quill"
                  theme="snow"
                  style={{ height: 250 }}
                  modules={modulesToolBar}
                  formats={formats}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalCancelledAppointment;
