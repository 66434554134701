import { Button, Form, Modal, Row, Space, Col, notification } from "antd";
import FloatSelect from "../../../../../providers/FloatSelect";
import FloatInput from "../../../../../providers/FloatInput";
import { GET, POST } from "../../../../../providers/useAxiosQuery";
import { useState } from "react";
import { userData } from "../../../../../providers/companyInfo";
import validateRules from "../../../../../providers/validateRules";

export default function ModalNewGrouChat(props) {
  const { showModal, setShowModal } = props;

  const [form1] = Form.useForm();

  const [members, setMembers] = useState([]);

  const { refetch: refetchUser, isLoading: isLoadingUsers } = GET(
    `api/v1/get_all_user`,
    [`messages_user`],
    (res) => {
      if (res.success) {
        let users = res.data.map((user) => {
          return {
            value: user.id,
            label: user.fullname,
          };
        });

        setMembers(users);
      }
    }
  );

  const { mutate: mutateNewGroupChat, isLoading: isLoadingNewGroupChat } = POST(
    `api/v1/new_group_chat`,
    [`new_group_chat`, "messages"]
  );

  const onFinish = (values) => {
    values.members.push(userData().id);

    // console.log("Success:", values);
    mutateNewGroupChat(values, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "New Group Chat",
            description: "Group Chat Successfully Created! ",
          });

          form1.resetFields();
          setShowModal(false);
        }
      },
    });
  };

  return (
    <Modal
      open={showModal}
      className="modal modal-email-template"
      title="NEW GROUP CHAT"
      okText="Submit"
      cancelText="Cancel"
      width={400}
      onCancel={() => {
        form1.resetFields();
        setShowModal(false);
      }}
      footer={[
        <Space>
          <Button
            className="atc-btn-opposite"
            onClick={() => {
              form1
                .validateFields()
                .then((values) => {
                  onFinish(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
            // loading={isLoadingNotification}
          >
            Submit
          </Button>
        </Space>,
      ]}
    >
      {/*  // initialValues={{ search_for: "Role" }}*/}
      <Form
        form={form1}
        name="new_group_chat"
        layout="vertical"
        // onFinish={onFinishNewEmailTemplate}
        // onFinishFailed={onFinishFailedNewEmailTemplate}
      >
        <Row>
          {/* <Col xs={24} sm={24} md={24} lg={24}>
            {" "}
            <Form.Item
              name="group_message_name"
              className="m-b-sm form-select-error"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <FloatInput
                label="Group Chat Name"
                placeholder="Group Chat Name"
              />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={24} md={24} lg={24}>
            {" "}
            <Form.Item
              name="members"
              className="m-b-sm form-select-error"
              rules={[validateRules.required]}
            >
              <FloatSelect
                label="Members"
                placeholder="Members"
                sea
                multi="multiple"
                options={members}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
