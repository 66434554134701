import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Layout,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
  Modal,
  Form,
  notification,
  Switch,
  Skeleton,
} from "antd";

import {
  DeleteOutlined,
  RedoOutlined,
  InboxOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  PaperClipOutlined,
  EyeOutlined,
  StopOutlined,
} from "@ant-design/icons";
import $ from "jquery";
import FloatSelect from "../../../../providers/FloatSelect";

import moment from "moment";
import optionUserType from "../../../../providers/optionUserType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import companyInfo, {
  appUrl,
  role,
  userData,
} from "../../../../providers/companyInfo";
import { GET, GETMANUAL, POST } from "../../../../providers/useAxiosQuery";
import { faSmile, faUserGroup } from "@fortawesome/pro-regular-svg-icons";

import { faSquare } from "@fortawesome/free-solid-svg-icons";
import ChatSideMenu from "./Component/ChatSideMenu";
import Pusher from "pusher-js";
import ModalNewGrouChat from "./Component/ModalNewGroupChat";
import ChatContent from "./Component/ChatContent";
import ModalChangeGroupMessageName from "./Component/ModalChangeGroupMessageName";
import { useQueryClient } from "react-query";
import validateRules from "../../../../providers/validateRules";

const { Sider, Content } = Layout;

function PageMessages() {
  let apiUrl = companyInfo().apiUrl;
  const urlParams = new URLSearchParams(window.location.search);
  const user_selected = urlParams.get("message_id");
  const group_chat_id = urlParams.get("group_chat_id");
  const history = useHistory();
  const queryClient = useQueryClient();

  localStorage.removeItem("fetchNotif");

  const { mutate: mutatePushNotif, isLoading: isLoadingMutatePushNotif } =
    POST("api/v1/push_notif");

  //pusher listener
  useEffect(() => {
    // Pusher.logToConsole = true;

    var pusher = new Pusher("5bb9eaf554f432b30222", {
      cluster: "us3",
    });

    var channel = pusher.subscribe("atc_listener");
    channel.bind("atc_listener", function (receive_message) {
      let _receive_message = receive_message.message;

      if (_receive_message.location === "http://127.0.0.1:3002") {
        _receive_message.location = "http://localhost:3002";
      }
      // console.log("receive_message", userdata.id);

      // alert(JSON.stringify(receive_message.message));
      if (
        (_receive_message.room == userdata.id &&
          _receive_message.location == window.location.origin &&
          _receive_message.type == "message") ||
        (_receive_message.type == "groupmessage" &&
          messageItems &&
          messageItems.find(
            (item) => item.id == _receive_message.room && item.type == "gc"
          ))
      ) {
        refetchMessages();
        refetchSearchMessageConvo();
      } else if (
        _receive_message.type == "message" &&
        _receive_message.members &&
        _receive_message.members?.length > 0 &&
        _receive_message.members?.includes(userdata.id)
      ) {
        setSelectedMessage({ id: _receive_message.room, type: "user" });
      }
    });

    // console.log("test message");

    return () => {};
  }, []);

  let userdata = companyInfo().userData;
  // const sub_title = "View";

  const [collapsed, setCollapsed] = useState(
    $(window).width() <= 768 ? true : false
  );

  const [messageItems, setMessageItems] = useState([]);
  const [archivedItems, setArchivedItems] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [toggleModalUpload, setToggleModalUpload] = useState(false);
  const uploadref = useRef(null);
  const [filterMessageStatus, setFilterMessageStatus] = useState("Active");

  const [filterMessage, setFilterMessage] = useState({
    get_messages: 1,
    message_sort: 1,
    view_as: "",
    view_as_id: null,
    is_viewing: false,
  });

  useEffect(() => {
    setTimeout(() => {
      if (filterMessage.is_viewing) {
        setSelectedMessage();
        $(".globalLoading").removeClass("hide");

        refetchMessages();
      } else if (
        filterMessage.view_as_id === null &&
        filterMessage.is_viewing === false
      ) {
        // console.log("here refetchMessages");
        $(".globalLoading").removeClass("hide");
        setSelectedMessage();
        refetchMessages();
      }
    }, 500);
  }, [filterMessage]);

  // const {
  //   data: groupMessage,
  //   refetch: refetchUserGC,
  //   isLoading: isLoadingUserGC,
  // } = GET(
  //   `api/v1/get_user_group_chat?${$.param({ id: filterMessage.view_as_id })}`,
  //   [`messages_user_group_chat`],
  //   (res) => {
  //     console.log("here asdasd asdads asda ");
  //     if (res.success) {
  //       res.data.map((item, key) => {
  //         let image = item.icon;
  //         if (image) {
  //           image = image.includes("gravatar") ? image : `${apiUrl}${image}`;
  //         } else {
  //           image = `${apiUrl}images/default.png`;
  //         }

  //         item.icon = (
  //           <img
  //             style={{
  //               width: "25px",
  //               height: "25px",
  //               borderRadius: "50%",
  //               objectFit: "cover",
  //             }}
  //             src={image}
  //             alt=""
  //           />
  //         );

  //         return item;
  //       });

  //       console.log("group_message", res.data);
  //       // setSelectedMessage({
  //       //   id: res.data[0]?.id,
  //       //   type: res.data[0].type,
  //       // });

  //       // console.log("group_message", res.data);
  //     }
  //   }
  // );

  const {
    data: dataMessages,
    refetch: refetchMessages,
    isLoading: isLoadingMessages,
  } = GET(
    `api/v1/getMessageUser?${$.param(filterMessage)}`,
    [`messages`],
    (res) => {
      if (res.success) {
        $(".globalLoading").addClass("hide");
        let items = [];
        let items_archived = [];
        let items_blocked = [];
        res.data.map((item, key) => {
          let name = "";
          let icon;
          let id;
          let type;

          id = item.id;
          type = item.type;
          // name = `${item.firstname} ${item.lastname}`;
          name = item.name;

          // let image = item.profile_image;
          let image = item.icon;
          if (image) {
            image = image.includes("gravatar") ? image : `${apiUrl}${image}`;
          } else {
            image = `${apiUrl}images/default.png`;
          }

          icon = (
            <img
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={image}
              alt=""
            />
          );

          let isBlocked = false;

          if (item.blocked) {
            isBlocked = true;
          }

          if (!isBlocked) {
            if (item?.archived == 0 || userData().role != "Admin") {
              items.push({
                key: type + "_" + id,
                id: id,
                label: name,
                icon: icon,
                type: type,
                sort: item.message_id_convo_update
                  ? item.message_id_convo_update
                  : 0,
              });
            } else {
              items_archived.push({
                key: type + "_" + id,
                id: id,
                label: name,
                type: type,
                icon: icon,
                sort: item.message_id_convo_update
                  ? item.message_id_convo_update
                  : 0,
              });
              // console.log("archived", items_archived);
            }
          } else {
            items_blocked.push({
              key: type + "_" + id,
              id: id,
              label: name,
              type: type,
              icon: icon,
              sort: item.message_id_convo_update
                ? item.message_id_convo_update
                : 0,
            });
          }
        });

        // console.log(items, filterMessageStatus);

        if (filterMessageStatus == "Active") {
          // var sorted = sortMessage(items);
          //   setMessageItems(sorted);
          if (items.length > 0) {
            setSelectedMessage({ id: items[0].id, type: items[0].type });
          } else {
            setSelectedMessage({ id: null, type: null });
          }
          setMessageItems(items);
        } else if (filterMessageStatus == "Archived") {
          //   var sorted = sortMessage(items_archived);
          //   setMessageItems(sorted);
          if (items_archived.length > 0) {
            setSelectedMessage({
              id: items_archived[0].id,
              type: items_archived[0].type,
            });
          } else {
            setSelectedMessage({ id: null, type: null });
          }
          setMessageItems(items_archived);
        } else if (filterMessageStatus == "Blocked") {
          if (items_blocked.length > 0) {
            setSelectedMessage({
              id: items_blocked[0].id,
              type: items_blocked[0].type,
            });
          } else {
            setSelectedMessage({ id: null, type: null });
          }
          setMessageItems(items_blocked);
        }
      }
    }
  );

  const [userListOption, setUserListOption] = useState([]);
  const { data: userList, refetch: refetchUserList } = GET(
    `api/v1/consultant`,
    [`userList`],
    (res) => {
      if (res.success) {
        let userList = [];
        res.data?.map((item, key) => {
          userList.push({
            value: item.id,
            label: item.firstname + " " + item.lastname,
          });
        });

        setUserListOption(userList);
      }
    }
  );

  useEffect(() => {
    // if (filterMessageStatus !== "GroupChat") {
    refetchMessages();
    // } else {
    //   refetchUserGC();
    // }
    return () => {};
  }, [filterMessageStatus]);

  useEffect(() => {
    // localStorage.setItem("fetchNotif", 1);
    if (user_selected) {
      setSelectedMessage({ id: user_selected, type: "user" });

      $("#pageMessages .ant-menu-item").removeClass("ant-menu-item-selected");
      $("#pageMessages .item_key_user_" + user_selected).addClass(
        "ant-menu-item-selected"
      );
    }
    if (group_chat_id) {
      setSelectedMessage({ id: group_chat_id, type: "gc" });

      // $("#pageMessages .ant-menu-item").removeClass("ant-menu-item-selected");
      $("#pageMessages .item_key_gc_" + user_selected).addClass(
        "ant-menu-item-selected"
      );
    }

    if (selectedMessage) {
      $("#pageMessages ").removeClass("ant-menu-item-selected");
      $(
        "#pageMessages .item_key_" +
          selectedMessage.type +
          "_" +
          selectedMessage.id
      ).addClass("ant-menu-item-selected");
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageItems]);

  const [showNewMessageContainer, setShowNewMessageContainer] = useState(false);
  const { mutate: mutateStartNewConvNewConvo } = POST(
    "api/v1/message",
    `messages`
  );
  const [selectedMessage, setSelectedMessage] = useState({
    id: null,
    type: null,
  });

  const [messageCovos, setMessageConvos] = useState([]);

  const { data: dataSearchMessageConvo, refetch: refetchSearchMessageConvo } =
    GETMANUAL(
      `api/v1/message_convo/get?id=${
        selectedMessage ? selectedMessage.id : ""
      }&view_as_id=${filterMessage.view_as_id}`,
      [`selected_convo_${selectedMessage ? selectedMessage.id : ""}`],
      (res) => {
        // console.log("selected_convo_", res);
        if (res.success) {
          setMessageConvos(res);
          queryClient.refetchQueries("get_notification_alert");
          setTimeout(
            () => {
              scrollToBottom();
            },
            dataSearchMessageConvo ? 0 : 500
          );
        }
      },
      false
    );

  const { data: dataGroupMessagesConvo, refetch: refetchGroupMessageConvo } =
    GETMANUAL(
      `api/v1/group_message_convo?id=${
        selectedMessage ? selectedMessage.id : ""
      }&view_as_id=${filterMessage.view_as_id}`,
      `get_group_message_convo`,
      (res) => {
        // console.log("selected_convo_group", res);
        if (res.success) {
          setMessageConvos(res);
          setTimeout(
            () => {
              scrollToBottom();
            },
            dataSearchMessageConvo ? 0 : 500
          );
        }
      },
      false
    );

  const { mutate: mutateReadMessageNotication } = POST(
    "api/v1/readNotification",
    ["get_messages_alert"],
    false
  );

  useEffect(() => {
    if (selectedMessage) {
      if (toggleEmojiPicker) {
        setToggleEmojiPicker(false);
      }
      setMessageText("");
      // console.log("roleToSearch", roleToSearch);

      // console.log("selectedMessage.id", selectedMessage.id);
      if (selectedMessage.type !== "gc") {
        refetchSearchMessageConvo();
      } else {
        refetchGroupMessageConvo();
      }
      $("#pageMessages .ant-menu-item").removeClass("ant-menu-item-selected");
      $(
        "#pageMessages .item_key_" +
          selectedMessage.type +
          "_" +
          selectedMessage.id
      ).addClass("ant-menu-item-selected");

      // console.log("selectedMessage.id", selectedMessage.id);
    }

    if (selectedMessage?.type == "gc") {
      setWithSms(false);
    } else {
      if (userdata.role === "Admin") {
        setWithSms(true);
      }
    }

    mutateReadMessageNotication(
      { from_id: selectedMessage?.id },
      {
        onSuccess: (res) => {
          // console.log("res", res);
        },
      }
    );
  }, [selectedMessage]);

  const [messageText, setMessageText] = useState("");
  const [withSms, setWithSms] = useState(
    userdata.role === "Admin" ? true : false
  );

  const { mutate: mutateNewMessageConvo } = POST("api/v1/message_convo", [
    `selected_convo_${selectedMessage ? selectedMessage.id : ""}`,
    `messages`,
    "get_messages_alert",
  ]);

  const { mutate: mutateNewGroupMessageConvo } = POST(
    "api/v1/group_message_new_convo",
    [
      `messages`,
      // "messages_user_group_chat",
      // `get_group_message_convo`,
      "get_messages_alert",
    ]
  );

  const handleNewMessageConvo = () => {
    const formdata = new FormData();
    //view as
    if (filterMessage.is_viewing) {
      formdata.append("from_id", filterMessage.view_as_id);
    } else {
      formdata.append("from_id", userdata.id ?? "");
    }

    //to_id
    if (selectedMessage.type !== "gc") {
      formdata.append("to_id", selectedMessage.id);
    } else {
      formdata.append("group_message_id", selectedMessage.id);
    }

    formdata.append("message", messageText);

    //sms
    if (withSms) {
      formdata.append("with_sms", true);
    }

    formdata.append("link_origin", window.location.origin);

    if (fileList.length > 0) {
      fileList.map((item, index) => {
        if (item.image_from === "old") {
          formdata.append("old_files[]", item.file_id);
        } else {
          formdata.append("files_" + index, item.originFileObj, item.name);
        }
      });
      formdata.append("files_count", fileList.length);
    }

    if (selectedMessage && selectedMessage?.type !== "gc") {
      mutateNewMessageConvo(formdata, {
        onSuccess: (res) => {
          // console.log(res);
          setMessageText("");
          setToggleModalUpload(false);
          setFileList([]);
          // setShowNewMessageContainer(false);
          //edit
          if (res.success) {
            let send_message_to_chat_server = {
              room: selectedMessage?.id,
              // message: messageText,
              location: window.location.origin,
              type: "message",
            };
            // socketio.emit("send_message", send_message_to_chat_server);
            mutatePushNotif(
              { message: send_message_to_chat_server },
              {
                onSuccess: (res) => {
                  console.log(res);
                },
              }
            );
            // console.log(
            //     "send_message_to_chat_server",
            //     send_message_to_chat_server
            // );
            // console.log(userdata);
          }
        },
      });
    } else {
      mutateNewGroupMessageConvo(formdata, {
        onSuccess: (res) => {
          // console.log(res);
          setMessageText("");
          setToggleModalUpload(false);
          setFileList([]);
          // setShowNewMessageContainer(false);
          //edit
          if (res.success) {
            let send_message_to_chat_server = {
              room: selectedMessage?.id,
              // message: messageText,
              location: window.location.origin,
              type: "groupmessage",
            };
            //   // socketio.emit("send_message", send_message_to_chat_server);
            mutatePushNotif(
              { message: send_message_to_chat_server },
              {
                onSuccess: (res) => {
                  console.log("pusher", res);
                },
              }
            );
            // console.log(
            //     "send_message_to_chat_server",
            //     send_message_to_chat_server
            // );
            // console.log(userdata);
          }
        },
      });
    }
  };

  const scrollToBottom = () => {
    const objDiv = document.getElementById("messageConvoContainer");
    // objDiv.scrollTop = objDiv.scrollHeight;
    if (objDiv) {
      objDiv.animate({ scrollTop: objDiv.scrollHeight }, 0);
    }
  };

  const searchForOptions = optionUserType;

  useEffect(() => {
    // console.log("searchForOptions", searchForOptions);
    return () => {};
  }, [searchForOptions]);

  const { mutate: mutateArchiveMessage } = POST(
    "api/v1/message_archived?action=archive",
    [
      `selected_convo_${selectedMessage ? selectedMessage.id : ""}`,
      `message_${userdata.id}`,
      "messages",
    ]
  );

  const handleArchiveMessage = (to_id, type) => {
    let data = { to_id: to_id, type: type };
    mutateArchiveMessage(data, {
      onSuccess: (res) => {
        // console.log(res);
        // if (data.archived == 1) {
        setSelectedMessage();
        // if (selectedMessage.id == user_selected) {
        // }
        // }
      },
    });
  };

  const { mutate: mutateRestoreMessage } = POST(
    "api/v1/message_archived?action=restore",
    [
      `selected_convo_${selectedMessage ? selectedMessage.id : ""}`,
      `message_${userdata.id}`,
      "messages",
    ]
  );

  const handleRestoreMessage = (to_id, type) => {
    let data = { to_id: to_id, type: type };
    mutateRestoreMessage(data, {
      onSuccess: (res) => {
        // console.log(res);
        // if (data.archived == 1) {
        setSelectedMessage();
        // if (selectedMessage.id == user_selected) {

        // }
        // }
      },
    });
  };

  useEffect(() => {
    function handleResize() {
      if ($(window).width() <= 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const [isActiveButton, setIsActiveButton] = useState("create-message");

  const [isHideSearch, setHideSearch] = useState(true);
  const [toggleViewAs, setToggleViewAs] = useState(false);

  useEffect(() => {
    if (window.screen.width <= 372) {
      // console.log("less than");
      if (isHideSearch) {
        $(".searchForLittle label").css("display", "block");
      } else {
        $(".searchForLittle label").css("display", "none");
      }
    } else {
      $(".searchForLittle label").css("display", "block");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.screen.width, isHideSearch]);

  const sortMessage = (data) => {
    // console.log("data", data);
    return data.sort((a, b) => b.sort - a.sort);
  };

  const beforeUpload = (file) => {
    let isJpgOrPng = true;

    const allowedFileTypes = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "jpg",
      "doc",
      "docx",
      "pdf",
    ];

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      notification.warning({
        message: "Error",
        description:
          "You can only upload JPG, PNG, GIF, JPEG, DOC, DOCX and PDF files.",
      });
      return Upload.LIST_IGNORE;
    }
    const isLt30M = file.size / 1024 / 1024 < 30; // Check if the file is smaller than 5MB

    //const isLt2M = file.size / 102400 / 102400 < 10;
    if (!isLt30M) {
      notification.error({
        message: "Error",
        description: "Image must smaller than 30MB!",
      });
      setFileList([]);
      return false || Upload.LIST_IGNORE;
    }
    return isJpgOrPng || Upload.LIST_IGNORE;
  };

  const handleChangeUpload = ({ fileList: newFileList }) => {
    var _file = newFileList;
    // console.log(_file);
    _file.map((row, key) => {
      return (row.status = "done");
    });
    setFileList(_file);
    setToggleModalUpload(true);
  };

  const [form] = Form.useForm();
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const handleMessageAddEmoji = (emoji) => {
    setMessageText((prevMessage) => prevMessage + emoji);
  };

  const [toggleNewGC, setToggleNewGC] = useState(false);
  const [toggleRenameGC, setToggleRenameGC] = useState({
    toggle: false,
    id: null,
  });

  return (
    <div id="pageMessages">
      <Card
        id="PageMessages"
        className="page-message no-card-border card--padding-mobile"
        bodyStyle={{ padding: 0 }}
      >
        <div className="ant-card-content-wrapper">
          <div className="ant-card-content">
            <Row gutter={[12, 12]}>
              <Col xs={24} md={24} lg={24}>
                <Layout.Content>
                  {userdata.role === "Admin" && (
                    <>
                      {" "}
                      <Row>
                        <Col
                          xs={24}
                          sm={18}
                          md={18}
                          style={{ marginBottom: "20px" }}
                        >
                          <Space
                            className="ant-space-message-buttons"
                            size={10}
                          >
                            <Button
                              // block
                              type="primary"
                              size="large"
                              icon={<InboxOutlined />}
                              onClick={(e) => {
                                // setShowNewMessageContainer(
                                //     true
                                // );
                                setFilterMessageStatus("Active");
                                setIsActiveButton("create-message");
                                setSelectedMessage();
                              }}
                              className={
                                isActiveButton === "create-message"
                                  ? "btn-active-default"
                                  : " btn-message b-r-none atc-btn-opposite"
                              }
                              // style={{ paddingTop: 3 }}
                            >
                              {/* Create Message <PlusCircleOutlined style={{ marginTop: 4 }} /> */}
                              CHAT{" "}
                            </Button>

                            {/* <Button
                          // block
                          type="primary"
                          size="large"
                          icon={
                            <FontAwesomeIcon
                              icon={faUserGroup}
                              style={{
                                fontSize: "11px",
                                marginBottom: "2px",
                                marginRight: "5px",
                              }}
                            />
                          }
                          onClick={(e) => {
                            setFilterMessageStatus("GroupChat");
                            setIsActiveButton("group-chat");
                            setSelectedMessage();
                          }}
                          className={
                            isActiveButton === "group-chat"
                              ? "btn-active-default"
                              : " btn-message b-r-none atc-btn-opposite"
                          }
                          // style={{ paddingTop: 3 }}
                        >
                          GROUP CHAT{" "}
                        </Button> */}
                            {userdata.role == "Admin" && (
                              <Button
                                // block
                                type="primary"
                                size="large"
                                icon={<DeleteOutlined />}
                                onClick={(e) => {
                                  setFilterMessageStatus("Archived");
                                  setIsActiveButton("archived");
                                  setSelectedMessage();
                                }}
                                className={
                                  isActiveButton === "archived"
                                    ? "btn-active-default"
                                    : "btn-message b-r-none atc-btn-opposite "
                                }
                                // style={{ paddingTop: 3 }}
                              >
                                ARCHIVED{" "}
                              </Button>
                            )}

                            {userdata.username == "superadmin" && (
                              <Button
                                // block
                                type="primary"
                                size="large"
                                icon={<StopOutlined />}
                                onClick={(e) => {
                                  setFilterMessageStatus("Blocked");
                                  setIsActiveButton("blocked");
                                  setSelectedMessage();
                                }}
                                className={
                                  isActiveButton === "blocked"
                                    ? "btn-active-default"
                                    : "btn-message b-r-none atc-btn-opposite "
                                }
                                // style={{ paddingTop: 3 }}
                              >
                                BLOCKED{" "}
                              </Button>
                            )}
                          </Space>
                        </Col>
                      </Row>
                      <Row className="btn-cont-row">
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={18}
                          xl={18}
                          className="messagesContainer toolbar-cont"
                        >
                          <Space
                            className="the-view-as"
                            // block
                            direction="horizontal"
                            style={
                              {
                                // width: "100%",
                                // display: "flex",
                                // justifyContent: "space-between",
                              }
                            }
                          >
                            <Button
                              size="small"
                              className={
                                "atc-btn-opposite btn-message " +
                                (filterMessage.is_viewing
                                  ? "active-viewing"
                                  : "")
                              }
                              icon={
                                filterMessage.is_viewing ? (
                                  <FontAwesomeIcon
                                    icon={faSquare}
                                    style={{ margin: "0px 8px" }}
                                  />
                                ) : (
                                  <EyeOutlined />
                                )
                              }
                              type="primary"
                              onClick={(e) => {
                                if (filterMessage.is_viewing) {
                                  setFilterMessage({
                                    ...filterMessage,
                                    view_as: "",
                                    view_as_id: null,
                                    is_viewing: false,
                                  });
                                } else {
                                  setToggleViewAs(true);
                                }
                              }}
                            >
                              {filterMessage.is_viewing
                                ? "VIEWING AS " + filterMessage.view_as
                                : "VIEW AS CONSULTANT"}
                            </Button>

                            {filterMessageStatus !== "Archived" &&
                              selectedMessage?.type != "gc" && (
                                <Switch
                                  checkedChildren="WITH SMS"
                                  unCheckedChildren="NO SMS"
                                  size="large"
                                  checked={withSms}
                                  onChange={(e) => setWithSms(e)}
                                  style={{ float: "right" }}
                                />
                              )}
                          </Space>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Layout className="messagesContainer ">
                    <Sider
                      // collapsible
                      theme="light"
                      collapsed={collapsed}
                      onCollapse={(e) => {
                        setHideSearch(e);
                        setCollapsed(e);
                      }}
                      // style={{ paddingTop: 35 }}
                      className="scrollbar-2"
                    >
                      <div className="ChatMessageText">
                        {collapsed ? (
                          <MenuUnfoldOutlined
                            style={{
                              color: "#783b19",
                              position: "relative",
                              top: "2px",
                              fontSize: "17px",
                            }}
                            onClick={() => {
                              setCollapsed(false);
                            }}
                          />
                        ) : (
                          <Row>
                            <Col
                              xs={2}
                              md={2}
                              style={{
                                textAlign: "end",
                              }}
                            >
                              <span
                                style={{
                                  position: "relative",
                                  top: "2px",
                                  fontSize: "17px",
                                  right: "4px",
                                }}
                              >
                                <MenuFoldOutlined
                                  style={{
                                    color: "#783b19",
                                  }}
                                  onClick={() => {
                                    setCollapsed(true);
                                  }}
                                />
                              </span>
                            </Col>
                            <Col xs={22} md={22}>
                              <Typography.Title
                                level={4}
                                style={{
                                  marginLeft: "9px",
                                  marginBottom: "0px",
                                }}
                              >
                                {filterMessageStatus === "Archived"
                                  ? "Archived"
                                  : "Chat"}
                              </Typography.Title>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {isLoadingMessages ? (
                        <Skeleton
                          active
                          loading={isLoadingMessages}
                          style={{ padding: "20px" }}
                        />
                      ) : (
                        <ChatSideMenu
                          messageItems={messageItems}
                          userdata={userdata}
                          filterMessageStatus={filterMessageStatus}
                          handleRestoreMessage={handleRestoreMessage}
                          handleArchiveMessage={handleArchiveMessage}
                          setSelectedMessage={setSelectedMessage}
                          showModal={setToggleNewGC}
                          setShowModalRename={setToggleRenameGC}
                        />
                      )}
                    </Sider>

                    <Layout
                      className="site-layout"
                      style={{ background: "#fff" }}
                    >
                      <ChatContent
                        dataSearchMessageConvo={messageCovos}
                        filterMessageStatus={filterMessageStatus}
                        filterMessage={filterMessage}
                        selectedMessage={selectedMessage}
                        handleRestoreMessage={handleRestoreMessage}
                        handleNewMessageConvo={handleNewMessageConvo}
                        messageText={messageText}
                        setMessageText={setMessageText}
                        uploadref={uploadref}
                        toggleEmojiPicker={toggleEmojiPicker}
                        setToggleEmojiPicker={setToggleEmojiPicker}
                        handleMessageAddEmoji={handleMessageAddEmoji}
                      />
                    </Layout>
                  </Layout>
                </Layout.Content>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          title="SEND AS A FILE"
          open={toggleModalUpload}
          footer={
            <Space wrap>
              <Button
                size="large"
                type="success"
                className="ant-btn ant-btn-default btn-success "
                onClick={handleNewMessageConvo}
              >
                SEND
              </Button>
              <Button
                size="large"
                type="secondary"
                onClick={() => {
                  uploadref.current.click();
                }}
              >
                ADD MORE
              </Button>
              <Button
                size="large"
                type="secondary"
                onClick={() => {
                  setFileList([]);
                  setToggleModalUpload(false);
                }}
              >
                CANCEL
              </Button>
            </Space>
          }
          onCancel={() => {
            setFileList([]);
            setToggleModalUpload(false);
          }}
          forceRender
          // bodyStyle={{
          //     paddingBottom: "10px",
          // }}
          className="uploadMessagesFileModal message-modal"
        >
          <Upload
            name="avatar"
            listType="picture"
            className="avatar-uploader uploadMessagesFile"
            action={false}
            customRequest={() => {}}
            fileList={fileList}
            beforeUpload={beforeUpload}
            onChange={handleChangeUpload}
          >
            <Button
              type="primary"
              ref={uploadref}
              style={{
                display: "none",
              }}
              disabled={filterMessageStatus === "Archived"}
            ></Button>
          </Upload>

          <Input
            style={{ padding: 10 }}
            disabled={filterMessageStatus === "Archived"}
            size="large"
            placeholder="Type Message Here"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
        </Modal>

        <Modal
          title="VIEW AS"
          open={toggleViewAs}
          onCancel={() => {
            setToggleViewAs(false);
            form.resetFields();
          }}
          className="modal-primary-default modal-change-2-factor-authentication modal-appointment modal-message-view-as"
          bodyStyle={{ padding: "24px 16px 0px" }}
          footer={[
            <Space key="atc-btn-opposite">
              <Button
                className="atc-btn-opposite"
                onClick={() => {
                  form.validateFields().then((values) => {
                    setFilterMessage({ ...filterMessage, is_viewing: true });
                    form.resetFields();
                    setToggleViewAs(false);
                  });
                  // form
                  //   .validateFields()
                  //   .then((values) => {
                  //     onFinishForm(values);
                  //   })
                  //   .catch((info) => {
                  //     console.log("Validate Failed:", info);
                  //   });
                }}
                // loading={isLoadingNotification}
              >
                Submit
              </Button>
            </Space>,
          ]}
        >
          <Form form={form}>
            <Row>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="view_as"
                  className="message-view-as-select"
                  rules={[validateRules.required]}
                >
                  <FloatSelect
                    label="View as"
                    placeholder="View as"
                    onChange={(e, value) => {
                      setFilterMessage({
                        ...filterMessage,
                        view_as: value.label.toUpperCase(),
                        view_as_id: e,
                      });
                    }}
                    // optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   (option?.label ?? "").includes(input)
                    // }
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label ?? "")
                    //     .toLowerCase()
                    //     .localeCompare((optionB?.label ?? "").toLowerCase())
                    // }
                    options={userListOption}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <ModalNewGrouChat
          showModal={toggleNewGC}
          setShowModal={setToggleNewGC}
        />
        <ModalChangeGroupMessageName
          showModal={toggleRenameGC}
          setShowModal={setToggleRenameGC}
          group_message_id={selectedMessage ? selectedMessage.id : ""}
        />
      </Card>
    </div>
  );
}

export default PageMessages;
