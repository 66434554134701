import React, { useState } from "react";
import { Card, Collapse, Form, Input, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleDown,
  faAngleUp,
  faMinus,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import ComponentQuill from "../../Components/ComponenQuill";

export default function PageStages() {
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const [stage, setStages] = useState([]);
  const [stageDetails, setStageDetails] = useState([]);

  const stage_name = [
    "App Analysis",
    "Client Stage",
    "Set Appt.",
    "Development",
    "Publish",
    "Complete",
  ];

  GET("api/v1/user/opportunity", "opportunity", (res) => {
    if (res.success) {
      console.log(res.pipeline_stages);
      setStages(res.pipeline_stages);
    }
  });

  GET("api/v1/user/stages", "stages", (res) => {
    if (res.success) {
      setStageDetails(res.data);

      form.setFieldsValue({
        stages: res.data,
      });
    }
  });

  const {
    mutate: mutateStagesDetails,
    // isLoading: isLoadingEmailTemplate,
  } = POST("api/v1/user/stages/update", "stages");

  const save = (values) => {
    // console.log("onFinish", values);

    mutateStagesDetails(values, {
      onSuccess: (res) => {
        // console.log("mutateEmailTemplate", res);
        if (res.success) {
          notification.success({
            message: "Stage Description",
            description: "Successfully Updated",
          });
        } else {
          notification.error({
            message: "Stage Description",
            description: "Data not updated!",
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: "Stage Description",
          description: err.response.data.message,
        });
      },
    });
  };

  const handleBlurSave = (value, index, id) => {
    if (
      stageDetails[index]?.description?.replace(/\s/g, " ") !==
      value?.replace(/\s/g, " ")
    ) {
      let data = {
        id: id,
        value: value,
      };

      save(data);
    }
  };

  return (
    <Card className="card--padding-mobile">
      <Form form={form}>
        <ComponentQuill
          form={form}
          name={"stages"}
          dataSource={stageDetails}
          onBlur={handleBlurSave}
        />
      </Form>
    </Card>
  );
}
