import React, { useState, useEffect } from "react";
import {
  Layout,
  Card,
  Form,
  Button,
  Row,
  Col,
  Image,
  Typography,
  Alert,
} from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  apiUrl,
  logo,
  description,
  encrypt,
} from "../../../providers/companyInfo";
import { POST } from "../../../providers/useAxiosQuery";
// import FloatInputPassword from "../../../providers/FloatInputPassword";
import axios from "axios";
import validateRules from "../../../providers/validateRules";
import FloatInputPasswordStrength from "../../../providers/FloatInputPasswordStrength";
import { H } from "highlight.run";

export default function PageForgotPassword({ match }) {
  let history = useHistory();
  let user_id = match.params.id;
  let token = match.params.token;
  let newApiUrl = apiUrl + "api/v1/";
  let url = `newpassword/auth`;
  const [form] = Form.useForm();

  useEffect(() => {
    axios
      .post(
        `${newApiUrl}${url}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        console.log("success");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          history.push("/error-500");
        }
      });
  }, [newApiUrl, url, token, history]);

  const [errorMessageLogin, setErrorMessageLogin] = useState({
    type: "success",
    message: "",
  });

  const { mutate: mutateChangePass, isLoading: isLoadingChangePass } = POST(
    "api/v1/change_password",
    "change_password"
  );

  const onFinishLogin = (values) => {
    let data = {
      ...values,
      id: user_id,
    };

    // console.log(user_id);
    mutateChangePass(data, {
      onSuccess: (res) => {
        console.log("mutateChangePass", res);
        if (res.success) {
          if (res.data) {
            H.identify(res.data.firstname + " " + res.data.lastname, {
              id: res.data.id,
              email: res.data.email,
              username: res.data.username,
            });
            setErrorMessageLogin({
              type: "success",
              message: "Successfully updated",
            });
            localStorage.userdata = encrypt(res.data.authUser.data);
            localStorage.token = res.data.authUser.token;

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      },
      onError: (err) => {
        setErrorMessageLogin({
          type: "error",
          message: "Error",
        });
      },
    });
  };

  return (
    <Layout className="public-layout login-layout">
      <Layout.Content className="p-t-xl p-b-xl">
        <Row>
          <Col span={24}>
            <Image
              className="zoom-in-out-box"
              onClick={() => history.push("/")}
              src={logo}
              preview={false}
            />

            <Card
              className="m-t-md card--public-body custom-padding"
              // bodyStyle={{
              //   padding: "40px 50px",
              // }}
            >
              <Form
                layout="vertical"
                name="new-password-form"
                className="new-password-form"
                onFinish={onFinishLogin}
                form={form}
                autoComplete="off"
              >
                <Typography.Title
                  level={3}
                  className="text-left text-create-user-account m-b-md"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Create a New Password
                  <h6 style={{ fontWeight: 400 }}>
                    Your password must be at least 8 characters and contain at
                    least one number, one uppercase letter and one special
                    character.
                  </h6>
                </Typography.Title>
                <Form.Item
                  name="new_password"
                  rules={[validateRules.required, validateRules.password]}
                  hasFeedback
                  // className="m-b-sm"
                  className="change-password-input"
                >
                  <FloatInputPasswordStrength
                    label="Password"
                    placeholder="Password"
                    required
                  />
                </Form.Item>
                <Form.Item
                  name="new_password_confirm"
                  rules={[
                    validateRules.required,
                    validateRules.password,
                    validateRules.password_validate,
                  ]}
                  hasFeedback
                  className="change-password-input"
                  // className="m-b-sm"
                >
                  <FloatInputPasswordStrength
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    required
                  />
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="atc-btn m-t-xs"
                  block
                  size="large"
                  loading={isLoadingChangePass}
                >
                  Submit
                </Button>

                {errorMessageLogin.message && (
                  <Alert
                    className="m-t-sm"
                    type={errorMessageLogin.type}
                    message={errorMessageLogin.message}
                  />
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      </Layout.Content>

      <Layout.Footer className="text-center">
        <Typography.Text>
          © Copyright {moment().format("YYYY")} {description}. All Rights
          Reserved.
        </Typography.Text>
      </Layout.Footer>
    </Layout>
  );
}
