import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row, notification, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; //
import momentTimezonePlugin from "@fullcalendar/moment-timezone"; //
import agendaPlugin from "@fullcalendar/react";
import { POST, GET, GETMANUAL } from "../../../../providers/useAxiosQuery";
import moment from "moment";
import { useHistory } from "react-router-dom";
import tz from "moment-timezone";
import ModalEventBooked from "../../Components/ModalEventBooked";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalEventBookedWeekView from "../../Components/ModalEventBookedWeekView";
import { userData } from "../../../../providers/companyInfo";
import $ from "jquery";

function PageAppointments() {
  const [selectedDate, setSelectedDate] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [stage, setStages] = useState([]);
  const [appontmentStage, setAppointmentStage] = useState();
  const [openWeekBooking, setOpenWeekBooking] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState();
  const history = useHistory();
  const [dataCalendarDates, setDataCalendarDates] = useState(null);
  const [dataCalendar, setDataCalendar] = useState(null);

  const { mutate: addHistoryLog } = POST(
    "api/v1/historylogs/add",
    "add_history_logs"
  );

  const handlesChangeDate = (e) => {
    let strDt = e.startStr;
    let endDt = e.endStr;

    // let strDt = moment(e.startStr).add(8, "hours").valueOf();
    // let endDt = moment(e.endStr).add(17, "hours").valueOf();

    let data = {
      startDate: moment(strDt).format("L"),
      endDate: moment(endDt).format("L"),
      // timezone: "US/Mountain",
      timezone: "US/Mountain",
    };
    setDataCalendar(e);
    setDataCalendarDates(data);

    let bookedAppointments = [];
    let events = [];
    mutateGetAppointments(data, {
      onSuccess: (res) => {
        if (res.success) {
          console.log("mutateGetAppointments", res);
          if (e.view.type == "timeGridWeek" || e.view.type == "timeGridDay") {
            let slots_array = [];

            res.data.forEach((data) => {
              data.extendedProps.slots.map((slot) => {
                slot.title = data.title;
              });
              slots_array.push(...data.extendedProps.slots);
            });

            slots_array.map((slot) => {
              slot.start = moment(slot.date + " " + slot.time_start).format(
                "YYYY-MM-DD HH:MM"
              );
              slot.end = moment(slot.date + " " + slot.time_end).format(
                "YYYY-MM-DD HH:MM"
              );
              slot.date = slot.date;
            });

            setAppointments(slots_array);
          } else {
            setAppointments(res.data);
          }
        }
      },
    });
  };

  const { mutate: mutateGetAppointments, isLoading: isLoadingSlot } = POST(
    "api/v1/calendar/consultant/availability",
    "appointments",
    false
  );

  GET("api/v1/user/opportunity", "opportunity", (res) => {
    if (res.success) {
      setStages(res.pipeline_stages);
      let appStage = res.pipeline_stages_appointment.find(
        (x) => x.status === "process"
      );

      setAppointmentStage(appStage?.name.toLowerCase());
    }
  });

  const handleEventClick = (event_data) => {
    let booking_data = [];

    let events = event_data.event.extendedProps;
    booking_data.push({
      date: moment(event_data.event.start).format("dddd, MMMM Do"),
      title: event_data.event.title,
      alter_date: moment(event_data.event.start).format("YYYY-MM-DD"),
      slots: events,
    });

    if (localStorage.getItem("userdata_admin")) {
      setAppointmentDetails(booking_data);
      setToggleBookedModal(true);
    } else {
      if (
        moment(event_data.event.start).format("YYYY-MM-DD") >=
        moment().tz("MST").format("YYYY-MM-DD")
        // moment(date1).unix() >= moment(date2).unix()
      ) {
        if (
          moment(event_data.event.start).format("YYYY-MM-DD") ===
          moment().tz("MST").format("YYYY-MM-DD")
          // &&
          // !localStorage.getItem("userdata_admin")
        ) {
          notification.warning({
            message: `Booking not allowed`,
            description: "You can't book current or previous dates.",
            placement: "topRight",
          });
        } else {
          setAppointmentDetails(booking_data);
          setToggleBookedModal(true);
        }
      } else {
        notification.warning({
          message: `Booking not allowed`,
          description: "You can't book current or previous dates.",
          placement: "topRight",
        });
      }
    }
  };

  const [toggleBookedModal, setToggleBookedModal] = useState(false);
  // useEffect(() => {
  //   if (appointmentDetails.length > 0) {
  //     setToggleBookedModal(true);
  //   }
  // }, [appointmentDetails, toggleBookedModal]);

  // const handleDateClick = (e) => {
  //   if (stage.length > 0) {
  //     if (stage[2].status != "wait") {
  //       let row = e.dateStr;
  //       if (moment(row).format("L") < moment().format("L")) {
  //       } else if (moment(row).format("dddd") === "Sunday") {
  //         notification.warning({
  //           message: `Booking not allowed`,
  //           description: "You can't book on weekends.",
  //           placement: "topRight",
  //         });
  //       }
  //     } else {
  //       notification.warning({
  //         message: `Booking not allowed`,
  //         description: "You are not yet on set appointment stage",
  //         placement: "topRight",
  //       });
  //     }
  //   }
  // };

  const calendarRef = useRef(null);
  const fCalendar = useRef(null);
  const [calendarWidth, setCalendarWidth] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dayMaxEvent, setDayMaxEvent] = useState(4);

  const disabledButton = (timeStart, timeEnd) => {
    // let sTime = moment(timeStart, "HH:mm:ss");
    // let eTime = moment(timeEnd, "HH:mm:ss");
    // let duration = moment.duration(eTime.diff(sTime));

    if (timeStart && timeEnd) {
      let sTime = moment(timeStart, "HHmm").format("HHmm");
      let eTime = moment(timeEnd, "HHmm").format("HHmm");
      let duration = Number(eTime) - Number(sTime);
      // console.log("duration", duration, sTime, eTime);
      if (appontmentStage) {
        if (
          // appontmentStage === "timeline" ||
          appontmentStage === "pre-publish" ||
          appontmentStage === "one hour update" ||
          appontmentStage === "pre-interview"
        ) {
          if (duration <= 100) {
            return false;
          }
        } else {
          if (duration <= 100) {
            return false;
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    const handleResize = () => {
      if (calendarRef?.current) {
        setCalendarWidth(calendarRef.current.offsetWidth);
      }
    };

    if (calendarRef?.current) {
      const calendarApi = fCalendar?.current?.getApi();
      if (windowSize.width <= 662) {
        calendarApi?.changeView("timeGridDay");
      } else if (windowSize.width > 662 && windowSize.width <= 768) {
        calendarApi?.changeView("timeGridWeek");
      } else {
        calendarApi?.changeView("dayGridMonth");
      }

      const resizeObserver = new ResizeObserver(handleResize);
      if (calendarRef.current) {
        resizeObserver.observe(calendarRef.current);
      }
    }
  }, [windowSize.width]);

  const [viewOnRefresh, setViewOnRefresh] = useState("dayGridMonth");

  return (
    <Card className="card--padding-mobile">
      <Row gutter={8}>
        <Col
          xs={24}
          sm={24}
          md={24}
          // className="admin-calendar calendar-book consultant-bookings-calendar client-calendar-booking"
          className="admin-calendar calendar-book client-calendar-booking consultant-calendar-bookings"
          ref={calendarRef}
        >
          <FullCalendar
            key={`c-app-${calendarWidth}`}
            timeZone="MST"
            ref={fCalendar}
            initialView={viewOnRefresh ? viewOnRefresh : "dayGridMonth"}
            allDaySlot={false}
            height="auto"
            eventMaxStack={dayMaxEvent}
            dayMaxEvents={dayMaxEvent}
            slotEventOverlap={false}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            viewHint={(buttonText, buttonName) => {
              if (buttonName.match(/^dayGridMonth/)) {
                return "Month View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridWeek/)) {
                return "Week View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridDay/)) {
                return "Day View"; // results in "week list view"
              }
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentTimezonePlugin,
            ]}
            events={appointments}
            displayEventTime={false}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: true,
            }}
            eventClick={(e) => {
              if (
                e.view.type == "timeGridWeek" ||
                e.view.type == "timeGridDay"
              ) {
                if (localStorage.getItem("userdata_admin")) {
                  // if (
                  //   disabledButton(
                  //     e?.event?.extendedProps?.time_start,
                  //     e?.event?.extendedProps?.time_end
                  //   )
                  // ) {
                  //   notification.warning({
                  //     message: `Booking not allowed`,
                  //     description: "Invalid time slot for this stage.",
                  //     placement: "topRight",
                  //   });
                  // } else {
                  setOpenWeekBooking(true);
                  setSelectedSlot(e.event);
                  // }
                } else {
                  let schedule_date = Number(
                    moment(e.event.extendedProps.schedule_date).format("YMMDD")
                  );
                  let current_date = Number(moment().tz("MST").format("YMMDD"));
                  if (
                    schedule_date > current_date
                    // &&
                    // disabledButton(
                    //   e?.event?.extendedProps?.time_start,
                    //   e?.event?.extendedProps?.time_end
                    // ) === false
                  ) {
                    setOpenWeekBooking(true);
                    setSelectedSlot(e.event);
                  } else {
                    // if (
                    //   disabledButton(
                    //     e?.event?.extendedProps?.time_start,
                    //     e?.event?.extendedProps?.time_end
                    //   )
                    // ) {
                    //   notification.warning({
                    //     message: `Booking not allowed`,
                    //     description: "Invalid time slot for this stage.",
                    //     placement: "topRight",
                    //   });
                    // } else {
                    notification.warning({
                      message: `Booking not allowed`,
                      description: "You can't book current or previous dates.",
                      placement: "topRight",
                    });
                    // }
                  }
                }
              } else {
                handleEventClick(e);
              }
            }}
            // dateClick={(e) => handleDateClick(e)}
            datesSet={(e) => {
              setViewOnRefresh(e.view.type);
              handlesChangeDate(e);
              if (windowSize.width <= 662) {
                setDayMaxEvent(0);
              } else if (windowSize.width > 662 && windowSize.width <= 991) {
                if (e?.view?.type === "dayGridMonth") {
                  setDayMaxEvent(4);
                } else {
                  setDayMaxEvent(0);
                }
              } else {
                setDayMaxEvent(4);
              }
            }}
          />
        </Col>
      </Row>

      <ModalEventBooked
        toggleModal={toggleBookedModal}
        setToggleModal={setToggleBookedModal}
        //   setSelectedDate={setSelectedDate}
        appStage={appontmentStage}
        details={appointmentDetails}
      />

      <ModalEventBookedWeekView
        open={openWeekBooking}
        setOpen={setOpenWeekBooking}
        appStage={appontmentStage}
        details={selectedSlot}
      />
    </Card>
  );
}

export default PageAppointments;
