import React from "react";
import { Col, Form, Row } from "antd";
import FloatTextArea from "../../../../../providers/FloatTextArea";

export default function PageCheckoutComponentAdditionalInformation(props) {
  const {} = props;
  return (
    <>
      <Row
        gutter={[12, 0]}
        className="personal-info-1"
        style={{ paddingTop: "8px" }}
      >
        <Col xs={24} sm={24} md={24}>
          <Form.Item name="order_notes">
            <FloatTextArea
              label="Order notes (Optional)"
              placeholder="Order notes (Optional)"
              maxLength={160}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
