import React from "react";
import { Menu } from "antd";

export const menuLeft = <></>;

export const dropDownMenuLeft = () => {
  const items = [];

  return <Menu items={items} />;
};
