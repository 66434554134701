import {
  faCalendar,
  faShoppingCart,
  faUpload,
  faFileArrowUp,
  faPen,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Card, Collapse, Empty, Typography } from "antd";

export default function TaskCardEmpty(props) {
  const { title } = props;

  return (
    <Collapse
      bordered
      key={"item"}
      className={"border-none unique-collapse"}
      defaultActiveKey={["empty"]}
      expandIconPosition="start"
    >
      <Collapse.Panel
        key={"empty"}
        collapsible={"header"}
        showArrow={false}
        header={
          <div className="task-flex" style={{ marginLeft: 5 }}>
            <Typography.Text
              className="task-collapse-title"
              ellipsis={{ rows: 2 }}
            >
              No {title} Task
            </Typography.Text>
            {/* {item.isActive && (
              <div
                className="task-card-title"
                style={{
                  padding: " 5px",
                  maxHeight: "40px",

                  background: item.isCompleted ? "#43a429" : "#ff2020",
                }}
              >
                {item.isCompleted ? "Completed" : "Pending"}
              </div>
            )} */}
          </div>
        }
        // className={item.isActive && !item.isCompleted ? "accordion border white collapse-content-pending" : item.isCompleted && item.isActive ? "accordion border white collapse-content-complete" : "accordion border collapse-content-inactive"}
      >
        <div
          className="card-btn-cont"
          style={{
            display: "flex",
            flexFlow: "column",
            justifyContent: "space-between",
            minHeight: "200px",
            alignContent: "space-around",
            flexWrap: "wrap !important",
          }}
        >
          <div>
            <Card
              bordered={false}
              style={{ minHeight: "100px" }}
              className="task-card-content"
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                // description="No Task"
                className="m-t-xxxl"
              />
            </Card>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
