import React from "react";
import {
  Route,
  Switch,
  // Route
} from "react-router-dom";
import {
  faChartPie,
  faChartMixed,
  faClockRotateLeft,
} from "@fortawesome/pro-light-svg-icons";
import {
  faHome,
  faUser,
  faUserPlus,
  faBell,
  faEnvelopesBulk,
  faCalendarTime,
  faCalendarTimes,
  faCalendar,
  faChartNetwork,
  faCalendarDays,
  faTableColumns,
  faUsers,
  faSms,
  faCommentDots,
  faShoppingCart,
  faTag,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faLaptopMedical,
  faPlay,
  faUserEdit,
  faFileLines,
  faFileCertificate,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";

/** template */
import PrivateRoute from "../PrivateRoute";

import Error404 from "../../views/errors/Error404";
import Error500 from "../../views/errors/Error500";

import PageDashboard from "../../views/private/PageAdmin/PageDashboard/PageDashboard";
import PageUser from "../../views/private/PageAdmin/PageUser/PageUser";
import PageUserForm from "../../views/private/PageAdmin/PageUser/PageUserForm";
import PageConsultantRegister from "../../views/private/PageAdmin/PageConsultant/PageConsultantRegister";
import PageEmailTemplates from "../../views/private/PageAdmin/PageEmailTemplate/PageEmailTemplates";
import PageConsultant from "../../views/private/PageAdmin/PageConsultant/PageConsultant";
import PageStages from "../../views/private/PageAdmin/PageStages/PageStages";
import PageConsultantSchedules from "../../views/private/PageAdmin/PageConsultant/PageConsultantSchedules";
import Page2FA from "../../views/private/Page2fa/Page2fa";
import PageProfile from "../../views/private/PageUser/PageProfile/PageProfile";
import PageNotification from "../../views/private/PageNotification/PageNotification";
import PageCancelledAppointments from "../../views/private/PageAdmin/PageCancelledAppointments/PageCancelledAppointments";
import PageCalendar from "../../views/private/PageAdmin/PageCalendar/PageCalendar";
import PageHistoryLog from "../../views/private/PageAdmin/PageHistoryLog/PageHistoryLog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageCalendarTest from "../../views/private/PageAdmin/PageCalendarTest/PageCalendarTest";
import PageSmsTemplate from "../../views/private/PageAdmin/PageSmsTemplate/PageSmsTemplate";
import PageMessages from "../../views/private/PageAdmin/PageMessages/PageMessages";
import PageOrder from "../../views/private/PageAdmin/PageOrder/PageOrder";
import PageCoupon from "../../views/private/PageAdmin/PageCoupon/PageCoupon";

export default function RouteAdmin() {
  // console.log("RouteAdmin");
  return (
    <Switch>
      {/* Dashboard */}
      <PrivateRoute
        exact
        page="Dashboard"
        path="/dashboard"
        title="Dashboard"
        subtitle="ADMIN"
        component={PageDashboard}
        pageHeaderIcon={faChartNetwork}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      <PrivateRoute
        exact
        page="Dashboard"
        path="/dashboard?code=:token"
        title="Dashboard"
        subtitle="ADMIN"
        component={PageDashboard}
        pageHeaderIcon={faHome}
        breadcrumb={[
          {
            name: "Dashboard",
            link: "/dashboard",
          },
        ]}
      />

      {/* Clients */}
      <PrivateRoute
        exact
        page="Clients"
        path="/clients"
        title="Clients"
        subtitle="VIEW"
        component={PageUser}
        pageHeaderIcon={faUser}
        breadcrumb={[
          {
            name: "Clients",
            link: "/clients",
          },
        ]}
      />

      <PrivateRoute
        exact
        page="Clients"
        path="/user/manageuser/:id"
        title="Manage User"
        subtitle="CLIENT"
        component={PageUserForm}
        pageHeaderIcon={faUserPlus}
        breadcrumb={[
          {
            name: "Clients",
            link: "/clients",
          },
          {
            name: "Client Details",
            link: "#Client Details",
          },
        ]}
      />

      {/* Consultant */}
      <PrivateRoute
        exact
        page="Consultants"
        path="/consultants"
        title="Consultants"
        subtitle="MANAGE"
        component={PageConsultant}
        pageHeaderIcon={faUsers}
        breadcrumb={[
          {
            name: "Consultants",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        page="Consultants"
        path="/consultant/schedule/:id"
        title="Schedule"
        subtitle="CONSULTANT"
        component={PageConsultantSchedules}
        pageHeaderIcon={faTableColumns}
        breadcrumb={[
          {
            name: "Consultants",
            link: "/consultants",
          },
          {
            name: "Consultant Schedule",
            link: "#",
          },
        ]}
      />

      <PrivateRoute
        exact
        page="Consultants"
        path="/consultant/register"
        title="Consultant"
        subtitle="ADD NEW"
        component={PageConsultantRegister}
        pageHeaderIcon={faUsers}
        breadcrumb={[
          {
            name: "Consultants",
            link: "/consultants",
          },
          {
            name: "Add Consultant",
            link: "/consultant/register",
          },
        ]}
      />

      {/* Calendar */}
      <PrivateRoute
        exact
        page="Calendar"
        path="/calendar"
        title="Calendar"
        subtitle="ADMIN"
        component={PageCalendar}
        pageHeaderIcon={faCalendarDays}
        breadcrumb={[
          {
            name: "Admin Calendar",
            link: "#",
          },
        ]}
      />

      {/* Coupons */}
      <PrivateRoute
        exact
        page="Coupons"
        path="/coupons"
        title="Coupons"
        subtitle="View"
        component={PageCoupon}
        pageHeaderIcon={faTag}
        breadcrumb={[
          {
            name: "Coupons",
            link: "#",
          },
        ]}
      />

      {/* Orders */}
      <PrivateRoute
        exact
        page="Orders"
        path="/orders"
        title="Orders"
        subtitle="View"
        component={PageOrder}
        pageHeaderIcon={faShoppingCart}
        breadcrumb={[
          {
            name: "Orders",
            link: "#",
          },
        ]}
      />

      {/* Email Templates */}
      <PrivateRoute
        exact
        page="Email Templates"
        path="/emailtemplate"
        title="Templates"
        subtitle="EMAIL"
        component={PageEmailTemplates}
        pageHeaderIcon={faEnvelopesBulk}
        breadcrumb={[
          {
            name: "Email Templates",
            link: "#",
          },
        ]}
      />

      {/* SMS Templates */}
      <PrivateRoute
        exact
        page="SMS Templates"
        path="/sms-template"
        title="Templates"
        subtitle="SMS"
        component={PageSmsTemplate}
        pageHeaderIcon={faSms}
        breadcrumb={[
          {
            name: "SMS Templates",
            link: "#",
          },
        ]}
      />

      {/* Stages */}
      <PrivateRoute
        exact
        page="Stages"
        path="/stages"
        title="Stages"
        subtitle="MANAGE DASHBOARD"
        component={PageStages}
        pageHeaderIcon={faTableColumns}
        breadcrumb={[
          {
            name: "Stages",
            link: "#",
          },
        ]}
      />

      {/* Messages */}
      <PrivateRoute
        exact
        page="Messages"
        subtitle="View"
        title="Messages"
        path="/messages"
        pageHeaderIcon={faCommentDots}
        component={PageMessages}
        breadcrumb={[
          {
            name: "Messages",
            link: "#",
          },
        ]}
      />

      {/* Nofication */}
      <PrivateRoute
        exact
        page="Notifications"
        path="/notification"
        title="Notifications"
        subtitle="VIEW"
        component={PageNotification}
        pageHeaderIcon={faBell}
        breadcrumb={[
          {
            name: "Notification",
            link: "#",
          },
        ]}
      />

      {/* Cancelled Appointments */}
      <PrivateRoute
        exact
        page="Cancelled Appointments"
        path="/cancelled-appointments"
        title="Appointments"
        subtitle="CANCELLED"
        component={PageCancelledAppointments}
        pageHeaderIcon={faCalendarTimes}
        breadcrumb={[
          {
            name: "Cancelled Appointments",
            link: "#",
          },
        ]}
      />

      {/* History Log */}
      <PrivateRoute
        exact
        page="History Log"
        path="/history/all"
        title="History"
        subtitle="ALL"
        component={PageHistoryLog}
        pageHeaderIcon={faClockRotateLeft}
        breadcrumb={[
          {
            name: "History Log",
            link: "#",
          },
        ]}
      />

      {/* Profile */}
      <PrivateRoute
        exact
        page="Profile"
        title="Profile"
        path="/profile/account"
        subtitle="EDIT"
        component={PageProfile}
        pageHeaderIcon={faUser}
        breadcrumb={[
          {
            name: "Profile",
            link: "/profile/account",
          },
        ]}
      />

      <PrivateRoute
        exact
        page="Profile"
        path="/2fa"
        title="2 Factor Authentication"
        subtitle="ACCOUNT"
        component={Page2FA}
        pageHeaderIcon={faLock}
        breadcrumb={[
          {
            name: "2 Factor Authentication",
            link: "/",
          },
        ]}
      />
      {/* this should always in the bottom */}

      <Route exact path="/*" component={Error404} />
      <Route exact path="/500" component={Error500} />
    </Switch>
  );
}
