import {
  Card,
  Row,
  Col,
  Button,
  Form,
  notification,
  Modal,
  Space,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST, POSTLOAD } from "../../../../providers/useAxiosQuery";
import { userData } from "../../../../providers/companyInfo";
import ReactSignatureCanvas from "react-signature-canvas";
import FloatInput from "../../../../providers/FloatInput";
import { useHistory } from "react-router-dom";
import validateRules from "../../../../providers/validateRules";
import optionCountryCodes from "../../../../providers/optionCountryCodes";
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import FloatSelect from "../../../../providers/FloatSelect";
import $ from "jquery";

// library link
// https://github.com/ZEISS/react-view-pdf
import { PDFViewer } from "react-view-pdf";

function PageDocusign(props) {
  const {} = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const [toggleModal, setToggleModal] = useState(false);

  let refSignature;

  const { mutate: mutateSaveSignature } = POSTLOAD(
    "api/v1/client/signature",
    "save_client_signature"
    // false
  );
  const { mutate: mutateSubmitDocusign } = POSTLOAD(
    "api/v1/client/submit_mda",
    "get_user"
    // false
  );

  const [iframeLink, setIframeLink] = useState();
  const [downloadLink, setDownloadLink] = useState();
  const [signature, setSignature] = useState(null);
  const [hasAgreed, setHasAgreed] = useState(false);

  const handleSaveSignature = (data) => {
    mutateSaveSignature(data, {
      onSuccess: (res) => {
        setIframeLink(res.link);
        setDownloadLink(res.downloadLink);
        setSignature(data.signature);
        setToggleModal(false);
      },
    });
  };

  GET(`api/v1/users/${userData().id}`, "get_user", (res) => {
    if (res.success) {
      let user = res.data;

      form.setFieldsValue({
        firstname: user.firstname,
        lastname: user.lastname,
        address: user.mda_address
          ? user.mda_address
          : user.user_address.address1,
        city: user.mda_address ? user.mda_city : user.user_address.city,
        state: user.mda_address ? user.mda_state : user.user_address.state,
        zip_code: user.mda_address ? user.mda_zip : user.user_address.zip_code,
        country: user.mda_address
          ? user.mda_country
          : user.user_address.country
          ? user.user_address.country
          : "United States",
      });
      setCountryValue(
        user.mda_address
          ? user.mda_country
          : user.user_address.country
          ? user.user_address.country
          : "United States"
      );
      onChangeCountry(
        user.mda_address
          ? user.mda_country
          : user.user_address.country
          ? user.user_address.country
          : "United States"
      );

      // console.log("setSignature", res.data.signature);

      if (res.data.signature) {
        setSignature(res.data.signature);

        let firstname = user.mnda_firstname
          ? user.mnda_firstname
          : user.firstname;
        let lastname = user.mnda_lastname ? user.mnda_lastname : user.lastname;

        let data = {
          signature: res.data.signature,
          firstname: firstname,
          lastname: lastname,
          address: user.mda_address
            ? user.mda_address
            : user.user_address.address1,
          city: user.mda_address ? user.mda_city : user.user_address.city,
          state: user.mda_address ? user.mda_state : user.user_address.state,
          zip_code: user.mda_address
            ? user.mda_zip
            : user.user_address.zip_code,
        };

        if (user.has_agreed_mnda) {
          setHasAgreed(true);
        }

        handleSaveSignature(data);
        setToggleModal(false);

        // $(".globalLoading").removeClass("hide");
      } else {
        setToggleModal(true);
      }
    }
  });

  useEffect(() => {
    if (!userData().has_mnda) {
      history.push("/");
    }
  }, [userData]);

  useEffect(() => {
    if (signature) {
      if (toggleModal) {
        refSignature.clear();
        refSignature.fromDataURL(signature);
      }
    }
  }, []);

  const handleSubmitDocusign = () => {
    let data = {
      link: downloadLink,
      user_id: userData().id,
    };

    mutateSubmitDocusign(data, {
      onSuccess: (res) => {
        notification.success({ message: "Success", description: res.message });
        history.push("/dashboard");
      },
      onError: (res) => {},
    });
  };

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [optionState, setOptionState] = useState([]);
  const [optionZip, setOptionZip] = useState();
  const [countryValue, setCountryValue] = useState("");

  const onChangeCountry = (val, load = false) => {
    setCountryValue(val);
    if (val === "United States") {
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else if (val === "Puerto Rico") {
      setOptionState(stateCA);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }

    // if (!load) {
    //   form.resetFields(["state", "zip", "city"]);
    // }
  };

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    $(".globalLoading").addClass("hide");
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const [pageCount, setPageCount] = useState(0);

  return (
    <Card
      className="upload-card card--padding-mobile"
      bodyStyle={{ paddingTop: 12 }}
    >
      {iframeLink && (
        <>
          <Row>
            <Col>
              {!hasAgreed ? (
                <Button
                  type="text"
                  size="large"
                  onClick={() => {
                    handleSubmitDocusign();
                    setHasAgreed(true);
                  }}
                  className="btn-dashboard-task btn-task-primary btn-mda-download"
                >
                  Agree and Submit
                </Button>
              ) : (
                <Space wrap>
                  <Button
                    type="text"
                    onClick={() => {
                      window.open(downloadLink);
                    }}
                    size="large"
                    className="btn-dashboard-task btn-task-primary btn-mda-download"
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ marginRight: "5px" }}
                    />
                    Download
                  </Button>
                  {/* <Button
                    type="text"
                    onClick={() => {
                      history.push("/dashboard");
                    }}
                    size="large"
                    className="btn-dashboard-task btn-task-primary btn-mda-download"
                  >
                    <FontAwesomeIcon
                      icon={faBackward}
                      style={{ marginRight: "5px" }}
                    />
                    Return to Dashboard
                  </Button> */}
                </Space>
              )}
            </Col>
          </Row>

          <Row className="m-t-md pdf-viewer-custom-row">
            {/* <iframe
              className="iframeStyle"
              src={iframeLink && iframeLink}
              onLoad={() => {
                $(".globalLoading").addClass("hide");
              }}
            /> */}

            <Col span={24} className="pdf-viewer-custom-col">
              <PDFViewer
                url={iframeLink}
                className="pdf-viewer"
                // onLoadSuccess={(pdf) => {
                //   // setPageCount(pdf.numPages);
                //   $(".globalLoading").addClass("hide");
                // }}
              ></PDFViewer>
            </Col>

            {!hasAgreed && (
              <div style={{ width: "100%" }}>
                <Button
                  style={{
                    left: "55%",
                    transform: "translate(-65%, 0px)",
                    position: "fixed",
                    bottom: "20px",
                    zIndex: 1,
                  }}
                  className="atc-btn"
                  onClick={() => {
                    handleSubmitDocusign();
                    setHasAgreed(true);
                  }}
                  size="large"
                >
                  Agree and Submit
                </Button>
              </div>
            )}
          </Row>
        </>
      )}

      <Modal
        title="MUTUAL CONFIDENTIALITY AGREEMENT"
        open={toggleModal}
        width={500}
        bodyStyle={{ minHeight: 500 }}
        footer={null}
        onCancel={() => {
          setToggleModal(false);
        }}
        className="modal-primary "
      >
        <div style={{ marginBottom: 20 }}>
          Completing these fields and adding your signature simply means your
          information will pre-populate into the appropriate fields, however
          this does NOT mean you are pre-signing and agreeing to the terms of
          the document. Once you have competed reviewing this document you will
          have the option to agree and submit or to decline to this document.
        </div>
        <Form form={form}>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24}>
              <Form.Item name="firstname" rules={[validateRules.required]}>
                <FloatInput
                  label="First Name"
                  placeholder="First Name"
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item name="lastname" rules={[validateRules.required]}>
                <FloatInput
                  label="Last Name"
                  placeholder="Last Name"
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="country"
                className="form-select-style"
                rules={[validateRules.required]}
              >
                <FloatSelect
                  label="Country"
                  placeholder="Country"
                  options={country}
                  onChange={(val, opt) => {
                    onChangeCountry(val);
                  }}
                  required
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item name="address" rules={[validateRules.required]}>
                <FloatInput label="Address" placeholder="Address" required />
              </Form.Item>
            </Col>

            <Col xs={8} sm={8} md={8}>
              <Form.Item name="city" rules={[validateRules.required]}>
                <FloatInput label="City" placeholder="City" required />
              </Form.Item>
            </Col>
            <Col xs={8} sm={8} md={8}>
              {countryValue !== "Puerto Rico" ? (
                <Form.Item
                  name="state"
                  rules={[validateRules.required]}
                  className="form-select-style"
                >
                  <FloatSelect
                    label={"State / County"}
                    placeholder={"State / County"}
                    options={optionState}
                    required
                  />
                </Form.Item>
              ) : (
                <Form.Item name="state" rules={[validateRules.required]}>
                  <FloatInput
                    label={"State / County"}
                    placeholder={"State / County"}
                    required
                  />
                </Form.Item>
              )}
            </Col>

            <Col xs={8} sm={8} md={8}>
              {countryValue !== "Puerto Rico" ? (
                <Form.Item
                  name="zip_code"
                  rules={[
                    validateRules.required,
                    {
                      pattern: optionZip,
                      message: "Invalid Post Code / ZIP Code!",
                    },
                  ]}
                >
                  <FloatInput
                    label="Post Code / ZIP Code"
                    placeholder="Post Code / ZIP Code"
                    required
                  />
                </Form.Item>
              ) : (
                <Form.Item name="zip_code" rules={[validateRules.required]}>
                  <FloatInput
                    label={"Post Code / ZIP Code"}
                    placeholder={"Post Code / ZIP Code"}
                    required
                  />
                </Form.Item>
              )}
            </Col>

            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="signature"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!refSignature.isEmpty() || value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Signature is required!")
                      );
                    },
                  }),
                ]}
              >
                <div
                  style={{
                    height: "180px",
                    width: "100%",
                    border: "1px solid black",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      fontSize: "10px",
                    }}
                  >
                    Signature
                  </div>
                  <div
                    style={{
                      right: "0px",
                      bottom: "0px",
                      fontSize: "10px",
                      position: "absolute",
                      color: " #3f5fac",
                    }}
                  >
                    <Button
                      type="text"
                      onClick={() => {
                        refSignature.clear();
                        setSignature(null);
                        form.setFields([
                          {
                            name: "signature",
                            errors: [],
                          },
                        ]);
                      }}
                    >
                      CLEAR
                    </Button>
                  </div>
                  <ReactSignatureCanvas
                    penColor="black"
                    defaultValue={signature && signature}
                    ref={(ref) => (refSignature = ref)}
                    canvasProps={{
                      width: "450px",
                      height: "160px",
                    }}
                    onEnd={() => {
                      setSignature(refSignature.toDataURL());
                      form.setFields([
                        {
                          name: "signature",
                          errors: [],
                        },
                      ]);
                    }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={24}>
              <Button
                style={{ float: "right " }}
                className="ant-btn ant-btn-default atc-btn-opposite ant-btn-preview-docs"
                size="large"
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      handleSaveSignature({
                        signature: refSignature.toDataURL(),
                        firstname: values.firstname,
                        lastname: values.lastname,
                        address: values.address,
                        city: values.city,
                        state: values.state,
                        zip_code: values.zip_code,
                      });
                      setSignature(refSignature.toDataURL());
                    })
                    .catch((info) => {
                      console.log("Validate Failed:", info);
                    });
                }}
              >
                Preview Document
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
}

export default PageDocusign;
