import React from "react";
import { Checkbox, Col, Form, Row, Typography } from "antd";
import FloatInput from "../../../../../providers/FloatInput";
import FloatSelect from "../../../../../providers/FloatSelect";
import FloatInputPhone from "../../../../../providers/FloatInputPhone";
import validateRules from "../../../../../providers/validateRules";

export default function PageCheckoutComponentBillingDetails(props) {
  const {
    country,
    onChangeCountry,
    optionState,
    optionBillingZip,
    countryValue,
    onChangeState,
    disabledEmail = true,
    viewPublic = false,
    checkAccount,
  } = props;

  return (
    <Row
      gutter={[12, 0]}
      className="personal-info-1"
      style={{ paddingTop: "8px" }}
    >
      <Col xs={24} sm={24} md={12}>
        <Form.Item name="first_name" rules={[validateRules?.required]}>
          <FloatInput label="First Name" placeholder="First Name" required />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={12}>
        <Form.Item name="last_name" rules={[validateRules?.required]}>
          <FloatInput label="Last Name" placeholder="Last Name" required />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item name="company">
          <FloatInput
            label="Company Name (Optional)"
            placeholder="Company Name (Optional)"
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item
          name="country"
          rules={[validateRules?.required]}
          className="form-select-style"
        >
          <FloatSelect
            label={"Country"}
            placeholder={"Country"}
            options={country}
            onChange={(val, opt) => onChangeCountry(val, opt)}
            required
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item name="address_1" rules={[validateRules?.required]}>
          <FloatInput
            label="House Number and Street Name"
            placeholder="House Number and Street Name"
            required
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item name="address_2">
          <FloatInput
            label="Apartment, Suite, Unit etc. (Optional)"
            placeholder="Apartment, Suite, Unit etc. (Optional)"
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item name="city" rules={[validateRules.required]}>
          <FloatInput label="Town / City" placeholder="Town / City" required />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        {countryValue !== "Puerto Rico" ? (
          <Form.Item
            name="state"
            rules={[validateRules.required]}
            className="form-select-style"
          >
            <FloatSelect
              label={"State / County"}
              placeholder={"State / County"}
              options={optionState}
              onChange={(val, opt) => onChangeState(val, opt)}
              required
            />
          </Form.Item>
        ) : (
          <Form.Item name="state" rules={[validateRules.required]}>
            <FloatInput
              label={"State / County"}
              placeholder={"State / County"}
              required
            />
          </Form.Item>
        )}
      </Col>

      <Col xs={24} sm={24} md={24}>
        {countryValue !== "Puerto Rico" ? (
          <Form.Item
            name="zip_code"
            rules={[
              validateRules.required,
              {
                pattern: optionBillingZip,
                message: "Invalid Post Code / ZIP Code!",
              },
            ]}
          >
            <FloatInput
              label={"Post Code / ZIP Code"}
              placeholder={"Post Code / ZIP Code"}
              required
            />
          </Form.Item>
        ) : (
          <Form.Item name="zip_code" rules={[validateRules.required]}>
            <FloatInput
              label={"Post Code / ZIP Code"}
              placeholder={"Post Code / ZIP Code"}
              required
            />
          </Form.Item>
        )}
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item
          name="phone"
          rules={[validateRules.required, validateRules.international_phone]}
        >
          <FloatInputPhone
            international={true}
            label="Phone"
            placeholder="Phone"
            onBlurInput={(e) => {
              if (viewPublic) {
                checkAccount(e.target.value, "phone");
              }
            }}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={24}>
        <Form.Item name="email" rules={[validateRules.required]} hasFeedback>
          <FloatInput
            label="Email Address"
            placeholder="Email Address"
            required
            readOnly={disabledEmail}
            onBlurInput={(e) => {
              if (viewPublic) {
                checkAccount(e, "email");
              }
            }}
          />
        </Form.Item>
      </Col>

      {/* <Col xs={24} sm={24} md={24} rules={[validateRules.required]}>
        <Form.Item name="email_enrollment" valuePropName="checked">
          <Checkbox
            onChange={(e) => {
              console.log(e.target.checked);
            }}
          >
            <Typography.Text strong>
              Check this box to get your enrollment email after check out.
            </Typography.Text>
          </Checkbox>
        </Form.Item>
      </Col> */}
    </Row>
  );
}
