import React, { useState, useEffect } from "react";

import {
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Modal,
  Typography,
  Popconfirm,
  Form,
  TimePicker,
  Tooltip,
  notification,
  Space,
} from "antd";
import validateRules from "../../../../../providers/validateRules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowDown,
  faCheckCircle,
  faPencil,
  faTrash,
  faXmarkCircle,
} from "@fortawesome/pro-regular-svg-icons";

import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import FloatTimePicker from "../../../../../providers/FloatTimePicker";
import moment from "moment";
import { GET, GETMANUAL, POST } from "../../../../../providers/useAxiosQuery";

export default function ModalAvailability(props) {
  const { showModal, setModalVisibility, data, setData, getEvents } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();

  const format = "HH:mma";

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        timeStart: moment(data.time_start, "H:mm"),
        timeEnd: moment(data.time_end, "H:mm"),
      });
    }
  }, [isEdit]);

  const onFinish = (values) => {
    let newdata = {
      timeStart: moment(values.timeStart).format("H:mm"),
      timeEnd: moment(values.timeEnd).format("H:mm"),
      id: data.id,
    };

    mutateEditSlot(newdata, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: res.message,
          });
          getEvents();
          setIsEdit(false);
          setModalVisibility(false);
        }
      },
      onError: (res) => {
        notification.warning({
          message: "Update Error",
          description: res.message,
        });
      },
    });
  };

  const onDelete = () => {
    let newdata = {
      id: data.id,
    };

    mutateDeleteSlot(newdata, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: res.message,
          });
          getEvents();
          setIsEdit(false);
          setModalVisibility(false);
        }
      },
      onError: (res) => {
        notification.warning({
          message: "Update Error",
          description: res.message,
        });
      },
    });
  };

  const { mutate: mutateEditSlot, isLoading: isLoadingEditSlot } = POST(
    "api/v1/admin/calendar/change-consultant-slot",
    "admin-calendar-events"
  );

  const { mutate: mutateDeleteSlot, isLoading: isLoadingDeleteSlot } = POST(
    "api/v1/admin/calendar/delete-consultant-slot",
    "admin-calendar-events"
  );

  return (
    <Modal
      open={showModal}
      // title=""
      title={
        <Typography.Text
          style={{ fontSize: 20, color: "#FFF", fontWeight: 400 }}
        >
          AVAILABILITY
        </Typography.Text>
      }
      closable
      className="modal-primary-default modal-change-2-factor-authentication modal-appointment"
      footer={
        isEdit ? (
          <>
            <Space>
              <Button
                type="primary"
                size="large"
                className="ant-btn ant-btn-primary atc-btn-opposite"
                style={{ textTransform: "uppercase" }}
                onClick={() => {
                  form.submit();
                }}
              >
                Save
              </Button>
              <Button
                type="primary"
                size="large"
                className="text-center btn-modal btn-modal-danger"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                Cancel
              </Button>
            </Space>
          </>
        ) : (
          <Space>
            <Button
              type="primary"
              className="ant-btn ant-btn-primary atc-btn-opposite"
              size="large"
              onClick={() => {
                setIsEdit(true);
              }}
            >
              {/* <FontAwesomeIcon icon={faPencil} /> */}
              UPDATE
            </Button>

            <Popconfirm
              placement="bottom"
              title="Delete Slot"
              description="Are you sure to delete this slot?"
              onConfirm={() => onDelete()}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "#9b0303" }} />}
            >
              <Button
                type="primary"
                className="text-center btn-modal btn-modal-danger"
                size="large"
              >
                {/* <FontAwesomeIcon icon={faTrash} /> */}
                DELETE
              </Button>
            </Popconfirm>
          </Space>
        )
      }
      onCancel={() => {
        setModalVisibility(false);
        setIsEdit(false);
        setData([]);
      }}
      bodyStyle={{ padding: "18px 16px 20px" }}
    >
      {isEdit == false ? (
        <Row
          gutter={[12, 12]}
          style={{ marginTop: "-12px", marginBottom: "-20px" }}
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            style={{ display: "flex", justifyContent: "left" }}
          >
            <Row gutter={[12, 12]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {data.length != 0 && (
                  <>
                    <Space style={{ marginBottom: "-2px" }}>
                      <span
                        style={{
                          color: "#779df9",
                          fontWeight: 400,
                          fontSize: 24,
                        }}
                      >
                        {data.user.firstname + " " + data.user.lastname}
                      </span>
                    </Space>
                    <Space>
                      <span style={{ fontSize: "14px" }}>
                        {moment(data.date).tz("MST").format("dddd, MMMM Do")}
                      </span>
                    </Space>
                    <Space>
                      <div style={{ fontSize: "14px" }}>
                        {moment(data.time_start, "H:mm").format("h:mm A") +
                          " - " +
                          moment(data.time_end, "H:mm").format("h:mm A")}
                      </div>
                    </Space>
                  </>
                )}
              </Col>
              <Col xs={24} sm={24} md={24}></Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={24} lg={24} sm={24} xs={24}>
            {" "}
            <Form name="dynamic_form_nest_item" onFinish={onFinish} form={form}>
              <Row style={{ marginBottom: "-27px" }}>
                <Col
                  xs={24}
                  md={24}
                  lg={24}
                  sm={24}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Row
                    className="timeslot-select-cont"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Col xs={24} md={24} lg={24} sm={24}>
                      <Form.Item
                        name="timeStart"
                        hasFeedback
                        rules={[validateRules.required]}
                      >
                        <FloatTimePicker
                          placeholder="Time Start"
                          label="Time Start"
                          format={"HH:mm"}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={24} lg={24} sm={24}>
                      <Form.Item
                        name="timeEnd"
                        hasFeedback
                        rules={[validateRules.required]}
                      >
                        <FloatTimePicker
                          placeholder="Time End"
                          label="Time End"
                          format={"HH:mm"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </Modal>
  );
}
