import React, { useState, useEffect } from "react";
import { InputNumber } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const FloatInputPhone = (props) => {
  const [focus, setFocus] = useState(false);
  const [inputValue, setValue] = useState();
  let {
    label,
    value,
    placeholder,
    required,
    international,
    countryCallingCodeEditable,
    defaultCountry,
  } = props;

  if (!placeholder) placeholder = label;

  const isOccupied =
    focus || (inputValue && inputValue.length !== 0) || international;

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div
      className="float-label input-phone"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <PhoneInput
        international={international ?? false}
        countryCallingCodeEditable={countryCallingCodeEditable ?? false}
        defaultCountry={defaultCountry ? defaultCountry : "US"}
        // countries={["US", "CA", "PR"]}
        onChange={props.onChange}
        value={value}
        style={{ width: "100%" }}
        className={"input-phone-number"}
        onBlur={props.onBlurInput}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInputPhone;
