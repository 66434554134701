import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import $ from "jquery";

function TableScrollOnTop(props) {
  const wrapperRef = useRef(null);
  const [scrollLength, setScrollLength] = useState(0);
  const [theWidth, setTheWidth] = useState();

  // Code for scrollbar to be visible on scroll
  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = props.tableRef2.current;
      if (!element) return;

      const elementRect = element.getBoundingClientRect();
      const elementTop = elementRect.top;

      if (elementTop <= 0 && !hasTriggered) {
        // Element hits the top of the viewport
        document.querySelector(".wrapper2").classList.add("wrapper-hits");
        // console.log("Element hit the top of the viewport!");
        setHasTriggered(true);
      } else if (elementTop > 0 && hasTriggered) {
        // Element is out of view
        document.querySelector(".wrapper2").classList.remove("wrapper-hits");
        // console.log("Element is out of view!");
        setHasTriggered(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasTriggered]);

  // End of code for scrollbar to be visible on scroll

  useEffect(() => {
    const onResize = () => {
      const scroller = document.querySelector("#tbl2 .ant-table-body");
      if (scroller) {
        const scrollLength = scroller.scrollWidth;
        setScrollLength(scrollLength);
        // console.log("scroll length", scrollLength)
      }
    };
    onResize();
    $(window).on("resize", onResize);
    return () => {
      $(window).off("resize", onResize);
    };
  }, [props]);

  useEffect(() => {
    if (wrapperRef.current) {
      const theWidth = wrapperRef.current.offsetWidth;
      setTheWidth(theWidth);
      // console.log("thewidth", theWidth);
    }

    $(wrapperRef.current).on("scroll", function () {
      $("#tbl2 .ant-table-body").scrollLeft($(this).scrollLeft());
    });

    $("#tbl2 .ant-table-body").on("scroll", function () {
      $(wrapperRef.current).scrollLeft($(this).scrollLeft());
    });
  }, []);

  return (
    <div
      className="wrapper2"
      ref={wrapperRef}
      style={
        theWidth == scrollLength
          ? { visibility: "hidden" }
          : { visibility: "visible" }
      }
    >
      <div
        className="division2"
        style={{
          width: scrollLength,
        }}
      ></div>
    </div>
  );
}

export default TableScrollOnTop;
