const optionCountryCodes = [
  { label: "United States", value: "United States" },
  { label: "Canada", value: "Canada" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  // { label: "Mexico", value: "Mexico" },
  // { value: "124", label: "Canada (124)" },
  // { value: "484", label: "Mexico (484)" },
  // { value: "840", label: "United States of America (840)" },
  // { value: "4", label: "Afghanistan (4)" },
  // { value: "248", label: "Åland Islands (248)" },
  // { value: "8", label: "Albania (8)" },
  // { value: "12", label: "Algeria (12)" },
  // { value: "16", label: "American Samoa (16)" },
  // { value: "20", label: "Andorra (20)" },
  // { value: "24", label: "Angola (24)" },
  // { value: "660", label: "Anguilla (660)" },
  // { value: "10", label: "Antarctica (10)" },
  // { value: "28", label: "Antigua and Barbuda (28)" },
  // { value: "32", label: "Argentina (32)" },
  // { value: "51", label: "Armenia (51)" },
  // { value: "533", label: "Aruba (533)" },
  // { value: "36", label: "Australia (36)" },
  // { value: "40", label: "Austria (40)" },
  // { value: "31", label: "Azerbaijan (31)" },
  // { value: "44", label: "Bahamas (44)" },
  // { value: "48", label: "Bahrain (48)" },
  // { value: "50", label: "Bangladesh (50)" },
  // { value: "52", label: "Barbados (52)" },
  // { value: "112", label: "Belarus (112)" },
  // { value: "56", label: "Belgium (56)" },
  // { value: "84", label: "Belize (84)" },
  // { value: "204", label: "Benin (204)" },
  // { value: "60", label: "Bermuda (60)" },
  // { value: "64", label: "Bhutan (64)" },
  // { value: "68", label: "Bolivia (Plurinational State of) (68)" },
  // { value: "535", label: "Bonaire, Sint Eustatius and Saba (535)" },
  // { value: "70", label: "Bosnia and Herzegovina (70)" },
  // { value: "72", label: "Botswana (72)" },
  // { value: "74", label: "Bouvet Island (74)" },
  // { value: "76", label: "Brazil (76)" },
  // { value: "86", label: "British Indian Ocean Territory (86)" },
  // { value: "96", label: "Brunei Darussalam (96)" },
  // { value: "100", label: "Bulgaria (100)" },
  // { value: "854", label: "Burkina Faso (854)" },
  // { value: "108", label: "Burundi (108)" },
  // { value: "132", label: "Cabo Verde (132)" },
  // { value: "116", label: "Cambodia (116)" },
  // { value: "120", label: "Cameroon (120)" },
  // { value: "136", label: "Cayman Islands (136)" },
  // { value: "140", label: "Central African Republic (140)" },
  // { value: "148", label: "Chad (148)" },
  // { value: "152", label: "Chile (152)" },
  // { value: "156", label: "China (156)" },
  // { value: "162", label: "Christmas Island (162)" },
  // { value: "166", label: "Cocos (Keeling) Islands (166)" },
  // { value: "170", label: "Colombia (170)" },
  // { value: "174", label: "Comoros (174)" },
  // { value: "178", label: "Congo (178)" },
  // { value: "180", label: "Congo (Democratic Republic of the) (180)" },
  // { value: "184", label: "Cook Islands (184)" },
  // { value: "188", label: "Costa Rica (188)" },
  // { value: "384", label: "Côte d'Ivoire (384)" },
  // { value: "191", label: "Croatia (191)" },
  // { value: "192", label: "Cuba (192)" },
  // { value: "531", label: "Curaçao (531)" },
  // { value: "196", label: "Cyprus (196)" },
  // { value: "203", label: "Czechia (203)" },
  // { value: "208", label: "Denmark (208)" },
  // { value: "262", label: "Djibouti (262)" },
  // { value: "212", label: "Dominica (212)" },
  // { value: "214", label: "Dominican Republic (214)" },
  // { value: "218", label: "Ecuador (218)" },
  // { value: "818", label: "Egypt (818)" },
  // { value: "222", label: "El Salvador (222)" },
  // { value: "226", label: "Equatorial Guinea (226)" },
  // { value: "232", label: "Eritrea (232)" },
  // { value: "233", label: "Estonia (233)" },
  // { value: "231", label: "Ethiopia (231)" },
  // { value: "238", label: "Falkland Islands (Malvinas) (238)" },
  // { value: "234", label: "Faroe Islands (234)" },
  // { value: "242", label: "Fiji (242)" },
  // { value: "246", label: "Finland (246)" },
  // { value: "250", label: "France (250)" },
  // { value: "254", label: "French Guiana (254)" },
  // { value: "258", label: "French Polynesia (258)" },
  // { value: "260", label: "French Southern Territories (260)" },
  // { value: "266", label: "Gabon (266)" },
  // { value: "270", label: "Gambia (270)" },
  // { value: "268", label: "Georgia (268)" },
  // { value: "276", label: "Germany (276)" },
  // { value: "288", label: "Ghana (288)" },
  // { value: "292", label: "Gibraltar (292)" },
  // { value: "300", label: "Greece (300)" },
  // { value: "304", label: "Greenland (304)" },
  // { value: "308", label: "Grenada (308)" },
  // { value: "312", label: "Guadeloupe (312)" },
  // { value: "316", label: "Guam (316)" },
  // { value: "320", label: "Guatemala (320)" },
  // { value: "831", label: "Guernsey (831)" },
  // { value: "324", label: "Guinea (324)" },
  // { value: "624", label: "Guinea-Bissau (624)" },
  // { value: "328", label: "Guyana (328)" },
  // { value: "332", label: "Haiti (332)" },
  // { value: "334", label: "Heard Island and McDonald Islands (334)" },
  // { value: "336", label: "Holy See (336)" },
  // { value: "340", label: "Honduras (340)" },
  // { value: "344", label: "Hong Kong (344)" },
  // { value: "348", label: "Hungary (348)" },
  // { value: "352", label: "Iceland (352)" },
  // { value: "356", label: "India (356)" },
  // { value: "360", label: "Indonesia (360)" },
  // { value: "364", label: "Iran (Islamic Republic of) (364)" },
  // { value: "368", label: "Iraq (368)" },
  // { value: "372", label: "Ireland (372)" },
  // { value: "833", label: "Isle of Man (833)" },
  // { value: "376", label: "Israel (376)" },
  // { value: "380", label: "Italy (380)" },
  // { value: "388", label: "Jamaica (388)" },
  // { value: "392", label: "Japan (392)" },
  // { value: "832", label: "Jersey (832)" },
  // { value: "400", label: "Jordan (400)" },
  // { value: "398", label: "Kazakhstan (398)" },
  // { value: "404", label: "Kenya (404)" },
  // { value: "296", label: "Kiribati (296)" },
  // { value: "408", label: "Korea (Democratic People's Republic of) (408)" },
  // { value: "410", label: "Korea (Republic of) (410)" },
  // { value: "414", label: "Kuwait (414)" },
  // { value: "417", label: "Kyrgyzstan (417)" },
  // { value: "418", label: "Lao People's Democratic Republic (418)" },
  // { value: "428", label: "Latvia (428)" },
  // { value: "422", label: "Lebanon (422)" },
  // { value: "426", label: "Lesotho (426)" },
  // { value: "430", label: "Liberia (430)" },
  // { value: "434", label: "Libya (434)" },
  // { value: "438", label: "Liechtenstein (438)" },
  // { value: "440", label: "Lithuania (440)" },
  // { value: "442", label: "Luxembourg (442)" },
  // { value: "446", label: "Macao (446)" },
  // { value: "807", label: "Macedonia (the former Yugoslav Republic of) (807)" },
  // { value: "450", label: "Madagascar (450)" },
  // { value: "454", label: "Malawi (454)" },
  // { value: "458", label: "Malaysia (458)" },
  // { value: "462", label: "Maldives (462)" },
  // { value: "466", label: "Mali (466)" },
  // { value: "470", label: "Malta (470)" },
  // { value: "584", label: "Marshall Islands (584)" },
  // { value: "474", label: "Martinique (474)" },
  // { value: "478", label: "Mauritania (478)" },
  // { value: "480", label: "Mauritius (480)" },
  // { value: "175", label: "Mayotte (175)" },
  // { value: "583", label: "Micronesia (Federated States of) (583)" },
  // { value: "498", label: "Moldova (Republic of) (498)" },
  // { value: "492", label: "Monaco (492)" },
  // { value: "496", label: "Mongolia (496)" },
  // { value: "499", label: "Montenegro (499)" },
  // { value: "500", label: "Montserrat (500)" },
  // { value: "504", label: "Morocco (504)" },
  // { value: "508", label: "Mozambique (508)" },
  // { value: "104", label: "Myanmar (104)" },
  // { value: "516", label: "Namibia (516)" },
  // { value: "520", label: "Nauru (520)" },
  // { value: "524", label: "Nepal (524)" },
  // { value: "528", label: "Netherlands (528)" },
  // { value: "540", label: "New Caledonia (540)" },
  // { value: "554", label: "New Zealand (554)" },
  // { value: "558", label: "Nicaragua (558)" },
  // { value: "562", label: "Niger (562)" },
  // { value: "566", label: "Nigeria (566)" },
  // { value: "570", label: "Niue (570)" },
  // { value: "574", label: "Norfolk Island (574)" },
  // { value: "580", label: "Northern Mariana Islands (580)" },
  // { value: "578", label: "Norway (578)" },
  // { value: "512", label: "Oman (512)" },
  // { value: "586", label: "Pakistan (586)" },
  // { value: "585", label: "Palau (585)" },
  // { value: "275", label: "Palestine, State of (275)" },
  // { value: "591", label: "Panama (591)" },
  // { value: "598", label: "Papua New Guinea (598)" },
  // { value: "600", label: "Paraguay (600)" },
  // { value: "604", label: "Peru (604)" },
  // { value: "608", label: "Philippines (608)" },
  // { value: "612", label: "Pitcairn (612)" },
  // { value: "616", label: "Poland (616)" },
  // { value: "620", label: "Portugal (620)" },
  // { value: "630", label: "Puerto Rico (630)" },
  // { value: "634", label: "Qatar (634)" },
  // { value: "638", label: "Réunion (638)" },
  // { value: "642", label: "Romania (642)" },
  // { value: "643", label: "Russian Federation (643)" },
  // { value: "646", label: "Rwanda (646)" },
  // { value: "652", label: "Saint Barthélemy (652)" },
  // { value: "654", label: "Saint Helena, Ascension and Tristan da Cunha (654)" },
  // { value: "659", label: "Saint Kitts and Nevis (659)" },
  // { value: "662", label: "Saint Lucia (662)" },
  // { value: "663", label: "Saint Martin (French part) (663)" },
  // { value: "666", label: "Saint Pierre and Miquelon (666)" },
  // { value: "670", label: "Saint Vincent and the Grenadines (670)" },
  // { value: "882", label: "Samoa (882)" },
  // { value: "674", label: "San Marino (674)" },
  // { value: "678", label: "Sao Tome and Principe (678)" },
  // { value: "682", label: "Saudi Arabia (682)" },
  // { value: "686", label: "Senegal (686)" },
  // { value: "688", label: "Serbia (688)" },
  // { value: "690", label: "Seychelles (690)" },
  // { value: "694", label: "Sierra Leone (694)" },
  // { value: "702", label: "Singapore (702)" },
  // { value: "534", label: "Sint Maarten (Dutch part) (534)" },
  // { value: "703", label: "Slovakia (703)" },
  // { value: "705", label: "Slovenia (705)" },
  // { value: "90", label: "Solomon Islands (90)" },
  // { value: "706", label: "Somalia (706)" },
  // { value: "710", label: "South Africa (710)" },
  // { value: "239", label: "South Georgia and the South Sandwich Islands (239)" },
  // { value: "728", label: "South Sudan (728)" },
  // { value: "724", label: "Spain (724)" },
  // { value: "144", label: "Sri Lanka (144)" },
  // { value: "729", label: "Sudan (729)" },
  // { value: "740", label: "Suriname (740)" },
  // { value: "744", label: "Svalbard and Jan Mayen (744)" },
  // { value: "748", label: "Swaziland (748)" },
  // { value: "752", label: "Sweden (752)" },
  // { value: "756", label: "Switzerland (756)" },
  // { value: "760", label: "Syrian Arab Republic (760)" },
  // { value: "158", label: "Taiwan, Province of China (158)" },
  // { value: "762", label: "Tajikistan (762)" },
  // { value: "834", label: "Tanzania, United Republic of (834)" },
  // { value: "764", label: "Thailand (764)" },
  // { value: "626", label: "Timor-Leste (626)" },
  // { value: "768", label: "Togo (768)" },
  // { value: "772", label: "Tokelau (772)" },
  // { value: "776", label: "Tonga (776)" },
  // { value: "780", label: "Trinidad and Tobago (780)" },
  // { value: "788", label: "Tunisia (788)" },
  // { value: "792", label: "Turkey (792)" },
  // { value: "795", label: "Turkmenistan (795)" },
  // { value: "796", label: "Turks and Caicos Islands (796)" },
  // { value: "798", label: "Tuvalu (798)" },
  // { value: "800", label: "Uganda (800)" },
  // { value: "804", label: "Ukraine (804)" },
  // { value: "784", label: "United Arab Emirates (784)" },
  // { value: "826", label: "United Kingdom of Great Britain and Northern Ireland (826)" },
  // { value: "581", label: "United States Minor Outlying Islands (581)" },
  // { value: "858", label: "Uruguay (858)" },
  // { value: "860", label: "Uzbekistan (860)" },
  // { value: "548", label: "Vanuatu (548)" },
  // { value: "862", label: "Venezuela (Bolivarian Republic of) (862)" },
  // { value: "704", label: "Viet Nam (704)" },
  // { value: "92", label: "Virgin Islands (British) (92)" },
  // { value: "850", label: "Virgin Islands (U.S.) (850)" },
  // { value: "876", label: "Wallis and Futuna (876)" },
  // { value: "732", label: "Western Sahara (732)" },
  // { value: "887", label: "Yemen (887)" },
  // { value: "894", label: "Zambia (894)" },
  // { value: "716", label: "Zimbabwe (716)" }
];

export default optionCountryCodes;
