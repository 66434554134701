import { Col, Row, Table } from "antd";
import { userData } from "../../../../../providers/companyInfo";
import { GET } from "../../../../../providers/useAxiosQuery";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import {
  TablePagination,
  TableShowingEntries,
} from "../../../Components/ComponentTableFilter";
import TableScrollOnTop from "../../../../../providers/TableScrollOnTop";
export default function ClientLastLogin(props) {
  const { toggleModalFormChangePassword, setToggleModalFormChangePassword } =
    props;

  const [tableDataSource, setTableDataSource] = useState([]);

  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_order: "desc",
    status: [],
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(50);
  const [searchText, setSearchText] = useState("");

  const onChange = (current, pageSize) => {
    setTableFilter({
      ...tableFilter,
      page_number: current,
      page_size: pageSize,
    });
    setCurrentSize(pageSize);
    setCurrentPage(current);
    // console.log("current", current);
    // console.log("pageSize", pageSize);
  };

  const [tableTotal, setTableTotal] = useState(0);

  const { refetch: getClientLastLogin } = GET(
    `api/v1/clients_last_login?${$.param(tableFilter)}`,
    "client-last-login",
    (res) => {
      if (res.success) {
        setTableDataSource(res.data.data);
        setCurrentPage(res.data.currentPage);
        setTableTotal(res.data.total);
      }
    }
  );

  useEffect(() => {
    getClientLastLogin();
    return () => {};
  }, [tableFilter]);
  const elementRef = useRef(null);
  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} md={24} sm={24} lg={24}>
        <TableScrollOnTop tableRef={elementRef} />
        <div id="tbl1" style={{ marginTop: "-8px" }}>
          <Table
            className="ant-table-default ant-table-striped"
            rowKey={(record) => record.id}
            dataSource={tableDataSource}
            pagination={false}
            ref={elementRef}
            style={{ top: "0px" }}
            sticky
          >
            <Table.Column
              width={200}
              align="left"
              key="firstname"
              title="First Name"
              dataIndex="firstname"
              sorter={(a, b) => a.firstname.localeCompare(b.firstname)}
            />
            <Table.Column
              width={200}
              key="lastname"
              title="Last Name"
              dataIndex="lastname"
              sorter={(a, b) => a.lastname.localeCompare(b.lastname)}
            />
            <Table.Column
              width={200}
              key="email"
              title="Email"
              dataIndex="email"
              sorter={(a, b) => a.email.localeCompare(b.email)}
            />
            <Table.Column
              width={200}
              key="phone"
              title="Phone"
              dataIndex="phone"
              sorter={(a, b) => a.phone.localeCompare(b.phone)}
            />
            <Table.Column
              width={200}
              key="last_login_dif"
              title="Client Last Login"
              dataIndex="last_login_dif"
              defaultSortOrder="descend"
              sorter={(a, b) => a.last_login.localeCompare(b.last_login)}
            />
          </Table>
        </div>
        <div className="ant-space-flex-space-between the-pagination the-pagination--client-last-login">
          <TableShowingEntries />
          <TablePagination
            paginationFilter={tableFilter}
            setPaginationFilter={setTableFilter}
            setPaginationTotal={tableTotal}
            showLessItems={true}
            showSizeChanger={false}
          />
        </div>
      </Col>
    </Row>
  );
}
