import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Badge,
  Image,
  Typography,
  Dropdown,
  PageHeader,
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { userData, role, apiUrl } from "../../providers/companyInfo";
import NotificationsAlert from "./Components/NotificationsAlert";
import defaultImage from "../../assets/img/default.png";
import { name, fullwidthwhitelogo } from "../../providers/companyInfo";
// import { GET } from "../../../../providers/useAxiosQuery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEdit, faPowerOff } from "@fortawesome/pro-regular-svg-icons";

import { GET, POST } from "../../providers/useAxiosQuery";

import {
  menuLeft as adminHeaderMenuLeft,
  dropDownMenuLeft as adminHeaderDropDownMenuLeft,
} from "./RoleMenu/admin/AdminHeader";
import {
  menuLeft as userHeaderMenuLeft,
  dropDownMenuLeft as userHeaderDropDownMenuLeft,
} from "./RoleMenu/user/UserHeader";
import {
  menuLeft as consultantHeaderMenuLeft,
  dropDownMenuLeft as consultantHeaderDropDownMenuLeft,
} from "./RoleMenu/consultant/ConsultantHeader";
import { useHistory } from "react-router-dom";
import Pusher from "pusher-js";
import { useQueryClient } from "react-query";

// import { GET } from "../../providers/useAxiosQuery";

export default function Header(props) {
  const {
    width,
    sideMenuCollapse,
    setSideMenuCollapse,
    pageHeaderIcon,
    title,
    subtitle,
    notifVilisble,
    setNotifVilisble,
  } = props;
  const [menuLeft, setMenuLeft] = useState(null);
  const history = useHistory();

  const queryClient = useQueryClient();

  const [notification, setNotification] = useState({
    count: 0,
    data: [],
  });

  const { refetch: refetchNotification } = GET(
    "api/v1/get_notification_alert",
    "get_notification_alert",

    (res) => {
      if (res.success) {
        setNotification({
          data: res.data,
          count: res.unread,
        });
      }
    },
    false
  );

  useEffect(() => {
    // Pusher.logToConsole = true;

    var pusher = new Pusher("5bb9eaf554f432b30222", {
      cluster: "us3",
    });

    var channel = pusher.subscribe("atc_listener");
    channel.bind("atc_listener", function (receive_message) {
      let _receive_message = receive_message.message;

      if (_receive_message.location === "http://127.0.0.1:3002") {
        _receive_message.location = "http://localhost:3002";
      }

      // // alert(JSON.stringify(receive_message.message));
      if (
        (_receive_message.room === userData().id &&
          _receive_message.location === window.location.origin &&
          _receive_message.type === "message") ||
        (_receive_message.type === "groupmessage" &&
          _receive_message.location === window.location.origin &&
          _receive_message.members &&
          _receive_message.members?.length > 0 &&
          _receive_message.members?.includes(userData().id))
      ) {
        queryClient.refetchQueries("get_notification_alert");
      } else if (
        _receive_message.type === "message" &&
        _receive_message.location == window.location.origin &&
        _receive_message.members &&
        _receive_message.members?.length > 0 &&
        _receive_message.members?.includes(userData().id)
      ) {
        queryClient.refetchQueries("get_notification_alert");
      }
    });

    // console.log("test message");

    return () => {};
  }, []);

  GET(
    "api/v1/maintenance",
    "maintenance",
    (res) => {
      if (!localStorage.fromMaintenance) {
        if (res.data?.in_maintenance === 1) {
          history.push("/maintenance");
        }
      }
    },
    false
  );

  // console.log("userData", userData);

  const { mutate: addHistoryLog } = POST(
    "api/v1/historylogs/add",
    "add_history_logs"
  );

  const handleLogout = () => {
    localStorage.clear();
    addHistoryLog(
      {
        page: "Logout",
        key: "Logout",
        old_data: "",
        new_data: "User logged out",
        method: "",

        // consultant: details[0].eventInfo.title,
      },
      {
        onSuccess: (res) => {},
      }
    );
    window.location.replace("/");
  };

  const [imageProfile, setImageProfile] = useState(defaultImage);
  useEffect(() => {
    let user = userData();
    if (role() === "Admin") {
      setMenuLeft(adminHeaderMenuLeft);
    } else if (role() === "User") {
      setMenuLeft(userHeaderMenuLeft);
    } else if (role() === "Consultant") {
      setMenuLeft(consultantHeaderMenuLeft);
      setMenuLeft(consultantHeaderDropDownMenuLeft);
    }
    // console.log("imageProfile_data", user);

    if (user?.profile_image) {
      let avatarImage = user?.profile_image.split("/");
      if (avatarImage[0] === "https:") {
        setImageProfile(user?.profile_image);
      } else {
        setImageProfile(apiUrl + user?.profile_image);
      }
    }
  }, [localStorage.getItem("userdata")]);

  const onClickMenuProfile = (e) => {
    // console.log("e", e);
  };

  const menuProfile = () => {
    const items = [
      {
        key: "/profile/details",
        className: "ant-menu-item-profile-details",
        label: (
          <div className="ant-menu-item-child ant-menu-item-profile">
            <Image
              className="ant-menu-submenu-profile-popup"
              src={imageProfile}
              preview={false}
            />

            <Typography.Text>
              <Typography.Text className="ant-typography-profile-details-name-info">
                {userData().firstname} {userData().lastname}
              </Typography.Text>
              <br />
              {/* <Typography.Text>{role()}</Typography.Text> */}
            </Typography.Text>
          </div>
        ),
      }, // remember to pass the key prop
      {
        key: "/profile/account",
        icon: <FontAwesomeIcon icon={faEdit} />,
        label: <Link to="/profile/account">Edit Account Profile</Link>,
      }, // which is required
    ];

    items.push({
      key: "/profile/signout",
      className: "ant-menu-item-logout",
      icon: <FontAwesomeIcon icon={faPowerOff} />,
      label: <Typography.Link onClick={handleLogout}>Sign Out</Typography.Link>,
    });

    return <Menu items={items} onClick={onClickMenuProfile} />;
  };

  useEffect(() => {
    refetchNotification();
    setNotifVilisble(false);
  }, [props.path]);

  useEffect(() => {
    if (!sideMenuCollapse) {
      setNotifVilisble(false);
    }
    console.log("sideMenuCollapse", sideMenuCollapse);
  }, [sideMenuCollapse]);

  return (
    <Layout.Header
      onClick={(e) => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
          if (notifVilisble) {
            setNotifVilisble(false);
          }
        }
      }}
    >
      <div
        className="ant-header-left-menu"
        style={{
          width: sideMenuCollapse === false ? "" : "calc(100% - 10px)",
        }}
        onClick={(e) => {
          if (notifVilisble) {
            setNotifVilisble(false);
          }
        }}
      >
        {width <= 767 && (
          <div className="ant-menu-left-icon ant-menu-left-icon-menu-collapse-on-close">
            {sideMenuCollapse ? (
              <MenuUnfoldOutlined
                onClick={() => setSideMenuCollapse(false)}
                className="menuCollapseOnClose"
              />
            ) : (
              <MenuFoldOutlined
                onClick={() => setSideMenuCollapse(true)}
                className="menuCollapseOnClose"
              />
            )}
          </div>
        )}
        {!sideMenuCollapse && width < 480 && (
          <img
            src={fullwidthwhitelogo}
            alt={name}
            width="150px"
            style={{ height: "50px", marginLeft: "9px", width: "120px" }}
          />
        )}

        {width > 768 && menuLeft !== null ? menuLeft : null}
        <div class="ant-page-header ant-page-header-ghost ant-page-header-compact">
          <div class="ant-page-header-heading">
            <div class="ant-page-header-heading-left">
              {/* span */}
              <div
                class="ant-page-header-heading-title"
                style={{ width: "calc(100% - 1px)" }}
              >
                <div className="ant-page-header-icon">
                  <FontAwesomeIcon icon={pageHeaderIcon} />
                </div>
                <div
                  className="ant-page-header-text"
                  style={{ width: "calc(100% - 65px)", paddingLeft: "10px" }}
                >
                  <div className="sub-title">{subtitle}</div>

                  <div className="title">
                    {title === "Cancelled Appointments" && width <= 390
                      ? "Cancelled Appt."
                      : title === "Mutual Confidentiality Agreement" &&
                        width <= 700
                      ? "MNDA"
                      : title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="ant-header-right-menu"
        style={{ width: "100px" }}
        onClick={(e) => {
          e.preventDefault();
          if (e.target === e.currentTarget) {
            if (notifVilisble) {
              setNotifVilisble(false);
            }
          }
        }}
      >
        <Dropdown
          overlay={menuProfile}
          placement="bottomRight"
          overlayClassName="ant-menu-submenu-profile-popup"
          trigger={["click"]}
          onClick={() => setNotifVilisble(false)}
        >
          <Image
            className="ant-menu-submenu-profile"
            src={imageProfile}
            preview={false}
          />
        </Dropdown>

        <Dropdown
          overlay={
            <NotificationsAlert
              notification={notification.data}
              refetch={refetchNotification}
              setNotifVilisble={setNotifVilisble}
            />
          }
          placement="bottomRight"
          overlayClassName="ant-menu-submenu-notification-popup scrollbar-2"
          trigger={["click"]}
          open={notifVilisble}
          onClick={(e) => {
            e.stopPropagation();
            setNotifVilisble(!notifVilisble);
          }}
        >
          <Badge
            count={notification.count < 99 ? notification.count : "99+"}
            className="ant-menu-submenu-notification"
          >
            <FontAwesomeIcon icon={faBell} />
          </Badge>
        </Dropdown>
      </div>
    </Layout.Header>
  );
}
