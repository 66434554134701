import { Button, Form, Modal, Row, Space, Col, notification } from "antd";
import FloatSelect from "../../../../../providers/FloatSelect2";
import FloatInput from "../../../../../providers/FloatInput";
import { GET, POST } from "../../../../../providers/useAxiosQuery";
import { useEffect, useState } from "react";
import { userData } from "../../../../../providers/companyInfo";
import validateRules from "../../../../../providers/validateRules";

export default function ModalChangeGroupMessageName(props) {
  const { showModal, setShowModal } = props;

  const [form1] = Form.useForm();

  const { mutate: mutateRenameGroupChat, isLoading: isLoadingRename } = POST(
    `api/v1/rename_group_chat`,
    [`new_group_chat`, "messages_user_group_chat", "messages"]
  );

  useEffect(() => {
    console.log("group_message_id", showModal.id);
  }, [showModal]);

  const onFinish = (values) => {
    // console.log("Success:", values);

    let data = {
      group_message_name: values.group_message_name,
      group_message_id: showModal.id,
    };

    mutateRenameGroupChat(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Rename Group Chat",
            description: "Group Chat Successfully Renamed! ",
          });

          form1.resetFields();
          setShowModal(false);
        }
      },
    });
  };

  return (
    <Modal
      open={showModal.toggle}
      className="modal modal-email-template"
      title="RENAME GROUP CHAT"
      okText="Submit"
      cancelText="Cancel"
      width={400}
      onCancel={() => {
        form1.resetFields();
        setShowModal(false);
      }}
      footer={[
        <Space>
          <Button
            className="atc-btn-opposite"
            onClick={() => {
              form1
                .validateFields()
                .then((values) => {
                  onFinish(values);
                })
                .catch((info) => {
                  console.log("Validate Failed:", info);
                });
            }}
            // loading={isLoadingNotification}
          >
            Submit
          </Button>
        </Space>,
      ]}
    >
      {/*  // initialValues={{ search_for: "Role" }}*/}
      <Form
        form={form1}
        name="new_group_chat"
        layout="vertical"
        // onFinish={onFinishNewEmailTemplate}
        // onFinishFailed={onFinishFailedNewEmailTemplate}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            {" "}
            <Form.Item
              name="group_message_name"
              className="m-b-sm form-select-error"
              hasFeedback
              rules={[validateRules.required]}
            >
              <FloatInput
                label="New Group Chat Name"
                placeholder="New Group Chat Name"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
