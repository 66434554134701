import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown, Modal, Typography, Space, Button } from "antd";
import { CheckOutlined, CloseSquareOutlined } from "@ant-design/icons";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";

import { POST } from "../../../providers/useAxiosQuery";
import { Link } from "react-router-dom";
import { role } from "../../../providers/companyInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

const NotificationsAlert = ({ notification, refetch, setNotifVilisble }) => {
  const history = useHistory();
  const [modal, setmodal] = useState(false);
  const [modaldata, setmodaldata] = useState("");
  const [modaltitle, setmodaltitle] = useState("");
  const [modaldescription, setmodaldescription] = useState("");
  const handleView = (item, type = "client") => {
    if (item.type !== "message_user" && item.type !== "message_gc") {
      if (role() === "Admin") {
        if (item.read === 0) {
          mutateReadNoLoad(
            { id: item.id, read: 1 },
            {
              onSuccess: (res) => {},
            }
          );
        }
        history.push(`/cancelled-appointments`);
      } else {
        setmodal(true);
        setmodaldata(item.id);
        setmodaltitle(item.notification?.title);
        setmodaldescription(item.notification?.description);
        setNotifVilisble(false);
      }
    } else {
      mutateReadNoLoad(
        { id: item.id, read: 1 },
        {
          onSuccess: (res) => {
            if (item.type === "message_user") {
              history.push({
                pathname: "/messages",
                search: `?message_id=${item.from_id}`,
              });

              if (history.location.pathname === "/messages") {
                window.location.reload();
              }
            } else if (item.type === "message_gc") {
              history.push({
                pathname: "/messages",
                search: `?group_chat_id=${item.from_id}`,
              });
              if (history.location.pathname === "/messages") {
                window.location.reload();
              }
            }
          },
        }
      );
    }
  };

  const handleCancel = () => {
    setmodal(false);
    mutateRead(
      { id: modaldata, read: 1 },
      {
        onSuccess: (res) => {
          refetch();
        },
      }
    );
  };

  const { mutate: mutateRead } = POST("api/v1/read", "get_notification_alert");

  const { mutate: mutateReadNoLoad } = POST(
    "api/v1/read",
    "get_notification_alert",
    false
  );

  const { mutate: mutateArchive, isLoading: isLoadingeArchive } = POST(
    "api/v1/archive",
    "get_notification_alert"
  );

  const handleRead = (item, status) => {
    mutateRead(
      { id: item.id, read: status === "read" ? 1 : 0 },
      {
        onSuccess: (res) => {
          refetch();
        },
      }
    );
  };
  const handleRemove = (item) => {
    mutateArchive(
      { id: item.id },
      {
        onSuccess: (res) => {
          refetch();
        },
      }
    );
  };

  const { mutate: mutateReadAll } = POST(
    "api/v1/read_all_notifications",
    "get_notification_alert",
    false
  );
  const handleClose = () => {
    mutateReadAll(
      {},
      {
        onSuccess: (res) => {
          setNotifVilisble(false);
        },
      }
    );
    setNotifVilisble(false);
  };

  const menuActions = (item) => {
    return (
      <Menu>
        {item.read === 0 ? (
          <Menu.Item key="#mark-as-read" icon={<CheckOutlined />}>
            <Link to="#" onClick={() => handleRead(item, "read")}>
              Mark as read
            </Link>
          </Menu.Item>
        ) : null}

        {item.read === 1 ? (
          <Menu.Item
            key="#mark-as-unread"
            icon={<CheckOutlined />}
            onClick={() => handleRead(item, "unread")}
          >
            <Link to="#">Mark as unread</Link>
          </Menu.Item>
        ) : null}

        <Menu.Item
          key="#remove-notification"
          icon={<CloseSquareOutlined />}
          onClick={() => handleRemove(item)}
          isLoading={isLoadingeArchive}
        >
          <Link to="#">Remove this notification</Link>
        </Menu.Item>
      </Menu>
    );
  };

  const notificationList = () => {
    if (notification && notification.length > 0) {
      return notification.map((item, index) => {
        return (
          // handleView(item)
          <Menu.Item
            className="notif-error"
            style={{ width: "100%" }}
            key={index}
          >
            <div

            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "space-between",
            //   width: "100%",
            // }}
            >
              <div /*style={{ width: "85%" }} */ className="notif-container">
                <Typography.Text
                  onClick={() => {
                    handleView(item);
                  }}
                >
                  <div className="notif-title-desc" x>
                    <Typography.Text strong ellipsis>
                      {item.notification ? item.notification.title : "No title"}
                    </Typography.Text>
                    <Typography.Paragraph ellipsis style={{ marginBottom: 5 }}>
                      {item.notification
                        ? item.notification.description
                        : "No Description"}
                    </Typography.Paragraph>
                  </div>
                </Typography.Text>
                <div
                  style={{
                    width: "30px",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "0px",
                    marginTop: "-2px",
                  }}
                  className="the-dots"
                >
                  <span className="ant-dropdown-container">
                    <Dropdown
                      overlay={(e) => menuActions(item)}
                      // placement="bottomRight"
                      overlayClassName="ant-menu-submenu-notification-action"
                      arrow
                    >
                      <HiOutlineDotsCircleHorizontal />
                    </Dropdown>
                  </span>

                  {item.read === 0 ? (
                    <span
                      className="ant-status-container"
                      style={{ marginRight: "-3px" }}
                    >
                      <GoPrimitiveDot />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </Menu.Item>
        );
      });
    } else {
      return (
        <Menu.Item
          key="#"
          className="text-center ant-notification empty-ant-notification"
        >
          <Link to="#">No notification</Link>
        </Menu.Item>
      );
    }
  };

  return (
    <>
      <Menu
      // onClick={(e) => {
      //   e.stopPropagation();
      //   setNotifVilisble(true);
      // }}
      >
        <Space
          className="w-100"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Menu.Item key="#notification" className="title">
            <Link style={{ fontWeight: 400 }} to="#">
              Notifications
            </Link>
          </Menu.Item>

          <Button
            type="link"
            className="font-18px"
            onClick={() => {
              handleClose();
            }}
            style={{
              paddingTop: "3px",
              paddingRight: "13px",
            }}
          >
            <FontAwesomeIcon icon={faClose} style={{ color: "#262626" }} />
          </Button>
        </Space>
        <Menu.Divider
          key="#notificationdivider"
          style={{
            background: "#dddddd",
          }}
        />

        <div
          className="scrollable-notification scrollbar-2"
          style={{
            maxHeight: "339px",
            overflow: "auto",
          }}
        >
          {" "}
          {notificationList()}
        </div>
      </Menu>

      <Modal
        className="modal-login"
        title={modaltitle}
        open={modal}
        footer={false}
        onCancel={handleCancel}
      >
        <span>{modaldescription}</span>
      </Modal>
    </>
  );
};

export default NotificationsAlert;
