import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import TableScrollOnTop from "../../../../providers/TableScrollOnTop";
import { GET, POST } from "../../../../providers/useAxiosQuery";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClose,
  faPencil,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import ModalAdd from "./ModalAdd";

export default function PageCoupon({ match, permission }) {
  const history = useHistory();
  const elementRef = useRef(null);
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 10,
    search: "",
    sort_field: "coupon_code",
    sort_order: "desc",
    status: ["active", "deactivated"],
  });

  const {
    data: dataTable,
    refetch: refetchTable,
    isFetching: isFetchingTable,
    isLoading: isLoadingTable,
  } = GET(`api/v1/coupons?${$.param(tableFilter)}`, "coupons_table", (res) => {
    if (res.success) {
      console.log("dataTable", res);
    }
  });

  const [searchText, setSearchText] = useState("");
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  useEffect(() => {
    refetchTable();
    return () => {};
  }, [tableFilter]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableFilter({
      ...tableFilter,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
      page: 1,
      status: filters.status ? filters.status : ["active", "deactivated"],
    });
  };

  const [toggleModalForm, setToggleModalForm] = useState({
    show: false,
    data: null,
  });

  const { mutate: mutateCoupon } = POST(
    "api/v1/delete_coupon_system",
    "coupons_table"
  );

  const deleteCoupon = (id, val) => {
    mutateCoupon(
      { id: id, status: val },
      {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Sucess",
              description: "Coupon sucessfully updated",
            });
          }
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  };

  return (
    <Card className="admin-page-user card--padding-mobile">
      <Row gutter={12}>
        <Col xs={24} sm={24} md={24} className="m-b-md">
          <Button
            size="large"
            className="atc-btn-opposite b-r-none"
            onClick={() => {
              setToggleModalForm({
                show: true,
                data: null,
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="m-r-sm" /> Add Coupon
          </Button>
        </Col>

        {/* table pagination and searc */}
        <Col xs={24} sm={24} md={24}>
          <Row gutter={12} className="m-b-sm">
            <Col xs={24} sm={24} md={12} lg={12} xl={18} className="m-b-sm">
              <Space>
                <TablePageSize
                  paginationFilter={tableFilter}
                  setPaginationFilter={setTableFilter}
                />
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
              <TableGlobalSearch
                paginationFilter={searchText}
                setPaginationFilter={setSearchText}
              />
            </Col>
          </Row>
        </Col>

        {/* table */}
        <Col xs={24} sm={24} md={24}>
          <TableScrollOnTop tableRef={elementRef} />
          <div id="tbl1" style={{ marginTop: "-8px" }}>
            <Table
              className="ant-table-default ant-table-striped"
              dataSource={
                dataTable?.data && dataTable?.data?.data
                  ? dataTable?.data?.data
                  : []
              }
              loading={isLoadingTable || isFetchingTable}
              rowKey={(record) => record.id}
              pagination={false}
              bordered={false}
              ref={elementRef}
              onChange={handleTableChange}
              style={{ top: "0px" }}
              sticky
            >
              <Table.Column
                key="coupon_code"
                title="Coupon Code"
                dataIndex="coupon_code"
                defaultSortOrder="descend"
                width={"180px"}
                sorter
              />
              <Table.Column
                key="coupon_name"
                title="Coupon Name"
                dataIndex="coupon_name"
                width={"200px"}
                sorter
              />

              <Table.Column
                key="discount_str"
                title="Discount"
                dataIndex="discount_str"
                width={"180px"}
                sorter
                // render={(text, record) => {
                //   return <div key={record.id}>{record.type === "percent" ? text + "% off" : "$" + text}</div>
                // }}
              />

              <Table.Column
                title="Duration"
                key="duration_from"
                dataIndex="duration_from"
                width={"230px"}
                sorter
                render={(text, record) => {
                  return (
                    <div key={record.id}>
                      {"" + record.duration_from + " - " + record.duration_to}
                    </div>
                  );
                }}
              />

              <Table.Column
                key="redemption_str"
                title="Redemption"
                dataIndex="redemption_str"
                width={"150px"}
                sorter
                // render={(text, record) => {
                //   return (
                //     <div key={record.id}>
                //       {record.redemption
                //         ? record.redemption + "/" + record.max
                //         : record.redemption}
                //     </div>
                //   );
                // }}
              />

              <Table.Column
                key="status"
                title="Status"
                dataIndex="status"
                width={"150px"}
                sorter
                render={(text, record) => {
                  return (
                    <span style={{ textTransform: "capitalize" }}>{text}</span>
                  );
                }}
                className="filter-class"
                filters={[
                  {
                    text: "Active",
                    value: "active",
                  },
                  {
                    text: "Deactivated",
                    value: "deactivated",
                  },
                ]}
                filteredValue={tableFilter.status}
              />

              <Table.Column
                key="use_once"
                title="Use Once"
                dataIndex="use_once"
                width={"120px"}
                sorter
              />

              <Table.Column
                key="action"
                title="Action"
                width={"120px"}
                className="table-th"
                // sorter
                render={(text, record) => {
                  return (
                    <Space>
                      <Tooltip title="Update">
                        <Button
                          size="small"
                          className="text-center btn-modal btn-modal-warning btn-coupon-warning coupon-btn"
                          onClick={(e) =>
                            setToggleModalForm({ data: record, show: true })
                          }
                          style={{ width: 30, height: 30, paddingTop: 1 }}
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </Button>
                      </Tooltip>

                      {record.status === "active" ? (
                        <Popconfirm
                          placement="topRight"
                          title={
                            "Are you sure you want to deactivate this coupon?"
                          }
                          onConfirm={() =>
                            deleteCoupon(record.id, "deactivated")
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Deactivate">
                            <Button
                              size="small"
                              className="text-center btn-modal btn-modal-danger btn-coupon-danger coupon-btn"
                              style={{ width: 30, height: 30, paddingTop: 1 }}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </Button>
                          </Tooltip>
                        </Popconfirm>
                      ) : (
                        <Popconfirm
                          placement="topRight"
                          title={
                            "Are you sure you want to activate this coupon?"
                          }
                          onConfirm={() => deleteCoupon(record.id, "active")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Activate">
                            <Button
                              size="small"
                              className="text-center btn-modal btn-modal-danger btn-coupon-danger coupon-btn"
                              style={{ width: 30, height: 30, paddingTop: 1 }}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                          </Tooltip>
                        </Popconfirm>
                      )}
                    </Space>
                  );
                }}
              />
            </Table>
          </div>
        </Col>

        {/* table pagination */}
        <Col xs={24} sm={24} md={24}>
          <div className="ant-space-flex-space-between the-pagination the-pagination--view-user">
            <TableShowingEntries />
            <TablePagination
              paginationFilter={tableFilter}
              setPaginationFilter={setTableFilter}
              setPaginationTotal={dataTable?.data?.total}
              showLessItems={true}
              showSizeChanger={false}
            />
          </div>
        </Col>
      </Row>

      <ModalAdd
        toggleModalForm={toggleModalForm}
        setToggleModalForm={setToggleModalForm}
      />
    </Card>
  );
}
