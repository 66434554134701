import { Collapse, Divider, Dropdown, Menu, notification } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import $, { type } from "jquery";
import {
  DeleteOutlined,
  FormOutlined,
  RedoOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { userData } from "../../../../../providers/companyInfo";
import { POST } from "../../../../../providers/useAxiosQuery";

export default function ChatSideMenu(props) {
  const {
    messageItems,
    userdata,
    filterMessageStatus,
    setSelectedMessage,
    handleRestoreMessage,
    handleArchiveMessage,
    showModal,
    setShowModalRename,
  } = props;

  const history = useHistory();

  const { mutate: mutateBlockUser, isLoading: isLoadingMutatePushNotif } = POST(
    "api/v1/message/block-user",
    ["messages"],
    false
  );

  const handleBlockUser = (data) => {
    mutateBlockUser(data, {
      onSuccess: (res) => {
        notification.success({
          message: "Success",
          description:
            "User has been " + (data.unblock ? "unblocked" : "blocked") + ".",
        });
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  const menuItemsArchive = (item) => {
    return [
      {
        label: (
          <div onClick={(e) => handleRestoreMessage(item.id, item.type)}>
            <RedoOutlined /> Restore Message
          </div>
        ),
        key: "0",
      },
    ];
  };

  const menuItemsActive = (item) => {
    let menuItems = [
      {
        label: (
          <div onClick={(e) => handleArchiveMessage(item.id, item.type)}>
            <DeleteOutlined /> Archive Message
          </div>
        ),
        key: "0",
      },
    ];

    if (item.type === "gc") {
      menuItems.push({
        label: (
          <div
            onClick={(e) => setShowModalRename({ toggle: true, id: item.id })}
          >
            <FormOutlined /> Rename Group Chat
          </div>
        ),
        key: "1",
      });
    }

    if (
      userData().username === "superadmin" &&
      item.type !== "gc" &&
      filterMessageStatus !== "Blocked"
    ) {
      menuItems.push({
        label: (
          <div onClick={(e) => handleBlockUser({ id: item.id })}>
            <StopOutlined /> Block User
          </div>
        ),
        key: "1",
      });
    }

    if (filterMessageStatus === "Blocked") {
      menuItems.push({
        label: (
          <div onClick={(e) => handleBlockUser({ id: item.id, unblock: true })}>
            <StopOutlined /> Unblock User
          </div>
        ),
        key: "1",
      });
    }

    return menuItems;
  };

  return (
    <>
      <Menu theme="light" mode="inline" className="messagesMenu">
        {filterMessageStatus !== "Archived" &&
          filterMessageStatus !== "Blocked" && (
            <>
              <Divider className="msg-sidemenu-dvdr" />

              <div
                className={`ant-menu-item the-group-chat-btn`}
                style={{
                  marginBottom: "10px",
                }}
                onClick={(e) => {
                  showModal(true);
                }}
              >
                {
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ fontSize: "25px" }}
                    className="add-new-group-chat-icon"
                  />
                }
                <span
                  className="ant-menu-title-content"
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {"CREATE NEW GROUP CHAT"}
                </span>
              </div>

              <Divider className="msg-sidemenu-dvdr" />
            </>
          )}

        {messageItems.map((item, index) => {
          return (
            <Dropdown
              disabled={userdata.role === "Admin" ? false : true}
              key={index}
              menu={{
                items:
                  filterMessageStatus === "Archived"
                    ? menuItemsArchive(item)
                    : menuItemsActive(item),
              }}
              trigger={["contextMenu"]}
            >
              <div
                className={`ant-menu-item item_key_${item.key}`}
                onClick={(e) => {
                  history.push("/messages");
                  // $(".messagesMenu").removeClass("ant-menu-item-selected");
                  // $(e.target)
                  //   .closest(".messagesMenu")
                  //   .addClass("ant-menu-item-selected");
                  setSelectedMessage({ id: item.id, type: item.type });

                  // setShowNewMessageContainer(
                  //     false
                  // );
                }}
                style={{
                  marginBottom: "10px",
                }}
              >
                {item.icon}
                <span
                  className="ant-menu-title-content"
                  style={{
                    marginLeft: 10,
                  }}
                >
                  {item.label}
                </span>
              </div>
            </Dropdown>
          );
        })}
      </Menu>
    </>
  );
}
