import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Space, Table, Typography } from "antd";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { apiUrl } from "../../../../../providers/companyInfo";

export default function PageOrder(props) {
  const { toggleModal, setToggleModal, handleToggleModal } = props;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // onAfterPrint: () => {
    //   handleToggleModal();
    // },
    removeAfterPrint: true,
  });
  return (
    <Modal
      open={toggleModal.show}
      // title="APPOINTMENT"
      title={
        <Typography.Text
          style={{
            fontSize: 20,
            color: "#FFF",
            fontWeight: 400,
            marginLeft: "-8px",
          }}
        >
          INVOICE
        </Typography.Text>
      }
      onCancel={handleToggleModal}
      beforeClose={handleToggleModal}
      className="modal-primary-default modal-change-2-factor-authentication user-sched-appt modal-invoice"
      footer={false}
    >
      <Row gutter={24} style={{ marginBottom: 50 }}>
        <Col span={24} className="text-right">
          <Button
            onClick={handlePrint}
            type="link"
            size="large"
            style={{ fontSize: 25, marginRight: "-16px", marginTop: "-20px" }}
          >
            <FontAwesomeIcon icon={faPrint} />
          </Button>
        </Col>
      </Row>

      <div id="elementId">
        <Row gutter={24}>
          <Col span={16}>
            <Space direction="vertical" size={0}>
              <Typography.Title>
                Airline Transition Consultants
              </Typography.Title>
              <Typography.Title level={4}>
                Date: {toggleModal?.data?.date}
              </Typography.Title>
            </Space>
          </Col>
          <Col span={8} className="text-right">
            <Space direction="vertical" size={0}>
              <Typography.Title>Invoice</Typography.Title>
              <Typography.Title level={4}>
                {toggleModal?.data?.invoice_number}
              </Typography.Title>
            </Space>
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: 50 }}>
          <Col span={12}>
            <Space direction="vertical" size={0} className="space-item-height">
              <Typography.Title level={5} style={{ lineHeight: "18px" }}>
                Airline Transition Consultants
              </Typography.Title>
              <Typography.Text>4178 Beus Drive</Typography.Text>
              <Typography.Text>Ogden, UT 84403</Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="text-right">
            <Space direction="vertical" size={0} className="space-item-height">
              <Typography.Title level={5} style={{ lineHeight: "18px" }}>
                BILL TO
              </Typography.Title>
              <Typography.Title level={5} style={{ lineHeight: "18px" }}>
                {toggleModal?.data?.full_name}
              </Typography.Title>
              <Typography.Text>{`${toggleModal?.data?.address_1} ${
                toggleModal?.data?.address_2
                  ? ", " + toggleModal?.data?.address_2
                  : ""
              }`}</Typography.Text>
              <Typography.Text>{`${toggleModal?.data?.city}, ${toggleModal?.data?.state} ${toggleModal?.data?.postcode}`}</Typography.Text>
            </Space>
          </Col>
        </Row>

        <Table
          pagination={false}
          style={{ marginTop: 50 }}
          dataSource={toggleModal?.dataTable}
          rowKey={(record) => record.description}
        >
          <Table.Column
            title="Description"
            key="description"
            dataIndex={"description"}
            width={"450px"}
          />
          <Table.Column
            title="Quantity"
            key="quantity"
            dataIndex={"quantity"}
            width={"150px"}
          />
          <Table.Column
            title="Amount"
            key="amount"
            dataIndex={"amount"}
            className="text-right"
            width={"150px"}
          />
        </Table>

        <Row gutter={24} style={{ marginTop: 50 }} justify={"end"}>
          <Col span={4}>
            <Space direction="vertical" size={0}>
              <Typography.Title level={5}>Cost Total:</Typography.Title>
              <Typography.Title level={5}>Discounts:</Typography.Title>
              <Typography.Title level={5} style={{ marginTop: 30 }}>
                TOTAL:
              </Typography.Title>
            </Space>
          </Col>
          <Col span={4} className="text-right">
            <Space direction="vertical" size={0}>
              <Typography.Title level={5} style={{ paddingRight: 14 }}>
                {toggleModal?.data?.amount}
              </Typography.Title>
              <Typography.Title level={5} style={{ paddingRight: 14 }}>
                {toggleModal?.data?.discount}
              </Typography.Title>
              <Typography.Title
                level={5}
                style={{ marginTop: 30, paddingRight: 14 }}
              >
                {toggleModal?.data?.total}
              </Typography.Title>
            </Space>
          </Col>
        </Row>

        <div
          style={{
            position: "absolute",
            bottom: 24,
            borderTop: "1px solid",
            paddingTop: 15,
            width: 785,
          }}
        >
          <img
            id="myImg"
            src={`${apiUrl}atc-logo.png`}
            style={{ width: 200 }}
          />
        </div>
      </div>

      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} toggleModal={toggleModal} />
      </div>
    </Modal>
  );
}

class ComponentToPrint extends React.Component {
  render() {
    const { toggleModal } = this.props;
    return (
      <div id="elementId" className="print-invoice">
        <Row gutter={24}>
          <Col span={16}>
            <Space direction="vertical" size={0}>
              <Typography.Title>
                Airline Transition Consultants
              </Typography.Title>
              <Typography.Title level={4}>
                Date: {toggleModal?.data?.date}
              </Typography.Title>
            </Space>
          </Col>
          <Col span={8} className="text-right">
            <Space direction="vertical" size={0}>
              <Typography.Title>Invoice</Typography.Title>
              <Typography.Title level={4}>
                {toggleModal?.data?.invoice_number}
              </Typography.Title>
            </Space>
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: 50 }}>
          <Col span={12}>
            <Space direction="vertical" size={0} className="space-item-height">
              <Typography.Title level={5} style={{ lineHeight: "18px" }}>
                Airline Transition Consultants
              </Typography.Title>
              <Typography.Text>4178 Beus Drive</Typography.Text>
              <Typography.Text>Ogden, UT 84403</Typography.Text>
            </Space>
          </Col>
          <Col span={12} className="text-right">
            <Space direction="vertical" size={0} className="space-item-height">
              <Typography.Title level={5} style={{ lineHeight: "18px" }}>
                BILL TO
              </Typography.Title>
              <Typography.Title level={5} style={{ lineHeight: "18px" }}>
                {toggleModal?.data?.full_name}
              </Typography.Title>
              <Typography.Text>{`${toggleModal?.data?.address_1} ${
                toggleModal?.data?.address_2
                  ? ", " + toggleModal?.data?.address_2
                  : ""
              }`}</Typography.Text>
              <Typography.Text>{`${toggleModal?.data?.city}, ${toggleModal?.data?.state} ${toggleModal?.data?.postcode}`}</Typography.Text>
            </Space>
          </Col>
        </Row>

        <Table
          pagination={false}
          style={{ marginTop: 50 }}
          dataSource={toggleModal?.dataTable}
          rowKey={(record) => record.description}
        >
          <Table.Column
            title="Description"
            key="description"
            dataIndex={"description"}
            width={"450px"}
          />
          <Table.Column
            title="Quantity"
            key="quantity"
            dataIndex={"quantity"}
            width={"150px"}
          />
          <Table.Column
            title="Amount"
            key="amount"
            dataIndex={"amount"}
            className="text-right"
            width={"150px"}
          />
        </Table>

        <Row gutter={24} style={{ marginTop: 50 }} justify={"end"}>
          <Col span={4}>
            <Space direction="vertical" size={0}>
              <Typography.Title level={5}>Cost Total:</Typography.Title>
              <Typography.Title level={5}>Discounts:</Typography.Title>
              <Typography.Title level={5} style={{ marginTop: 30 }}>
                TOTAL:
              </Typography.Title>
            </Space>
          </Col>
          <Col span={4} className="text-right">
            <Space direction="vertical" size={0}>
              <Typography.Title level={5} style={{ paddingRight: 14 }}>
                {toggleModal?.data?.amount}
              </Typography.Title>
              <Typography.Title level={5} style={{ paddingRight: 14 }}>
                {toggleModal?.data?.discount}
              </Typography.Title>
              <Typography.Title
                level={5}
                style={{ marginTop: 30, paddingRight: 14 }}
              >
                {toggleModal?.data?.total}
              </Typography.Title>
            </Space>
          </Col>
        </Row>

        <div
          style={{
            position: "absolute",
            bottom: 24,
            borderTop: "1px solid",
            paddingTop: 15,
            width: 785,
          }}
        >
          <img
            id="myImg"
            src={`${apiUrl}atc-logo.png`}
            style={{ width: 200 }}
          />
        </div>
      </div>
    );
  }
}
