import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Table, Space, Card, Typography } from "antd";
import $ from "jquery";
import { GET } from "../../../../providers/useAxiosQuery";
import { faEye, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import {
  TableGlobalSearch,
  TablePageSize,
  TablePagination,
  TableShowingEntries,
} from "../../Components/ComponentTableFilter";
import ModalCancelledAppointment from "../../../../layouts/private/Components/ModalCancelledAppointment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalBulkEmailsList from "./Components/ModalBulkEmailsList";
import TableScrollOnTop from "../../../../providers/TableScrollOnTop";

export default function PageNotification({ match, permission, title }) {
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  const [bookingDetails, setBookingDetails] = useState();
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    page_size: 50,
    search: "",
    sort_field: "cancelled_at",
    sort_order: "desc",
    status: [],
  });

  const [searchText, setSearchText] = useState("");
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const timeoutId = setTimeout(() => {
      setTableFilter({
        ...tableFilter,
        search: searchText,
        page: 1,
      });
    }, 1500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  const [tableTotal, setTableTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const { refetch: refetchTickets, isLoading: isLoadingTickets } = GET(
    `api/v1/appointment/cancelled_booking?${$.param(tableFilter)}`,
    `notification`,
    (res) => {
      if (res.success) {
        res.data.data.map((details) => {
          details.client_fullname =
            details?.client?.firstname + " " + details?.client?.lastname;
          details.consultant_fullname =
            details?.user?.firstname + " " + details?.user?.lastname;

          return details;
        });
        setDataSource(res.data.data);
        setTableTotal(res.data.total);
      }
    }
  );

  useEffect(() => {
    refetchTickets();
    return () => {};
  }, [tableFilter]);

  const onChange = (current, pageSize, sorter) => {
    setTableFilter({
      ...tableFilter,
      page: 1,
      sort_field: sorter.columnKey,
      sort_order: sorter.order ? sorter.order.replace("end", "") : null,
    });
  };

  const [showModalNew, setShowModalNew] = useState(false);
  const [showModalBulkEmails, setShowModalBulkEmails] = useState(false);
  const handleEdit = (record) => {
    setBookingDetails(record);
    setShowModalNew(true);
  };

  const elementRef = useRef(null);
  return (
    <>
      <Card
        className="card-min-height card--padding-mobile the-cancelled-appointments"
        id="PageFaqs"
      >
        <Row gutter={12} className="m-b-sm">
          <Col xs={24} sm={24} md={12} lg={12} xl={18} className="m-b-sm">
            <Space>
              <TablePageSize
                paginationFilter={tableFilter}
                setPaginationFilter={setTableFilter}
              />
            </Space>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6}>
            <TableGlobalSearch
              paginationFilter={searchText}
              setPaginationFilter={setSearchText}
            />
          </Col>
        </Row>
        <TableScrollOnTop tableRef={elementRef} />
        <div id="tbl1" style={{ marginTop: "-8px" }}>
          <Table
            className="ant-table-default ant-table-striped"
            rowKey={(record) => record.id}
            onChange={onChange}
            loading={isLoadingTickets}
            dataSource={dataSource}
            pagination={false}
            ref={elementRef}
            style={{ top: "0px" }}
            sticky
          >
            <Table.Column
              width={200}
              key="client"
              title="Client"
              dataIndex="client_fullname"
              sorter={true}
            />
            <Table.Column
              width={200}
              key="consultant"
              title="Consultant"
              dataIndex="consultant_fullname"
              sorter={true}
            />
            <Table.Column
              width={200}
              key="schedule_date"
              title="Schedule Date"
              dataIndex="schedule_date"
              sorter={true}
            />
            <Table.Column
              width={200}
              key="time_start"
              title="Time Start"
              dataIndex="time_start"
              sorter={true}
            />
            <Table.Column
              width={200}
              key="time_end"
              title="Time End"
              dataIndex="time_end"
              sorter={true}
            />
            <Table.Column
              width={200}
              key="cancelled_at"
              title="Date Cancelled"
              dataIndex="cancelled_at"
              defaultSortOrder="descend"
              sorter={true}
            />
            <Table.Column
              key="action"
              // title="Send Bulk Email Notification"
              className="text-left notif-send"
              title={
                <Typography.Text
                  style={{
                    lineHeight: "16px",
                    color: "#325db8",
                  }}
                >
                  Send Bulk
                  <br />
                  Email Notification
                </Typography.Text>
              }
              width={170}
              align="center"
              render={(text, record) => {
                return (
                  <div className="w-100 text-center">
                    <Space>
                      <Button
                        size="large"
                        type="link"
                        className="light-blue-link"
                        onClick={(e) => handleEdit(record)}
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="table-link"
                        />
                      </Button>

                      <Button
                        size="large"
                        type="link"
                        className="light-blue-link"
                        onClick={(e) => {
                          setShowModalBulkEmails(true);
                          setBookingDetails(record);
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} className="table-link" />
                      </Button>
                    </Space>
                  </div>
                );
              }}
            />
          </Table>
        </div>
        <div className="ant-space-flex-space-between the-pagination the-pagination--cancelled-appointments">
          <TableShowingEntries />
          <TablePagination
            paginationFilter={tableFilter}
            setPaginationFilter={setTableFilter}
            setPaginationTotal={tableTotal}
            showLessItems={true}
            showSizeChanger={false}
          />
        </div>
      </Card>

      <ModalCancelledAppointment
        setShowModal={setShowModalNew}
        showModal={showModalNew}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
      />

      <ModalBulkEmailsList
        showModalBulkEmails={showModalBulkEmails}
        setShowModalBulkEmails={setShowModalBulkEmails}
        bookingDetails={bookingDetails}
        setBookingDetails={setBookingDetails}
      />
    </>
  );
}
