import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  message,
  Radio,
  Row,
  Switch,
  Typography,
  Upload,
  notification,
  Result,
} from "antd";
import ImgCrop from "antd-img-crop";
// import optionCountryCodes from "../../../../providers/optionCountryCodes"
import optionStateCodesUnitedState from "../../../../providers/optionStateCodesUnitedState";
import optionStateCodesCanada from "../../../../providers/optionStateCodesCanada";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import { apiUrl, userData, encrypt } from "../../../../providers/companyInfo";
import { GET, POST, POSTNOLOADING } from "../../../../providers/useAxiosQuery";
import ModalDeactivateAcc from "./Components/ModalDeactivateAcc";
import ModaFormChangePassword from "./Components/ModaFormChangePassword";
import ModaForm2FactorAuthentication from "./Components/ModaForm2FactorAuthentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import validateRules from "../../../../providers/validateRules";
import FloatInputMask from "../../../../providers/FloatInputMaskDate";
import $ from "jquery";
import FloatInputPhone from "../../../../providers/FloatInputPhone";
import optionCountryCodes from "../../../../providers/optionCountryCodes";

export default function PageProfile() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [users, setUsersData] = useState([]);
  const [authText, setAuthText] = useState("enable");

  useEffect(() => {
    if (users.length != 0) {
      let address = users?.user_address ? users?.user_address : null;

      console.log("address", address);
      form.setFieldsValue({
        username: users.username ? users.username : undefined,
        firstname: users.firstname ? users.firstname : undefined,
        lastname: users.lastname ? users.lastname : undefined,
        email: users.email ? users.email : undefined,
        phone: users.phone ? "+" + users.phone : undefined,
        birthdate: users.birthdate,
        address1: address?.address1 ? address?.address1 : undefined,
        address2: address?.address2 ? address?.address2 : undefined,
        city: address?.city ? address?.city : undefined,
        state: address?.state ? users.user_address?.state : undefined,
        zip: address?.zip_code ? address?.zip_code : undefined,
        country: address?.country ? address?.country : "United States",
      });

      onChangeCountry(
        users?.user_address?.country
          ? users?.user_address?.country
          : "United States",
        true
      );
    }

    return () => {};
  }, [users]);

  useEffect(() => {
    setOptionState(stateUS);
    setStateLabel("State");
    setOptionZip(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    setZipLabel("Zip Code");
  }, [form]);

  const country = optionCountryCodes;
  const stateUS = optionStateCodesUnitedState();
  const stateCA = optionStateCodesCanada();

  const [optionCountry, setOptionStateCountry] = useState(stateUS);
  const [optionState, setOptionState] = useState([]);
  const [stateLabel, setStateLabel] = useState("State");
  const [optionZip, setOptionZip] = useState();
  const [zipLabel, setZipLabel] = useState("Zip Code");

  const [fileList, setFileList] = useState([]);
  const [radioData, setRadioData] = useState(1);
  const [imageCrop, setImageCrop] = useState({
    width: 1,
    height: 1,
  });

  const [toggleModalDeactivateAcc, setToggleModalDeactivateAcc] = useState({
    title: "",
    show: false,
  });
  const [toggleModalFormChangePassword, setToggleModalFormChangePassword] =
    useState(false);
  const [
    toggleModalForm2FactorAuthentication,
    setToggleModalForm2FactorAuthentication,
  ] = useState(false);
  const [googleApiTokenMessage, setGoogleApiTokenMessage] = useState({
    title: "",
    description: "",
  });

  const countryCodes = {
    Canada: "CA",
    "Puerto Rico": "PR",
  };
  const [defaultCountry, setDefaultCountry] = useState("");
  GET(`api/v1/users/${userData().id}`, "update_profile", (res) => {
    if (res.success) {
      if (res.data) {
        let data = res.data;
        // console.log("user", res.data);
        setUsersData(data);

        let country = countryCodes[res.data?.user_address?.country] || "US";
        setDefaultCountry(country);

        let image_type = data.profile_image
          ? data.profile_image.split("/")
          : "";

        if (image_type[0] !== undefined) {
          image_type =
            image_type[0] === "https:"
              ? data.profile_image
              : apiUrl + data.profile_image;

          setFileList([
            {
              uid: "-1",
              name: "image.png",
              status: "done",
              url: image_type,
            },
          ]);
        } else {
          setFileList([]);
          image_type = "";
        }

        if (data.calendar_token) {
          let calendar_token = JSON.parse(data.calendar_token);

          if (calendar_token && calendar_token?.error) {
            setGoogleApiTokenMessage({
              title: "Resync Google Calendar",
              description:
                "Due to multiple use of google account, Token has been Expired or Revoked. Please resync your google account to receive appointment bookings on your Google Calendar.",
            });
          }
        }
      }
    }
  });

  const handleResize = (val) => {
    setRadioData(val.target.value);
    if (val.target.value === 1) {
      setImageCrop({
        width: 1,
        height: 1,
      });
    } else if (val.target.value === 2) {
      setImageCrop({
        width: 3.9,
        height: 2.6,
      });
    } else if (val.target.value === 3) {
      setImageCrop({
        width: 1,
        height: 1.5,
      });
    }
  };

  const onChangeUpload = ({ fileList: newFileList }) => {
    var _file = newFileList;

    if (_file.length !== 0) {
      _file[0].status = "done";
      setFileList(_file);
      setHasChange(true);
      form.submit();
    } else {
      setFileList([]);
    }
  };

  const onPreviewUpload = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const beforeUpload = (newfile) => {
    let file = newfile[0];
    console.log(file.size);

    const allowedFileTypes = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "jpg",
      "doc",
      "docx",
      "pdf",
    ];

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      notification.warning({
        message: "Error",
        description:
          "You can only upload JPG, PNG, GIF, JPEG, DOC, DOCX, PDF files.",
      });
      return Upload.LIST_IGNORE;
    }

    const isLt5M = file.size < 5 * 1024 * 1024; // Check if the file is smaller than 5MB

    // const isLt2M = file.size / 1024 / 1024 < 2; // Check if the file is smaller than 5MB
    if (!isLt5M) {
      notification.warning({
        message: "Error",
        description: "Image must smaller than 5MB!",
      });

      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const { mutate: mutateUpdateUser } = POST(
    "api/v1/users/update",
    "update_profile"
  );

  const onFinish = (values) => {
    if (hasChange) {
      let formData = new FormData();
      let data = form.getFieldsValue();

      Object.keys(data).map((key, item) => {
        formData.append(key, data[key] ? data[key] : "");
      });
      let phone = values.phone.replace(/[^0-9]/g, "");
      formData.append("id", userData().id);
      formData.append("phone", phone);

      if (fileList.length !== 0) {
        if (fileList[0].uid != "-1") {
          formData.append(
            "profile_image",
            fileList[0].originFileObj,
            fileList[0].name
          );
        }
      }

      //    let data = { ...form.getFieldsValue(), id: userData().id };
      mutateUpdateUser(formData, {
        onSuccess: (res) => {
          if (res.success) {
            notification.success({
              message: "Success",
              description: "Successfully Updated.",
            });

            let imageUrl = "";
            if (res.data?.profile_image) {
              let avatarImage = res.data?.profile_image.split("/");
              if (avatarImage[0] === "https:") {
                imageUrl = res.data?.profile_image;
              } else {
                imageUrl = apiUrl + res.data?.profile_image;
              }
            }
            $(".ant-menu-submenu-profile").attr("src", imageUrl);
            $(".ant-menu-submenu-profile-popup").attr("src", imageUrl);
            $(".ant-typography-profile-details-name-info").text(
              `${res.data?.firstname} ${res.data?.lastname}`
            );

            localStorage.userdata = encrypt(res.data);
            setHasChange(false);
          }
        },
      });
    }
  };

  const [hasChange, setHasChange] = useState(false);

  const generateGoogleAccessToken = () => {
    mutateGenerateGoogleAccessToken(
      {},
      {
        onSuccess: (res) => {
          if (res.success) {
            // redirect url targer blank
            window.open(res.data, "_blank");
          }
        },
      }
    );
  };

  const {
    mutate: mutateGenerateGoogleAccessToken,
    isLoading: isLoadingGenerateGoogleAccessToken,
  } = POST("api/generate-google-access-token", "generate_google_access_token");

  const checkAccount = (value, type) => {
    if (type === "phone") {
      value = value.replace(/[^0-9]/g, "");
    }
    let data = {
      value: value,
      type: type,
    };

    mutateCheckAccount(data, {
      onSuccess: (res) => {
        if (res.success) {
          form.submit();
        } else {
          form.setFields([
            {
              name: type,
              errors: [res.message],
            },
          ]);
        }
      },
      onError: (err) => {
        form.setFields([
          {
            name: type,
            errors: ["Error on checking " + type + "!"],
          },
        ]);
      },
    });
  };

  const { mutate: mutateCheckAccount } = POSTNOLOADING(
    "api/v1/check_account",
    "check_account",
    false
  );

  const handleBlur = (val, type = "") => {
    form
      .validateFields()
      .then((values) => {
        form.submit();
      })
      .catch((info) => {
        // console.log("Validate Failed:", info);
      });
  };

  const [countryValue, setCountryValue] = useState("");
  const onChangeCountry = (val, load = false) => {
    setCountryValue(val);
    if (val === "United States") {
      setOptionState(stateUS);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    } else if (val === "Canada") {
      setOptionState(stateCA);
      setOptionZip(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    } else if (val === "Puerto Rico") {
      setOptionState(stateCA);
      setOptionZip(/(^\d{5}$)|(^\d{5}[ -]\d{4}$)/);
    }

    // if (!load) {
    //   form.resetFields(["state", "zip", "city"]);
    // }
  };

  return (
    <Card className="page-admin-profile card--padding-mobile">
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={() => {
          setHasChange(true);
        }}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faMinus}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faPlus}
                  />
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="end"
            >
              <Collapse.Panel
                header="LOGIN INFORMATION"
                key="1"
                className="accordion bg-darkgray-form m-b-md white "
              >
                <Row
                  gutter={8}
                  className="profile-username"
                  style={{ paddingTop: "8px" }}
                >
                  <Col xs={24} sm={24} md={24}>
                    <Form.Item name="username" required hasFeedback>
                      <FloatInput
                        label="Username"
                        placeholder="Username"
                        readOnly
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24}>
                    <Button
                      type="link"
                      className="light-blue-link m-l-n"
                      onClick={() => setToggleModalFormChangePassword(true)}
                    >
                      Change Password
                    </Button>
                  </Col>
                  {/* {role() !== "Admin" ? ( */}
                  <Col xs={24} sm={24} md={24}>
                    <div className="two-factor-text">
                      {/* <Link to={"/2fa"} className="link2factor">
                          {" "}
                          CLICK HERE
                        </Link>{" "} */}
                      <span className="enable-text ">
                        {authText} 2-Factor Authentication (2FA){" "}
                      </span>
                      <Switch
                        size="small"
                        checked={users.google2fa_enable === 1 ? true : false}
                        onChange={() => {
                          history.push("/2fa");
                        }}
                      />
                    </div>
                  </Col>
                  {/* ) : null} */}
                </Row>
              </Collapse.Panel>
            </Collapse>

            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faMinus}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faPlus}
                  />
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="end"
            >
              <Collapse.Panel
                header="PERSONAL INFORMATION"
                key="1"
                className="accordion bg-darkgray-form m-b-md "
              >
                <Row
                  gutter={[12, 0]}
                  className="personal-info-1"
                  style={{ paddingTop: "8px" }}
                >
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="firstname"
                      rules={[validateRules.required]}
                    >
                      <FloatInput
                        label="First Name"
                        placeholder="First Name"
                        onBlurInput={(e) => {
                          if (users.firstname != e) {
                            handleBlur(e, "firstname");
                          }
                        }}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item name="lastname" rules={[validateRules.required]}>
                      <FloatInput
                        label="Last Name"
                        placeholder="Last Name"
                        onBlurInput={(e) => {
                          if (users.lastname != e) {
                            handleBlur(e, "lastname");
                          }
                        }}
                        required
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item name="email" rules={[validateRules.required]}>
                      <FloatInput
                        label="Email"
                        placeholder="Email"
                        required
                        // readOnly={
                        //   localStorage.getItem("userdata_admin") ? false : true
                        // }
                        onBlurInput={(e) => {
                          if (e && users?.email != e) {
                            checkAccount(e, "email");
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      name="phone"
                      rules={[
                        validateRules.required,
                        validateRules.international_phone,
                      ]}
                      className="class-phone"
                    >
                      <FloatInputPhone
                        international={true}
                        label="Phone"
                        placeholder="Phone"
                        onBlurInput={(e) => {
                          let value = e.target.value;
                          if (value) {
                            let phone = value.replace(/[^0-9]/g, "");
                            // console.log("FloatInputPhone", phone, users?.phone);
                            if (phone !== users?.phone) {
                              checkAccount(phone, "phone");
                            }
                          }
                        }}
                        defaultCountry={defaultCountry}
                        required
                      />
                    </Form.Item>
                  </Col>

                  {users.role !== "Admin" && (
                    <Col xs={24} sm={24} md={12} className="phone">
                      <Form.Item
                        name="birthdate"
                        rules={[validateRules.birthday]}
                      >
                        <FloatInputMask
                          label="Date of Birth (MM/DD/YYYY)"
                          placeholder="Date of Birth"
                          maskLabel="Date of Birth"
                          maskType="mm/dd/yyyy"
                          onBlurInput={(e) => {
                            if (users?.birthdate != e) {
                              handleBlur(e, "birthdate");
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* {role() === "Admin" ? (
                    <Col xs={24} sm={24} md={24} className="two-factor">
                      <Button
                        type="link"
                        onClick={() =>
                          setToggleModalForm2FactorAuthentication(true)
                        }
                      >
                        <span className="light-blue-link">CLICK HERE</span>{" "}
                        <span className="color-7">
                          to enable 2-Factor Authentication (2FA)
                        </span>
                      </Button>
                    </Col>
                  ) : null} */}
                </Row>
              </Collapse.Panel>
            </Collapse>

            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faMinus}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faPlus}
                  />
                )
              }
              defaultActiveKey={["1"]}
              expandIconPosition="end"
            >
              <Collapse.Panel
                header="ADDRESS INFORMATION"
                key="1"
                className="accordion bg-darkgray-form m-b-md "
              >
                <Row gutter={[12, 0]} style={{ paddingTop: "8px" }}>
                  {userData().role === "User" && (
                    <Col xs={24} sm={24} md={12} className="state">
                      <Form.Item
                        name="country"
                        rules={[validateRules.required]}
                      >
                        <FloatSelect
                          label="Country"
                          placeholder="Country"
                          options={country}
                          // onBlurInput={(e) => {
                          //   if (e && users?.user_address?.city != e) {
                          //     handleBlur(e, "country");
                          //   }
                          // }}
                          onChange={(val, opt) => {
                            onChangeCountry(val);
                            handleBlur(val, "country");
                          }}
                          required
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={[12, 0]}>
                  <Col xs={24} sm={24} md={12} className="address1">
                    <Form.Item
                      name="address1"
                      required
                      rules={[validateRules.required]}
                    >
                      <FloatInput
                        label="Address "
                        placeholder="Address"
                        onBlurInput={(e) => {
                          if (e && users?.user_address?.address1 != e) {
                            handleBlur(e, "address1");
                          }
                        }}
                        required
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12} className="address2">
                    <Form.Item name="address2">
                      <FloatInput
                        label="Address 2"
                        placeholder="Address 2"
                        onBlurInput={(e) => {
                          if (users?.user_address?.address2 != e) {
                            handleBlur(e, "address2");
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} className="city">
                    <Form.Item name="city" rules={[validateRules.required]}>
                      <FloatInput
                        label="City"
                        placeholder="City"
                        onBlurInput={(e) => {
                          if (e && users?.user_address?.city != e) {
                            handleBlur(e, "city");
                          }
                        }}
                        required
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} className="state">
                    {countryValue !== "Puerto Rico" ? (
                      <Form.Item name="state" rules={[validateRules.required]}>
                        <FloatSelect
                          label={stateLabel}
                          placeholder={stateLabel}
                          options={optionState}
                          onChange={(e, opt) => {
                            if (e && users?.user_address?.state != e) {
                              handleBlur(e, "state");
                            }
                          }}
                          required
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item name="state" rules={[validateRules.required]}>
                        <FloatInput
                          label={"State / County"}
                          placeholder={"State / County"}
                          required
                          onBlurInput={(e) => {
                            if (e && users?.user_address?.state != e) {
                              handleBlur(e, "state");
                            }
                          }}
                        />
                      </Form.Item>
                    )}
                  </Col>

                  <Col xs={24} sm={24} md={8} className="zip-code">
                    {countryValue !== "Puerto Rico" ? (
                      <Form.Item
                        name="zip"
                        className="w-100"
                        rules={[
                          validateRules.required,
                          {
                            pattern: optionZip,
                            message: "Invalid Post Code / ZIP Code!",
                          },
                        ]}
                      >
                        <FloatInput
                          label={"Post Code / ZIP Code"}
                          placeholder={"Post Code / ZIP Code"}
                          onBlurInput={(e) => {
                            if (e && users?.user_address?.zip_code != e) {
                              handleBlur(e, "zip");
                            }
                          }}
                          required
                        />
                      </Form.Item>
                    ) : (
                      <Form.Item name="zip" rules={[validateRules.required]}>
                        <FloatInput
                          label={"Post Code / ZIP Code"}
                          placeholder={"Post Code / ZIP Code"}
                          onBlurInput={(e) => {
                            if (e && users?.user_address?.zip_code != e) {
                              handleBlur(e, "zip");
                            }
                          }}
                          required
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <Collapse
              className="main-1-collapse border-none"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faMinus}
                  />
                ) : (
                  <FontAwesomeIcon
                    style={{ color: "#325db8", fontSize: 18 }}
                    icon={faPlus}
                  />
                )
              }
              defaultActiveKey={["1", "2"]}
              expandIconPosition="end"
            >
              <Collapse.Panel
                header="PROFILE PHOTO"
                key="1"
                className="accordion bg-darkgray-form m-b-md "
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={24}>
                    <div className="font-red">
                      {/* <b>Photo upload & cropping:</b> */}

                      <div style={{ fontSize: 16 }}>
                        Select your image orientation
                      </div>
                    </div>
                    <Radio.Group onChange={handleResize} value={radioData}>
                      <Radio value={1}>Square</Radio>
                      <Radio value={2}>Rectangle</Radio>
                      <Radio value={3}>Portrait</Radio>
                    </Radio.Group>
                  </Col>
                  <Divider />
                  <Col xs={24} sm={24} md={24}>
                    <div className="flex">
                      <ImgCrop
                        modalClassName="img-crop-modal"
                        rotate
                        // cropperProps={{ restrictPosition: false }}
                        aspect={imageCrop.width / imageCrop.height}
                        beforeCrop={(_, file) => beforeUpload(file)}
                      >
                        <Upload
                          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          listType="picture-card"
                          style={{ width: "200px" }}
                          maxCount={1}
                          action={false}
                          customRequest={false}
                          fileList={fileList}
                          onChange={onChangeUpload}
                          onPreview={onPreviewUpload}
                          // beforeUpload={beforeUpload}
                          className="profilePhoto"
                        >
                          {fileList.length < 1 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                    </div>
                  </Col>
                  <Divider />
                  <Col xs={24} sm={24} md={24}>
                    <Typography.Text>
                      5MB limit. Allowed types png, gif, jpeg
                      <br /> Your selected profile photo will be visible to all
                      users.
                    </Typography.Text>
                    <br />
                  </Col>
                </Row>
              </Collapse.Panel>

              {users.role !== "User" && (
                <Collapse.Panel
                  header="Google Calendar Sync"
                  key="2"
                  className="accordion bg-darkgray-form m-b-md "
                >
                  {users.calendar_token && (
                    <Result
                      status={
                        googleApiTokenMessage?.title ? "warning" : "success"
                      }
                      title={
                        googleApiTokenMessage?.title
                          ? googleApiTokenMessage?.title
                          : "Successfully Synced!"
                      }
                      subTitle={
                        googleApiTokenMessage?.description
                          ? googleApiTokenMessage?.description
                          : "You are now able to receive appointment bookings on your Google Calendar."
                      }
                      className="result-token"
                    />
                  )}

                  {/* {users.calendar_token} */}

                  <Button
                    size="large"
                    className="atc-btn the-change-pass w-100"
                    onClick={() => {
                      generateGoogleAccessToken();
                    }}
                    loading={isLoadingGenerateGoogleAccessToken}
                  >
                    Sync Google Calendar
                  </Button>
                </Collapse.Panel>
              )}
            </Collapse>
          </Col>
        </Row>
      </Form>

      <ModalDeactivateAcc
        toggleModalDeactivateAcc={toggleModalDeactivateAcc}
        setToggleModalDeactivateAcc={setToggleModalDeactivateAcc}
      />

      <ModaFormChangePassword
        toggleModalFormChangePassword={toggleModalFormChangePassword}
        setToggleModalFormChangePassword={setToggleModalFormChangePassword}
      />

      <ModaForm2FactorAuthentication
        toggleModalForm2FactorAuthentication={
          toggleModalForm2FactorAuthentication
        }
        setToggleModalForm2FactorAuthentication={
          setToggleModalForm2FactorAuthentication
        }
      />
    </Card>
  );
}
