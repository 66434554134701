import {
  Button,
  Checkbox,
  Form,
  Modal,
  notification,
  Radio,
  Space,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputNumber from "../../../../providers/FloatInputNumber";
import FloatDatePickerForm from "../../../../providers/FloatDatePickerForm";
import { POST } from "../../../../providers/useAxiosQuery";
import moment from "moment";

export default function ModalSchool(props) {
  const { toggleModalForm, setToggleModalForm } = props;

  const [form] = Form.useForm();

  const [useOnce, setUseOnce] = useState(false);
  const [isFixed, setIsFixed] = useState("percent");
  useEffect(() => {
    if (toggleModalForm?.data) {
      setUseOnce(
        toggleModalForm.data && toggleModalForm.data.use_once === "True"
          ? true
          : false
      );

      setIsFixed(toggleModalForm.data.type);
      form.setFieldsValue({
        ...toggleModalForm.data,
        duration_from: moment(toggleModalForm.data.duration_from),
        duration_to: moment(toggleModalForm.data.duration_to),
        // role: toggleModalForm.data.role
        //   ? JSON.parse(toggleModalForm.data.role)
        //   : [],
      });
    } else {
      setIsFixed("percent");
      form.resetFields();
    }

    // console.log("isFixed1", toggleModalForm?.data);
  }, [toggleModalForm]);

  const validator = {
    require: {
      required: true,
      message: "This field is required!",
    },
  };

  const [isRepeating, setIsRepeating] = useState(false);

  const { mutate: mutateCoupon, isLoading: isLoadingCoupon } = POST(
    "api/v1/add_coupon_system",
    ["add", "coupons_table"]
  );
  const { mutate: mutateCouponUpdate, isLoading: isLoadingCouponUpdate } = POST(
    "api/v1/update_coupon_system",
    ["update", "coupons_table"]
  );

  const onFinish = (values) => {
    let data = {
      ...values,
      type: isFixed,
      duration_from: values.duration_from.format("YYYY-MM-DD"),
      duration_to: values.duration_to.format("YYYY-MM-DD"),
      max_redemptions: values.max_redemptions
        ? Math.floor(values.max_redemptions)
        : null,
      user_once: useOnce,
    };

    let from = values.duration_from.format("YYYYMMDD");
    let to = values.duration_to.format("YYYYMMDD");
    if (isFixed === "percent" && values.off > 100) {
      notification.warning({
        message: "Warning",
        description: "Percentage off must be equal or less than 100.",
      });
      return false;
    }
    console.log(
      "max_redemptions",
      values,
      data.max,
      toggleModalForm?.data?.redemption
    );
    if (
      data.max &&
      data.max !== undefined &&
      Number(data.max) < Number(toggleModalForm?.data?.redemption)
    ) {
      notification.warning({
        message: "Warning",
        description: "Max redemptions must be greater than redemptions.",
      });
      return false;
    }

    if (Number(from) > Number(to)) {
      notification.warning({
        message: "Warning",
        description: "Duration From must be equal or less than Duration To.",
      });
    } else {
      if (toggleModalForm.data && toggleModalForm.data.id) {
        mutateCouponUpdate(data, {
          onSuccess: (res) => {
            if (res.success) {
              // console.log(res)
              notification.success({
                message: "Sucess",
                description: "Coupon sucessfully updated.",
              });
              setToggleModalForm({ show: false, data: null });
              setIsFixed("percent");
              form.resetFields();
            }
          },
          onError: (err) => {
            notification.error({
              message: "Error",
              description: err.response.data.message,
            });
          },
        });
      } else {
        mutateCoupon(data, {
          onSuccess: (res) => {
            if (res.success) {
              // console.log(res)
              notification.success({
                message: "Sucess",
                description: "Coupon sucessfully added.",
              });
              setToggleModalForm({ show: false, data: null });
              setIsFixed("percent");
              form.resetFields();
            } else {
              notification.error({
                message: "Error",
                description: "Coupon already exists.",
              });
            }
          },
          onError: (err) => {
            notification.error({
              message: "Error",
              description: err.response.data.message,
            });
          },
        });
      }
    }
  };

  const [oldPercentage, setOldPercentage] = useState(null);

  const onChange = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setUseOnce(e.target.checked);
  };

  const isFixedRef = useRef(isFixed);

  useEffect(() => {
    isFixedRef.current = isFixed;
  }, [isFixed]);

  return (
    <Modal
      open={toggleModalForm.show}
      width={600}
      title={
        <Typography.Text
          style={{ fontSize: 20, color: "#FFF", fontWeight: 400 }}
        >
          Coupon Form
        </Typography.Text>
      }
      onCancel={() => {
        setToggleModalForm({ show: false, data: null });
      }}
      beforeClose={() => {
        setToggleModalForm({ show: false, data: null });
      }}
      footer={
        <>
          <Button
            type="primary"
            loading={isLoadingCoupon || isLoadingCouponUpdate}
            className="atc-btn-opposite b-r-none"
            size="large"
            onClick={() => form.submit()}
          >
            SUBMIT
          </Button>
        </>
      }
      className="dashboard-modal-event-preview"
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={(change, values) => {
          if (change.duration) {
            if (change.duration == "repeating") {
              setIsRepeating(true);
            } else {
              setIsRepeating(false);
            }
          }
          if (change.type) {
            if (change.type === "percent") {
              setIsFixed("percent");
              let percOff = form.getFieldValue("off");
              console.log("percOff", percOff);
              if (percOff > 100) {
                notification.warning({
                  message: "Warning",
                  description: "Percentage off must be equal or less than 100.",
                });
                setOldPercentage(100);
                form.setFieldsValue({
                  off: 100,
                });
              }
            }
            if (change.type === "fixed") {
              setIsFixed("fixed");
              if (oldPercentage) {
                form.setFieldsValue({
                  off: oldPercentage,
                });
              }
            }
          }
        }}
      >
        <Form.Item name="coupon_code" rules={[validator.require]}>
          <FloatInput
            label="Coupon Code"
            placeholder="Coupon Code"
            disabled={
              toggleModalForm.data && toggleModalForm.data.id ? true : false
            }
            required
          />
        </Form.Item>
        <Form.Item name="coupon_name" rules={[validator.require]}>
          <FloatInput label="Coupon Name" placeholder="Coupon Name" required />
        </Form.Item>
        <Space
          direction="vertical"
          style={{ marginTop: "-5px", marginBottom: "-3px" }}
        >
          <Form.Item name="type" label="Type Discount">
            <Radio.Group defaultValue={isFixed} o>
              <Radio value="percent">Percentage </Radio>
              <Radio value="fixed">Fixed </Radio>
              {/* <Radio value="offer">Offer Free Days</Radio> */}
            </Radio.Group>
          </Form.Item>
        </Space>

        {isFixed === "percent" ? (
          <Form.Item name="off" rules={[validator.require]}>
            <FloatInputNumber
              label="Percentage Off"
              placeholder="Percentage Off"
              required
              onBlur={(e) => {
                if (e.target.value > 100) {
                  setTimeout(() => {
                    if (isFixedRef.current === "percent") {
                      form.setFieldsValue({
                        off: 100,
                      });

                      notification.warning({
                        message: "Warning",
                        description:
                          "Percentage off must be equal or less than 100.",
                      });
                    }
                  }, 500);

                  setOldPercentage(e.target.value);
                }
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item name="off" rules={[validator.require]}>
            <FloatInputNumber
              label="Amount Off"
              placeholder="Amount Off"
              required
            />
          </Form.Item>
        )}

        <Form.Item name="duration_from" rules={[validator.require]}>
          <FloatDatePickerForm
            label={"Duration From"}
            placeholder={"Duration From"}
            mode
            required
          />
        </Form.Item>

        <Form.Item name="duration_to" rules={[validator.require]}>
          <FloatDatePickerForm
            label={"Duration To"}
            placeholder={"Duration To"}
            mode
            required
          />
        </Form.Item>

        {/* <Form.Item
          name="role"
          rules={[validator.require]}
          // className="form-select-error-multi"
        >
          <FloatSelect
            label="Applied on Account type"
            placeholder="Applied on Account type"
            multi="multiple"
            options={[
              {
                label: "Client",
                value: "Client",
              },
              {
                label: "Athlete Guardian",
                value: "Athlete Guardian",
              },
            ]}
            required
          />
        </Form.Item> */}

        <Form.Item
          name="max"
          rules={[
            validator.require,
            // add patter for number only
            {
              pattern: /^[0-9]*$/,
              message: "Invalid Max Redemptions!",
            },
          ]}
        >
          <FloatInputNumber
            label="Max redemptions"
            placeholder="Max redemptions"
            required
            // onBlur={(e) => {
            //   if (
            //     e.target.value &&
            //     e.target.value !== undefined &&
            //     e.target.value < toggleModalForm?.data?.redemption
            //   ) {
            //     notification.warning({
            //       message: "Warning",
            //       description:
            //         "Max redemptions must be greater than redemptions",
            //     });
            //     // form.setFieldsValue({
            //     //   max: undefined,
            //     // });
            //   }
            // }}
          />
        </Form.Item>

        {/* <Form.Item name="max" hasFeedback>
          <FloatInputNumber
            label="Max redemptions"
            placeholder="Max redemptions"
            rules={[validator.require]}
          />
        </Form.Item> */}

        <Checkbox
          checked={useOnce}
          onChange={onChange}
          className="checkbox-fix"
        >
          <Typography.Text strong>Use Once Per (Client)</Typography.Text>
        </Checkbox>
      </Form>
    </Modal>
  );
}
