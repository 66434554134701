import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Row,
  notification,
  Modal,
  Button,
  Space,
  Typography,
} from "antd";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import moment from "moment";
import tz from "moment-timezone";
import ModalAddConsultantAvailability from "../../Components/ModalAddConsultantAvailability";
import { userData, role } from "../../../../providers/companyInfo";
import { ExclamationCircleOutlined, WindowsFilled } from "@ant-design/icons";
import ModalCancelAppointment from "../../Components/ModalCancelAppointmentConsultant";
import $ from "jquery";
import { faBackward, faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
const { Text } = Typography;

const PageConsultantSchedules = (props) => {
  const userdata = userData();
  const history = useHistory();
  const { match } = props.match;
  const [selectedDate, setSelectedDate] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [stage, setStages] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [slotId, setSlotId] = useState();
  const [id, setID] = useState("");
  const [currentDate, setCurrentDate] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    if (role() === "Consultant" || role() === "Special consultant") {
      setID(userData().id);
    } else {
      if (props.match.params.id) {
        setID(props.match.params.id);
      }
    }
  }, [props]);

  useEffect(() => {
    if (id !== "") {
      handlesChangeDate();
    }
  }, [id]);

  const handlesChangeDate = () => {
    let data = {
      id: id,
    };

    let bookedAppointments = [];
    let events = [];

    mutateGetAppointmentsAdmin(data, {
      onSuccess: (res) => {
        if (res.success) {
          let schedules = [];
          res.data.map((schedule) => {
            schedules.push({
              title: "",
              start: moment(
                schedule.schedule_date + " " + schedule.time_start
              ).format("YYYY-MM-DDTHH:mm"),
              end: moment(
                schedule.schedule_date + " " + schedule.time_end
              ).format("YYYY-MM-DDTHH:mm"),
              id: schedule.id,
              extendedProps: schedule,
            });
          });

          setAppointments(schedules);
        }
      },
    });
  };

  const { mutate: mutateDeleteSlot, isLoading: lodaingDeleteSlot } = POST(
    "api/v1/calendar/delete/slots",
    "get_appointment"
  );

  const { mutate: addHistoryLog } = POST(
    "api/v1/historylogs/add",
    "add_history_logs"
  );

  const showModalDelete = (value) => {
    setShowConfirmModal(true);
    setSlotId(value);
  };

  const deleteSlot = (value) => {
    let data = {
      slot_id: value,
    };

    mutateDeleteSlot(data, {
      onSuccess: (res) => {
        if (res.success) {
          notification.success({
            message: "Success",
            description: "Appointment slot successfully deleted.",
          });

          addHistoryLog(
            {
              page: "Consultant/Schedule",
              key: "appointment slot",
              slot_data: JSON.stringify(res.data),
              old_data: "",
              new_data: "",
              method: "delete-slot",
            },
            { onSuccess: (res) => {} }
          );

          setShowConfirmModal(false);
          handlesChangeDate();
          //    window.location.reload(false);
        }
      },
    });
  };

  const { mutate: mutateGetAppointmentsAdmin, isLoading: isLoadingSlot } = POST(
    "api/v1/get/consultant/appointment",
    "get_appointment",
    false
  );

  const [toggleModal, setToggleModal] = useState(false);
  const [toggleBookedModal, setToggleBookedModal] = useState(false);

  const handleDateClick = (e) => {
    setSelectedDate(moment(e.dateStr).format("MM/DD/YYYY"));
    setToggleBookedModal(true);
  };

  const { mutate: mutateDownloadCSV, isLoading: isLoadingSlots } = POST(
    "api/v1/calendar/download-schedule/csv",
    "download_calendar_csv"
  );

  const { mutate: mutateDownloadICS, isLoading: isLoadingICS } = POST(
    "api/v1/calendar/download-schedule/ics",
    "download_calendar_csv"
  );

  const arrayToCSV = (arr, delimiter = ",") =>
    arr
      .map((v) =>
        v
          .map((x) => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x))
          .join(delimiter)
      )
      .join("\n");

  const donwloadCSV = () => {
    let data;
    if (id) {
      data = { ...currentDate, id: id };
    } else {
      data = { ...currentDate };
    }

    mutateDownloadCSV(data, {
      onSuccess: (res) => {
        if (res.success) {
          // console.log(res.data.toString());
          var blob = new Blob([arrayToCSV(res.data)], {
            type: "text/csv;charset=utf-8",
          });
          saveAs(blob, "download.csv");
          // window.location.replace(res.url);
          //   setAppointments(bookedAppointments);
        }
      },
    });
  };

  const donwloadICS = () => {
    //  console.log("appointments", currentDate);
    let data;
    if (id) {
      data = { ...currentDate, id: id };
    } else {
      data = { ...currentDate };
    }

    mutateDownloadICS(data, {
      onSuccess: (res) => {
        if (res.success) {
          var blob = new Blob([res.data], {
            type: "text/calendar;charset=utf-8",
          });
          saveAs(blob, "download.ics");
          //   window.location.replace(res.url);
          //    setAppointments(bookedAppointments);
        }
      },
    });
  };

  const calendarRef = useRef(null);
  const fCalendar = useRef(null);
  const [calendarWidth, setCalendarWidth] = useState(0);
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (calendarRef?.current) {
  //       setCalendarWidth(calendarRef.current.offsetWidth);

  //       if (calendarRef.current?.offsetWidth < 425) {
  //         if (fCalendar?.current) {
  //           console.log("fCalendar", fCalendar.current);
  //           const calendarApi = fCalendar.current.getApi();
  //           calendarApi.changeView("timeGridDay");
  //         }
  //       }
  //     }
  //   };

  //   const resizeObserver = new ResizeObserver(handleResize);

  //   if (calendarRef.current) {
  //     resizeObserver.observe(calendarRef.current);
  //   }

  //   return () => {
  //     if (calendarRef.current) {
  //       resizeObserver.unobserve(calendarRef.current);
  //     }
  //   };
  // }, []);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [dayMaxEvent, setDayMaxEvent] = useState(4);
  useEffect(() => {
    if (windowSize.width <= 662) {
      setDayMaxEvent(0);
    } else if (windowSize.width > 662 && windowSize.width <= 991) {
      if (fCalendar?.current?.getApi()?.view?.type === "dayGridMonth") {
        setDayMaxEvent(4);
      } else {
        setDayMaxEvent(0);
      }
    } else {
      setDayMaxEvent(4);
    }
  }, [fCalendar?.current?.getApi()?.view?.type]);

  useEffect(() => {
    const handleResize = () => {
      if (calendarRef?.current) {
        setCalendarWidth(calendarRef.current.offsetWidth);
      }
    };

    if (calendarRef?.current) {
      const calendarApi = fCalendar?.current?.getApi();
      if (windowSize.width <= 662) {
        calendarApi?.changeView("timeGridDay");
      } else if (windowSize.width > 662 && windowSize.width <= 768) {
        calendarApi?.changeView("timeGridWeek");
      } else {
        calendarApi?.changeView("dayGridMonth");
      }

      const resizeObserver = new ResizeObserver(handleResize);
      if (calendarRef.current) {
        resizeObserver.observe(calendarRef.current);
      }
    }
  }, [windowSize.width]);

  const [modalViewSlot, setModalViewSlot] = useState({
    show: false,
    title: "",
    data: [],
  });

  const renderEventContent = (eventInfo) => {
    let event = eventInfo?.event?.extendedProps;
    let schedule_date = event?.schedule_date;
    let time_start = event?.time_start;
    let time_end = event?.time_end;
    let timeStart = moment(schedule_date + " " + time_start).format("HH:mm");
    let timeEnd = moment(schedule_date + " " + time_end).format("HH:mm");

    return (
      <div
        style={{
          display: "flex",
          color: "#365293",
          paddingTop: "6px",
          paddingBottom: "3px",
          width: "100%",
          // paddingLeft: "2px",
        }}
      >
        <GoPrimitiveDot size={15} style={{ marginTop: "-2.5px" }} />
        <div
          className="calendar-event-cont-tittle"
          style={{
            fontSize: 12,
            lineHeight: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "100%",
          }}
        >
          {timeStart + " - " + timeEnd}
        </div>
      </div>
    );
  };

  const [viewOnRefresh, setViewOnRefresh] = useState("dayGridMonth");

  return (
    <Card className="card--padding-mobile">
      <Row gutter={8}>
        <Col xs={24} sm={24} md={24} className="consultant-calendar-note">
          <Text>
            <strong>Note: </strong>To create an available time slot, simply
            click the date on the calendar and a pop-up prompt will appear for
            that day.
          </Text>
        </Col>
        {userdata.role === "Admin" && (
          <Col xs={24} sm={24} md={24} className="back-btn">
            <Button
              size="medium"
              className="btn-primary btn-with-svg btn-ant-header atc-btn-opposite"
              onClick={(e) => {
                history.goBack();
              }}
              icon={
                <FontAwesomeIcon
                  icon={faBackward}
                  style={{ marginRight: "5px" }}
                />
              }
            >
              Back to Consultants
            </Button>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          className="admin-calendar consultant-bookings-calendar consultant-schedule-calendar"
          ref={calendarRef}
        >
          <FullCalendar
            key={`c-sched-${calendarWidth}`}
            timeZone="MST"
            ref={fCalendar}
            allDaySlot={false}
            height="auto"
            slotEventOverlap={false}
            initialView={viewOnRefresh ? viewOnRefresh : "dayGridMonth"}
            eventMaxStack={dayMaxEvent}
            dayMaxEvents={dayMaxEvent}
            eventContent={renderEventContent}
            displayEventEnd={{ month: true, basicWeek: true, default: true }}
            customButtons={{
              downLoadCSV: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> Outlook/Google
                  </>
                ),
                hint: "Download Outlook/Google",
                click: function () {
                  donwloadCSV();
                },
              },
              downLoadICS: {
                text: (
                  <>
                    <FontAwesomeIcon icon={faDownload} /> ICalendar
                  </>
                ),
                hint: "Download ICalendar",
                click: function () {
                  donwloadICS();
                },
              },
            }}
            headerToolbar={{
              left: "prev,next",
              center: "title",
              right:
                "downLoadCSV,downLoadICS dayGridMonth,timeGridWeek,timeGridDay",
            }}
            viewHint={(buttonText, buttonName) => {
              if (buttonName.match(/^dayGridMonth/)) {
                return "Month View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridWeek/)) {
                return "Week View"; // results in "week list view"
              } else if (buttonName.match(/^timeGridDay/)) {
                return "Day View"; // results in "week list view"
              }
            }}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentTimezonePlugin,
            ]}
            events={appointments}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: true,
            }}
            eventClick={(e) => {
              if (
                e.event.extendedProps.status == null ||
                e.event.extendedProps.status == "cancelled"
              ) {
                showModalDelete(e.event.id);
              } else {
                setModalViewSlot({
                  show: true,
                  title: e.event.extendedProps.status,
                  data: e.event.extendedProps,
                });
              }
            }}
            dateClick={(e) => handleDateClick(e)}
            datesSet={(e) => {
              setViewOnRefresh(e.view.type);

              setCurrentDate({ start: e.startStr, end: e.endStr });
              handlesChangeDate();
            }}
          />
        </Col>
      </Row>

      <ModalAddConsultantAvailability
        toggleModal={toggleBookedModal}
        setToggleModal={setToggleBookedModal}
        selectedDate={selectedDate}
        details={appointmentDetails}
        data={appointments}
        id={id}
        reload={handlesChangeDate}
      />

      <ModalCancelAppointment
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        //   setSelectedDate={setSelectedDate}
        details={appointmentDetails}
      />

      <Modal
        className="booking-view-slot-modal"
        open={showConfirmModal}
        title="CONFIRM"
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        footer={
          <Space>
            <Button
              onClick={() => {
                deleteSlot(slotId);
              }}
              className="atc-btn-opposite"
              type="primary"
            >
              Confirm
            </Button>
          </Space>
        }
        bodyStyle={{ padding: "14px 16px 5px" }}
      >
        {<p>Are you sure you want to delete this appointment?</p>}
      </Modal>

      <Modal
        className="booking-view-slot-modal"
        open={modalViewSlot.show}
        title={
          modalViewSlot.title === "noshow"
            ? "no show"
            : modalViewSlot.title.toUpperCase()
        }
        onCancel={() => {
          setModalViewSlot({
            show: false,
            title: "",
            data: [],
          });
        }}
        footer={false}
        bodyStyle={{ padding: "13px 16px 15px" }}
      >
        {/* {<p>Are you sure you want to delete this appointment?</p>} */}
        <Space direction="vertical" size={1} style={{ marginTop: "-15px" }}>
          <Button
            type="link"
            className="modal-name-link"
            style={{
              paddingTop: "5px",
              marginBottom: "7px",
              marginLeft: "-2px",
            }}
          >
            <span
              className="app-modal-name"
              style={{
                color:
                  modalViewSlot?.data?.status == "booked"
                    ? "#058d08"
                    : modalViewSlot?.data?.status == "showed"
                    ? "#783b19"
                    : modalViewSlot?.data?.status == "cancelled"
                    ? "#f20020"
                    : "#f49917",
                fontWeight: 400,
                fontSize: 24,
                textTransform: "none",
              }}
            >
              {modalViewSlot?.data?.client?.firstname +
                " " +
                modalViewSlot?.data?.client?.lastname}
            </span>
          </Button>

          <span>
            {moment(modalViewSlot?.data?.schedule_date).format("dddd, MMMM Do")}
          </span>
          <span>
            {moment(modalViewSlot?.data?.time_start, "H:mm").format("h:mm A") +
              " - " +
              moment(modalViewSlot?.data?.time_end, "H:mm").format("h:mm A")}
          </span>
        </Space>
      </Modal>
    </Card>
  );
};

export default PageConsultantSchedules;
